import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Navbar from '../navbar';
import { Toolbar } from 'primereact/toolbar';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../loadingComponent';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import { TabPanel, TabView } from 'primereact/tabview';
import CreateBatch from './createBatch';
import { Toast } from 'primereact/toast';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import moment from 'moment/moment';
import SvgViewer from '../customComponents/svgViewer';
import { DeleteIcon, NotificationIcon, WarningIcon } from '../svgIcons';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import SearchBar from './SearchBar';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import { getBoardsData } from './../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Authentication from './../session';
import ShowAssessmentsData from './showAssessmentsData';
import { InputSwitch } from 'primereact/inputswitch';

const items = [
    { label: 'Analytics' },
    { label: 'Teachers Proffesional Training' },
    { label: 'Batch Management', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }
const todayDate = new Date
const StatusOptions = [
    { label: 'Scheduled', value: "Scheduled" },
    { label: 'Ongoing', value: "Ongoing" },
    { label: 'Cancelled', value: "Cancelled" },
    { label: 'ReScheduled', value: "ReScheduled" },
    { label: 'Completed', value: "Completed" },
]


export class BatchManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseType: "",
            fromDate: "",
            toDate: "",
            courseTypeErr: false,
            fromDateErr: false,
            toDateErr: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedTab: 1,
            batchManagement: true,
            createCourse: false,
            statusVisible: false,
            statusHeaderMsg: "",
            statusMessage: "",
      
            mandatoryList: [],
            choiceBasedList: [],
            type: "mandatory",
            totalRecords: null,
            rowDataBatchStatus:{},
            currentDate: new Date(),
            editBatch: {},
            warningDialog: warningDailogInit,
            deleteBatchId: null,
            selectedCourses: [],
            selectedCoursesObjects: [],
            showAssignUsers: false,
          
            filterData: null,
            users: [],
            createBatch: false,
            userstotalRecords: '',
            showBatchStatusDropDown:false,
            showSearch: "",
            globalSearchUsers: "",
            batchStatus:"",
            batchesList: [],
            selectedUser: [],
            assessmentData: {},
            showAssessmentsData: false,
            assessmentType: ""
        }

        this.service = new Service()

    }

    componentDidMount() {

        this.getBatchesList()
      
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Batch Management</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onClickReset = () => {
        this.setState({
            "fromDate": "",
            "toDate": ""
        })
        this.getBatchesList()
    }

    getBatchesList = () => {
       
        let lazyParams = this.state.lazyParams

        const payload = {
            courseId: this.props.location.state.courseId,
            fromDate:this.state.fromDate,
            toDate:this.state.toDate
        }

        const Url = `${baseUrlAdmin}/batch?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        this.service.post(Url, payload, true).then((data) => {
            if (data && data.res) {

                this.setState({
                    batchesList: data?.res?.data,
                    totalRecords: data?.res?.totalRecords
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }





    onClickCreateNewBatch = () => {
        this.setState({
            batchManagement: false,
            createBatch: true
        })
    }

    onBackFromCreate = (a) => {
        // this.setState({
        //     courseManagement : true,
        //     createCourse : false,
        //     editCourse : {}
        // })
        if (a) {
            this.setState({
                batchManagement: true,
                createBatch: false,
                editBatch: {},
                selectedCourses: [],
            }, () => this.getBatchesList())
            this.toast.show({ severity: 'success', summary: `Successfully Batch ${a}`, detail: '', life: 3000 });
        } else {
            this.setState({
                batchManagement: true,
                createBatch: false,
                editBatch: {},
                selectedCourses: [],
                // fromDate : "",
                // fromDateErr : false,
                // toDate : "",
                // toDateErr : false,
                // mandatoryList : [],
                // choiceBasedList : []
            })
        }
    }

    onClickEditBatch = (rowData) => {

        this.setState({
            batchManagement: false,
            createBatch: true,
            editBatch: { ...rowData, startDate: rowData.startDate, endDate: rowData.endDate }
        })
    }

    onDeleteBatch = (rowData) => {
        if (rowData?.assignedTeacher.length >= 0) {
            this.setState({
                warningDialog: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    message: "Do you want to delete the Batch from the Batch list and unassign it from the courses"

                },
                deleteBatchId: rowData
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Unable to Delete Assigned Courses', life: 3000 });
        }
    }

    onClickDelete = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/batch/${this.props.location.state?.courseId}/${this.state.deleteBatchId?._id}`

        this.service.delete(url, true).then((res) => {
            if (res && res.status && res?.res?.status) {
                this.setState({ isLoading: false }, () => {
                    this.getBatchesList();
                })
                this.toast.show({ severity: 'success', summary: 'Course deleted successfully', detail: res.res.message, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, () => this.getBatchesList());
    }

    onSelectCourse = e => this.setState({ selectedCourses: e.value })

    onChangeSelection = (rowData, e) => {
        if (e.checked) {
            let cId = rowData._id
            rowData.prerequisite.courseId = cId
            rowData.preAssessment.courseId = cId
            rowData.postAssessment.courseId = cId
            this.setState((prevState) => {
                return {
                    selectedCourses: [...prevState?.selectedCourses, rowData._id],
                    selectedCoursesObjects: [...prevState?.selectedCoursesObjects, rowData]
                }
            })
        } else {
            this.setState((prevState) => {
                let filteredArr = prevState?.selectedCourses.filter(a => a !== rowData._id)
                let filterArrObj = prevState?.selectedCoursesObjects.filter(b => b._id !== rowData._id)
                return {
                    selectedCourses: filteredArr,
                    selectedCoursesObjects: filterArrObj
                }
            })
        }
    }

    onChangeSelectUser = (rowData, e) => {
        if (e.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        } else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }
    }

    onClikAssginUsers = () => {
        if (this.state.selectedCourses.length > 0) {
            this.setState({ showAssignUsers: true })
        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the courses', life: 3000 });
        }
    }

    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.filterUsersData)
    }
    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }
    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isActive = "Active"
        } else {
            data.isActive = "InActive"
        }

        this.setState({
           
                statusVisible: true,
                statusHeaderMsg: 'Are you Sure?',
                statusMessage: `You are changing this Batch status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`,
          
            statusId: data,
            //editUserData: this.transFormEditUserData(data)
        });

       

    }


    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })
        let { lazyParams, globalSearchUsers, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearchUsers}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    userstotalRecords: res.res.totalRecords
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
        })
    }

    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onSortUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return { globalSearchUsers: e.target.value, }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }
        });
    }


    onChangeFromDateForBatch = (e)=>{
        let startDate = new Date(e.target.value);
        let timezoneOffset = startDate.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
      

       this.setState({fromDate:startDate.toISOString(),fromDateErr:false})
    }


    onChangeToDateForBatch = (e)=>{
        let endDate = new Date(e.target.value);
        let timezoneOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - timezoneOffset);
      

       this.setState({toDate:endDate.toISOString(),toDateErr:false})
    }

    startGlobalFilter = () => {
        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;
            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }
            }
            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }

    onClickAssignExamsToUsers = () => {
        if (this.state.selectedUser.length) {
            let obj = {}
            obj.curriculum = {}
            obj.assignIds = this.state.selectedUser

            let examsArr = []
            this.state.selectedCoursesObjects.map(e => {
                examsArr.push(e.prerequisite, e.preAssessment, e.postAssessment)
            })
            obj.curriculum.boardId = "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7"
            obj.curriculum.classId = "ccb7388c-dfc8-477d-8919-b202db3f422b"
            obj.curriculum.groupId = "1aeababe-3477-427c-8a74-0819caf05d36"
            obj.curriculum.exams = examsArr

            let courseArr = []
            this.state.selectedCoursesObjects.map(e => {
                courseArr.push({ id: e._id, name: e.courseName, assigned: e.type === "mandatory" ? true : false })
            })
            obj.courses = courseArr

            obj.assignTo = "user"
            obj.examType = "Assessment"

            let url = `${baseUrlAdmin}/examination/assigntosection`
            this.setState({ isLoading: true })
            this.service.post(url, obj, true).then(res => {
                if (res && res?.status && res?.res?.success) {
                    this.setState({
                        isLoading: false, showAssignUsers: false, users: [], filterData: null,
                        selectedCourses: [], selectedCoursesObjects: [], selectedUser: [],
                    })
                    this.toast.show({ severity: 'success', summary: '', detail: 'Successfully assigned to users', life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                console.log('e', e)
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
            })

        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the studnets', life: 3000 });
        }
    }
    onClickAssessments = (rowData, assessmentType) => {
        this.setState({
            courseManagement: false,
            showAssessmentsData: true,
            assessmentData: rowData,
            assessmentType: assessmentType
        })
    }

    onUpdateBatchStatus = ()=>{
      
        let url = `${baseUrlAdmin}/batch/status`;

        const statusData={
               courseId:this.state.rowDataBatchStatus?.courseId,
                batchId:this.state.rowDataBatchStatus?._id,
               status:this.state.batchStatus
        }
        const ActiveOrInactiveData={
            courseId:this.state.statusId?.courseId,
             batchId:this.state.statusId?._id,
            isActive:this.state.statusId?.isActive
     }
       const payload = this.state.showBatchStatusDropDown ?statusData:ActiveOrInactiveData
        this.service.post(url,payload , true).then((res) => {
            if (res?.status) {
                this.setState({ 
                    showBatchStatusDropDown:false,
                    isLoading: false,
                    rowDataBatchStatus:{},
                    warningDialog: {
                        visible: false,
                    }
                   
                },this.getBatchesList())
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
        
    }


    onBackFromAssessments = () => {
        this.setState({
            courseManagement: true,
            showAssessmentsData: false,
            assessmentData: {},
            assessmentType: '',
            selectedCourses: []
        })
    }

    usersClear = () => {
        this.setState({ users: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }

    onBack = () => {
        
        this.props.history.push({
            pathname: '/course-management',
           state: {fromBatch:true}
          });
    }

    onUpdateBatchStatus = ()=>{
      
        let url = `${baseUrlAdmin}/batch/status`;

        const statusData={
               courseId:this.props.location.state?.courseId,
                batchId:this.state.rowDataBatchStatus?._id,
               status:this.state.batchStatus
        }
        const ActiveOrInactiveData={
            courseId:this.state.statusId?.courseId,
             batchId:this.state.statusId?._id,
            isActive:this.state.statusId?.isActive
     }
    
       const payload = this.state.showBatchStatusDropDown ?statusData:ActiveOrInactiveData
        this.service.post(url,payload , true).then((res) => {
            if (res?.status && res?.res?.status) {
                this.setState({ 
                    showBatchStatusDropDown:false,
                    isLoading: false,
                    rowDataBatchStatus:{},
                    statusVisible:false
                   
                },()=>this.getBatchesList())
                this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.messsage, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
        
    }

    render() {
               const header2 = (
            <div className="table-header ma-tbl-header ">
                <div className="ma-tbl-left">
                    <h2 >Users List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearchUsers}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearchUsers: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                    <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                        <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                    </Authorizer> 


                    <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </Authorizer>*/}
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                {this.state.columns.map((column) => {
                                    return <li>{column.columnName}</li>
                                })}
                            </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        const header = (
            <div className="table-header flex justify-between ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Batches List For  <span style={{color:"green",fontWeight:"bold"}}>{(this.props.location.state.course?.courseName.charAt(0).toUpperCase()+ this.props.location.state.course?.courseName.slice(1))}</span></h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    {/* <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns"
                                onClick={(event) => this.menu.toggle(event)}
                                data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul> */}
                </div>
                {/* <Menu
                style={{ width: "100px" }}
                model={[
                    {
                        template: (<div className='search-filter'>
                            <h4 className="sort-title">Sort & Filter</h4>
                            <ul>
                                {this.state.columns.map((column) => {
                                    return <li>{column.columnName}</li>
                                })}
                            </ul>
                        </div>)
                    }]
                }
                popup
                ref={el => this.menu = el} /> */}
            </div>
        );
        return (
            <Navbar>
                {this.state.batchManagement &&
                    <>
                        <div className="p-grid ma-toolbar">
                            <div className="p-col-12 p-md-1 ma-no-p">
                            </div>
                            <div className="p-col-12 p-md-11 ma-no-pm">
                                <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                            </div>
                        </div>
                        <div className='ma-main '>
                            <div className='flex-between'>
                                <div style={{marginLeft:"-5px"}} className='flex ma-mt20'>
                                    <div className='ma-mlr5'>
                                        <div>
                                            <Calendar placeholder='Start Date'  dateFormat='dd-mm-yy' showIcon value={this.state.fromDate}
                                                onChange={(e) => this.onChangeFromDateForBatch(e)} />

                                        </div>
                                        {/* {this.state.fromDateErr && <span className='p-error'>From Date required</span>} */}
                                    </div>
                                    <div className='ma-mlr5'>
                                        <div>
                                            <Calendar placeholder='End Date' dateFormat='dd-mm-yy' showIcon value={this.state.toDate}
                                                onChange={(e) => this.onChangeToDateForBatch(e)} />

                                        </div>
                                        {/* {this.state.toDateErr && <span className='p-error'>To Date required</span>} */}
                                    </div>
                                    <div className='ma-mt5'>
                                    <Authorizer permId={PERMISSIONS.COURSE_SEARCH_BATCH} >
                                        <Button label='Fetch Batches' className="p-button-success" onClick={() => this.getBatchesList()} />
                                  </Authorizer>
                                        <Button label='Reset' className="p-button-success ma-mlr5" onClick={() => this.onClickReset()} />
                                    </div>
                                </div>
                                <div style={{marginTop:"28px"}}>
                                <Button type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBack}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
                                </div>
                             </div>  
                             <Authorizer permId={PERMISSIONS.COURSE_ADD_BATCH}>  
                             <div style={{display:"flex",justifyContent:"end",marginTop:"18px",marginBottom:"-10px"}}>
                            
                            <Button onClick={() => this.onClickCreateNewBatch()} label='Create new Batch' icon="pi pi-plus" className="p-button-success" />
                            </div>
                            </Authorizer>
                               
                                <div>
                                    <DataTable ref={(el) => this.dt = el}
                                        lazy className='ma-mt30'
                                        value={this.state.batchesList}
                                        dataKey="_id"
                                        paginator
                                        onPage={this.onPage}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}

                                        // selectionMode="checkbox"
                                        // selection={this.state.selectedCourses}
                                        // onSelectionChange={this.onSelectCourse}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Batches"
                                        globalFilter={this.state.globalFilter2}
                                        header={header}
                                        paginatorLeft={() => {
                                            return <>
                                                <p className='avail-q'> Total Batches:  <span>{this.state.batchesList && this.state.batchesList.length}</span> </p>
                                            </>
                                        }}
                                    >
                                        {/* <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column> */}
                                        {/* <Column headerStyle={{ width: '70px' }} body={(rowData) => {
                                            return (<><Checkbox onChange={e => this.onChangeSelection(rowData,e)}
                                                checked={this.state.selectedCourses?.includes(rowData._id)}
                                            ></Checkbox>
                                            </>)
                                            }} header="Select"
                                        /> */}
                                        <Column field="slNo" headerStyle={{ width: "70px" }} header="Sr.No" body={(rowData, rowIndex) => {
                                            return <span>
                                                {(this.state.lazyParams.page * this.state.lazyParams.rows) + rowIndex.rowIndex + 1}
                                            </span>
                                        }} />
                                        <Column field="batchName" header="Batch Name" />
                                        <Column field="trainerName" header="Mentor" />
                                        {/* <Column field="courseName" headerStyle={{ width: "180px" }} header="Course" />*/}
                                        {/* <Column field="mode" header="Start Date" />
                                        <Column field="language" header="Language" style={{width : "15rem"}} body = {(rowData) => {
                                            return <>{rowData.language.toString()}</>
                                        }}/>
                                        <Column field="strength" header="Strength" /> */}
                                        <Column field="startDate" header="Start Date" body={(rowData) => {
                                            return (<><span>{moment.utc(rowData?.startDate).format('DD-MM-YYYY')}</span></>)
                                        }} />
                                        <Column field="endDate" header="End Date" body={(rowData) => {
                                            return (<><span style={{width:"90px"}}>{moment.utc(rowData?.endDate).format('DD-MM-YYYY')}</span></>)
                                        }} />
                                        <Column field="isActive" header="Active/InActive"  headerStyle={{ width: "180px",textAlign:"center" }} body={(rowData) => {
                                    return (
                                       <div style={{display:"flex",justifyContent:"center"}}>
                                    <InputSwitch tooltip={rowData?.isActive === "Active" ?"Active":"InActive"} style={{ width: "50px",textAlign:"center" }} onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.isActive == 'Active'} />
                                  
                                  
                                </div>
                            )
                                   }}/>
                                        {/* <Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} > */}
                                        <Column field="status" headerStyle={{ width:PERMISSIONS.COURSE_BATCH_STATUS ? "125px": '0px',textAlign:"center" }} header="Status" body={(rowData, rowIndex) => {
                                            if(rowData.status === "Scheduled"){
                                                return (<Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} ><div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData})} style={{backgroundColor:"#B2EBF2",paddingTop:"5px",border:"1px solid grey", cursor:"pointer",  display:"flex",justifyContent:"space-around" ,paddingBottom:"3px",textAlign:"center",color:"black"}}>{rowData?.status} <SvgViewer  className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" /></div></Authorizer>)
                                            }
                                            else if(rowData.status === "Ongoing"){
                                                return (<Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} ><div  onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData})} style={{textAlign:"center",color:"black" ,paddingTop:"5px",border:"1px solid grey",cursor:"pointer",   display:"flex",justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#C8E6C9 "}}> {rowData?.status} <SvgViewer  className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit"  /></div></Authorizer>)
                                            }
                                            else if(rowData.status === "Cancelled"){
                                                return (<Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} ><div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData})} style={{textAlign:"center",color:"black" ,paddingTop:"5px",border:"1px solid grey",cursor:"pointer",   display:"flex",justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#FFCDD2"}}>{rowData?.status} <SvgViewer  className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit"  /></div></Authorizer>)
                                            }
                                            else if(rowData.status === "ReScheduled"){
                                                return (<Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} ><div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData})} style={{textAlign:"center",color:"black" ,paddingTop:"5px",border:"1px solid grey",cursor:"pointer",   display:"flex",justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#FFF9C4 "}}>{rowData?.status} <SvgViewer  className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" /></div></Authorizer>)
                                            }
                                            else if(rowData.status === "Completed"){
                                                return (<Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} ><div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData})} style={{textAlign:"center",color:"black" ,paddingTop:"5px",border:"1px solid grey",cursor:"pointer",  width: "105px",display:"flex", justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#98FB98"}}>{rowData?.status} <SvgViewer  className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" /></div></Authorizer>)
                                            }
                                            else{
                                                return (<Authorizer permId={PERMISSIONS.COURSE_BATCH_STATUS} ><div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData})} style={{textAlign:"center",color:"black" ,paddingTop:"5px",border:"1px solid grey",cursor:"pointer",  width: "105px",display:"flex", justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#98FB98"}}>{""} <SvgViewer  className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" /></div></Authorizer>)
                                            }
                                        }} />
                                        {/* </Authorizer> */}
                                        {/* <Column field="status" header="Status" /> */}
                                        <Column field="roomstrength" headerStyle={{ width: "200px",textAlign:"center" }} header="Strength" body={(rowData, rowIndex) => {
                                            return <p style={{textAlign:"center"}}>
                                                {rowData.totalAssigned}/{rowData.roomStrength}
                                            </p>
                                        }} />
                                        <Column header='Actions' body={(rowData) => {
                                            return (
                                                <>
                                                    {
                                                    (new Date() < new Date(rowData?.startDate)) ?
                                                    <Authorizer permId={PERMISSIONS.COURSE_EDIT_BATCH}>
                                                     <span onClick={() => this.onClickEditBatch(rowData)}>
                                                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Edit" tooltipOptions={{ position: 'top' }} />
                                                     </span>
                                                     </Authorizer>
                                                     :
                                                     <Authorizer permId={PERMISSIONS.COURSE_EDIT_BATCH}>
                                                     <span>
                                                       <SvgViewer style={{filter: "blur(1px)"}} className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" tooltip="Disabled" tooltipOptions={{ position: 'top' }} />
                                                    </span>
                                                    </Authorizer>
                                                    }
                                                  {
                                                    (new Date() < new Date(rowData?.startDate)) ?
                                                    <Authorizer permId={PERMISSIONS.COURSE_EDIT_BATCH}>
                                                    <span data-pr-tooltip="Delete Question" data-pr-position="top" className={`ma-ml10 ma-pointer`} onClick={() => this.onDeleteBatch(rowData)}>
                                                        <DeleteIcon height={20} width={20} />
                                                    </span>
                                                    </Authorizer >
                                                    :
                                                    <Authorizer permId={PERMISSIONS.COURSE_EDIT_BATCH}>
                                                    <span  style={{filter: "blur(1px)"}} className={`ma-ml10 ma-pointer`}>
                                                        <DeleteIcon  height={20} width={20}/>
                                                    </span>
                                                    </Authorizer>
                                                  }
                                                    {/* <span data-pr-tooltip="Notification " data-pr-position="top" className={`ma-ml10 ma-pointer`} >
                                                        <NotificationIcon height={20} width={20} />
                                                    </span> */}


                                                </>
                                            )
                                        }} />
                                    </DataTable>
                                </div>



                                                    </div>
                    </>}

                    <Dialog header="Update Status" visible={this.state.showBatchStatusDropDown} style={{ width: '30vw' }} onHide={() => this.setState({showBatchStatusDropDown:false})} 
                    footer={()=>{
                       return (<div> 
                        <Button label="update" icon="pi pi-check" onClick={this.onUpdateBatchStatus}  autoFocus />
                        <Button label="Cancel" icon="pi pi-times" onClick={() => this.setState({showBatchStatusDropDown:false,batchStatus:this.state.rowDataBatchStatus?.status})}   className="p-button-text" />
                       
                    </div>)   }}>
                        
                        <div className="p-col-4 p-md-3 ma-mr20">
                                <p className='ma-label-s1'>Status<span className='ma-required'>*</span></p>
                                <Dropdown value={this.state.batchStatus||this.state.rowDataBatchStatus?.status} style={{ height: "35px" }} className='ma-w200 md:w-14rem' placeholder='Select' options={StatusOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => this.setState({batchStatus:e.value})}
                                />
                              
                        </div>
                       
                   
               
                    </Dialog>
                    <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.statusVisible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ statusVisible:false, editRoleData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onUpdateBatchStatus} />
                        </div>)
                    }}
                    onHide={() => this.setState({ statusVisible: false, editRoleData: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.statusHeaderMsg}</p>
                    <p className='ma-alert-msg'>{this.state.statusMessage}</p>
                </Dialog>

                <Dialog header={<></>} draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true}
                    dismissableMask={true} closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" type='button' className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, deleteBatchId: null }) }} />
                            <Button label="Yes" type='button' className='ma-m-lr10' onClick={this.onClickDelete} />
                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, deleteBatchId: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg ' style={{ color: "#000", fontSize: "13px" }}>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog visible={this.state.showAssignUsers} header={"Assign to users"} draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true}
                    dismissableMask={false} closable={true} style={{ maxWidth: '70vw', minWidth: '70vw' }}
                    onHide={() => this.setState({ showAssignUsers: false, users: [], selectedUser: [], filterData: {}, showSearch: false, globalSearchUsers: "" })}>
                    <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} usersClear={this.usersClear} />
                    <div className="card datatable-crud-demo ma-mt10">
                        <DataTable ref={(el) => this.dt = el}
                            lazy
                            value={this.state.users}
                            dataKey="_id"
                            paginator
                            onPage={this.onPageChangeUsers}
                            // onSort={this.onSortUsers}
                            first={this.state.lazyParams.first}
                            last={this.state.userstotalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.userstotalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                            globalFilter={this.state.globalFilter2}
                            //selection={this.state.selectedUser}
                            //onSelectionChange={this.onSelectUser}
                            paginatorLeft={() => {
                                return <>
                                    <p className='avail-q'> Selected Users:  <span>{this.state.selectedUser && this.state.selectedUser.length}</span> </p>
                                </>
                            }}
                            selectionMode="checkbox"

                            header={header2}>
                            <Column headerStyle={{ width: '4em' }} field='_id' header={'Select'}
                                body={(rowData) => {
                                    return <Checkbox checked={this.state.selectedUser.includes(rowData.user_id)}
                                        onChange={(e) => this.onChangeSelectUser(rowData, e)}
                                    ></Checkbox>
                                }}>
                            </Column>
                            <Column field="given_name" header="Given Name" />
                            <Column field="phone_number" header="Phone" />
                            <Column field="email" header="Email ID" />
                            <Column field="custom:board" header="Board" />
                            <Column field="custom:grade" header="Grade" />
                            {/* <Column header="Actions" body={(rowData) => {
                            return (<>
                                <Button label="Assign Assessment" onClick={() => this.assignSubjects(false, [rowData.sectionId])} />
                            </>
                            );
                        }} ></Column> */}
                        </DataTable>
                        <Button label="Assign Course" className='p-button-success ma-mt20' onClick={this.onClickAssignExamsToUsers} />
                    </div>
                </Dialog>
                {this.state.createBatch && <CreateBatch course= {this.props.location.state?.course} courseId={this.props.location.state?.courseId} editBatch={this.state.editBatch} onBackFromCreate={this.onBackFromCreate} boards={this.props.boards} />}
                {/* {this.state.showAssessmentsData && <ShowAssessmentsData data={this.state.assessmentData} assessmentType={this.state.assessmentType} onBackFromAssessments={this.onBackFromAssessments} />} */}
                {this.state.isLoading && <><LoadingComponent /></>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
       // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});
export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(BatchManagement))) 