import { InputText } from 'primereact/inputtext'
import React, { Component } from 'react'
import { getFormFields, isFormValid, onTextChange } from '../../utile/formHelper';
import { Button } from 'primereact/button';
var createBatchRulesFieldsJson = require('./batchRules.json')
export default class rules extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(createBatchRulesFieldsJson);
        this.state = {
            createBatchRulesFields: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
    }
    onAddRule = () => {
        let body = this.state.createBatchRulesFields

        const formStatus = isFormValid(createBatchRulesFieldsJson, this.formFields.formValidations, body);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        console.log(isCompleteFormValid, body, "Rules")
    }

    render() {
        const { createBatchRulesFields, formValidations } = this.state
        return (
            <div className='p-grid tab-active-body' style={{ paddingLeft: '20px' }}>
                <div className='p-col-12' style={{ marginTop: "15px" }}>
                    <h2 className='ma-active-item'>Batch Formation Rules</h2>
                    <div className='p-pl-2'>
                        <div>{/* MinCourses */}

                            <div className=" " style={{ display: "flex" }}>
                                <p className='ma-label-s1 p-col-12 p-md-3'>No of teachers from each school<span className='ma-required'>*</span></p>
                                <div className='p-col-12 p-md-3'>
                                    <InputText value={createBatchRulesFields.noOfTeachersFromSchool} className='p-inputtext-style1 ma-w100p md:w-14rem '
                                        onChange={(e) => onTextChange(e.target.value, 'noOfTeachersFromSchool', this, createBatchRulesFieldsJson, createBatchRulesFields, formValidations, 'createBatchRulesFields', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['noOfTeachersFromSchool'].isValid && <p className="p-error">{formValidations.fields['noOfTeachersFromSchool'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className=" " style={{ display: "flex" }}>
                                <p className='ma-label-s1 p-col-12 p-md-3'>Total teachers in school<span className='ma-required'>*</span></p>
                                <div className='p-col-12 p-md-3'>
                                    <InputText value={createBatchRulesFields.teachersInSchool} className='p-inputtext-style1 ma-w100p md:w-14rem '
                                        onChange={(e) => onTextChange(e.target.value, 'teachersInSchool', this, createBatchRulesFieldsJson, createBatchRulesFields, formValidations, 'createBatchRulesFields', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['teachersInSchool'].isValid && <p className="p-error">{formValidations.fields['teachersInSchool'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className=" " style={{ display: "flex" }}>
                                <p className='ma-label-s1 p-col-12 p-md-3'>Total teachers in who can attend<span className='ma-required'>*</span></p>
                                <div className='p-col-12 p-md-3'>
                                    <InputText value={createBatchRulesFields.noOfTeachersCanAttend} className='p-inputtext-style1 ma-w100p md:w-14rem '
                                        onChange={(e) => onTextChange(e.target.value, 'noOfTeachersCanAttend', this, createBatchRulesFieldsJson, createBatchRulesFields, formValidations, 'createBatchRulesFields', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['noOfTeachersCanAttend'].isValid && <p className="p-error">{formValidations.fields['noOfTeachersCanAttend'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-col-12 p-md-12 flex justify-start  p-mt-5'>
                        <Button label='Add New Rule' onClick={this.onAddRule} />
                        <Button className='p-ml-5 ' style={{ backgroundColor: 'red', border: 'none' }} label='Remove' onClick={this.onRemoveRule} />
                    </div>

                </div>


            </div>
        )
    }
}
