import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import {
    baseUrlAdmin
} from '../apiConstants';



const dispatchBackScreenData = (data) => ({
  type: ActionTypes.GET_FETCH_COURSE_STATE,
  payload: data
});

export const OnGetCourseData = (data) => (dispatch) => {
    dispatch(dispatchBackScreenData(data));
}


