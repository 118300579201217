import * as ActionType from "../actionTypes";

const initialState = {
    courseState:{},
}

export default (state = initialState, action) => {
 
   
  switch (action.type) {
    case 'GET_FETCH_COURSE_STATE':
      return { ...state, courseState: action.payload };
    default:
      return state;
  }
        
    
  }
  