import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import AttachmentsUploadWithProgress from '../discussionBoard/common/attachmentUploadWithProgress';

export default class ClassDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classDetails: {
                "_id": "6295c8c27d666dc24aa97867",
                "subjectId": "96696baf-4b3d-408b-b5b0-b2e2735f363a",
                "subjectName": "Mathematics",
                "resources": [
                    {
                        "id": "41afea82-2a96-4183-a069-61f54b9e4eca",
                        "data": {
                            "url": "https://xd.adobe.com/view/146bb0e2-dfcd-4b3e-8958-09c83cac818a-4138/screen/fff1c034-e92a-4fa9-8e92-9612ae85b3a5/"
                        },
                        "assigned": true
                    },
                    {
                        "id": "4e28a411-d4b8-46f7-abf2-caa887b3ebaa",
                        "data": {
                            "url": "https://xd.adobe.com/view/146bb0e2-dfcd-4b3e-8958-09c83cac818a-4138/screen/fff1c034-e92a-4fa9-8e92-9612ae85b3a5/"
                        },
                        "assigned": true
                    }
                ],
                "sectionIds": [
                    "a63d95e4-b07c-4324-9f4a-b9b57fa51b7a"
                ],
                "boardId": "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7",
                "boardName": "Board 1",
                "classId": "bf805df2-a942-48f3-813b-eef60fa3528d",
                "className": "Class 1",
                "groupId": "904a3e8c-d76a-4c9b-965a-89906e704c10",
                "groupName": "NO_GROUP",
                "customJoinLink": "https://xd.adobe.com/view/146bb0e2-dfcd-4b3e-8958-09c83cac818a-4138/screen/fff1c034-e92a-4fa9-8e92-9612ae85b3a5/",
                "title": "ererrerrererereewrerewre",
                "description": "erer rrre rwererrrerere",
                "chapters": [
                    {
                        "chapterId": "cfcadad2-dd0b-4c1b-a873-2fe0e37e61f1",
                        "chapterName": "Chapter 1"
                    },
                    {
                        "chapterId": "1eb7c5f2-efe1-43a3-a445-12c3843e572b",
                        "chapterName": "dfdd"
                    }
                ],
                "duration": "34",
                "meetingApp": "custom",
                "startTime": "2022-05-31T09:59:44.709Z",
                "strength": "23",
                "teacherId": "f333f441-21cf-48c9-afda-6bfc85dc8d85",
                "teacherName": "Admin",
                "meetingStatus": "completed",
                "meetingInfo": {
                    "joinUrl": "https://xd.adobe.com/view/146bb0e2-dfcd-4b3e-8958-09c83cac818a-4138/screen/fff1c034-e92a-4fa9-8e92-9612ae85b3a5/"
                },
                "createdAt": "2022-05-31T07:50:26.785Z",
                "updatedAt": "2022-05-31T07:54:29.603Z",
                "attachments": [
                    {
                        "type": "pdf",
                        "uri": "https://macademy-profiles-new.s3.ap-south-1.amazonaws.com/uploads/2022-05-31T07%3A51%3A25.387Zsample.pdf",
                        "name": "sample.pdf",
                        "newFileName": "2022-05-31T07:51:25.387Zsample.pdf",
                        "mimeType": "application/pdf"
                    }
                ]
            }
        }


    }


    render() {

        const { classDetails } = this.props;

        // console.log(classDetails, 'classDetails')
        return (<><Dialog
            visible={true}
            style={{ width: '80%', minHeight: '90vh' }}
            header={'Class Details'}
            blockScroll={true}
            modal
            draggable={false}
            closeOnEscape={true}
            dismissableMask={false}
            closable={true}
            onHide={this.props.onHide}>
            <div className='class-details'>
                <p className='cls-value'>  <span className='cls-title'>Teacher</span>{classDetails.teacherName}</p>
                <p className='cls-value'>  <span className='cls-title'>Board</span>{classDetails.boardName}</p>
                <p className='cls-value'>  <span className='cls-title'>Grade</span>{classDetails.className}</p>
                <p className='cls-value'>  <span className='cls-title'>Title</span>{classDetails.title}</p>
                <p className='cls-value'>  <span className='cls-title'>Description</span>{classDetails.description}</p>
                <p className='cls-value'>  <span className='cls-title'>Resources</span></p>
                {(classDetails.attachments && classDetails.attachments.length) || (classDetails.resources && classDetails.resources.length) ? <>
                    <AttachmentsUploadWithProgress noPreview={true} attachments={classDetails.attachments} noRemove={true} />
                    {
                        classDetails.resources && classDetails.resources.length ? <>
                            {
                                classDetails.resources.map(a => <p><a className='cls-resource-link' href={a.url} target="_blank" >{a.url}</a></p>)
                            }
                        </> : <></>
                    }
                </> : <p>No Resources</p>}
            </div>
        </Dialog>
        </>
        )
    }
}
