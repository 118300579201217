
export const TestTypes = [
    // {
    //     value: 'Quiz',
    //     label: 'Quiz'
    // },
    {
        value: 'Assessment',
        label: 'Assessment'
    },
    // {
    //     value: 'MockTest',
    //     label: 'Mock Test List'
    // },
    // {
    //     value: 'Template List',
    //     label: 'Template List'
    // },  
    // {
    //     value: 'AdhocMockTest',
    //     label: 'Adhoc Mock Test'
    // },
    // {
    //     value: 'SubjectiveTest',
    //     label: 'Subjective Test'
    // },
   
    // {
    //     value: 'AdhocMockTestT2',
    //     label: 'AdhocMockTest T2'
    // },
    // {
    //     value: 'DiagnosticTest',
    //     label: 'Digonstic Test'
    // }
];

export const TestsForReports = [
    {
        value: 'AdhocMockTest',
        label: 'Adhoc Mock Test',
        
    }
    ,
    {
        value: 'AdhocMockTestT2',
        label: 'AdhocMockTest T2'
    }
];

export const ExamTemplates = [
    {
        value: 'AdhocMockTest',
        label: 'Adhoc Mock Test'
    },
    {
        value: 'SubjectiveTest',
        label: 'Subjective Test'
    },
    {
        value: 'AdhocMockTestT2',
        label: 'AdhocMockTest T2'
    }
];