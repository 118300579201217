import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import { BasicLazyParams } from '../../../utile';
import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import moment from 'moment/moment';
import { Badge } from 'primereact/badge';


const items = [
    { label: 'Analytics' },
    { label: 'Teachers Proffesional Training' },
    { label: 'Course Management', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class ShowAssessmentsData extends Component {
    constructor(props){
        super(props);
        this.state = {
            lazyParams : BasicLazyParams,
            isLoading : false,
            assessmentData : [],
            totalRecords : ""
        }
        // const items = [
        //     { label: 'Analytics' },
        //     { label: 'Teachers Proffesional Training' },
        //     { label: 'Course Management', className: 'ma-active-item' }
        // ];
        this.service = new Service()
    }

    componentDidMount() {
        if(this.props.data && this.props.assessmentType){
            this.getAssessmentsData()
        }
    }

    getAssessmentsData =  () => {
        let obj = {}
        obj.courseId = this.props.data?._id
        switch(this.props.assessmentType){
            case "Prerequisite":
                obj.teacherTestType = "prerequisite"
                break
            case "Pre Assessment" : 
                obj.teacherTestType = "preAssessment"
                break
            case "Post Assessment":
                obj.teacherTestType = "postAssessment"
                break
            default : 
                break
        }
        if(this.props.data?._id){
            this.setState({isLoading : true})
            let { lazyParams} = this.state;
            let url = `${baseUrlAdmin}/course/type?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`
            this.service.post(url,obj,true).then(res => {
                if(res?.status && res?.res?.status){
                    this.setState({
                        isLoading : false,
                        assessmentData : res.res.data,
                        totalRecords : res.res.totalRecords
                    })
                }else{
                    this.setState({isLoading : false})
                    this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
                }
            }).catch(err => {
                console.log(err)
                this.setState({isLoading : false})
                this.toast.show({ severity: 'error', summary: '', detail: err.errMessage, life: 3000 });
            })
        }else{
            this.toast.show({ severity: 'error', summary: '', detail: 'Error in ID', life: 3000 });
        }   
    }

    onBackFromAssessments = () => {
        this.props.onBackFromAssessments()
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>{this.props.showTrainerAssessmentsData ? "Trainer Management":"Course Manegement"}</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return {lazyParams: {...prevState?.lazyParams,...event}};
        }, this.getAssessmentsData);
    }

  render() {
    return (
        <>
        <div className="p-grid ma-toolbar">
            <div className="p-col-12 p-md-1 ma-no-p">
            </div>
            <div className="p-col-12 p-md-11 ma-no-pm">
                <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
            </div>
            {/* <p className='back-arrow' onClick={this.onBackFromAssessments}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
        </div>
        <div className='ma-main'>
        <div style={{display:"flex",justifyContent:"end"}}>
            <Button style={{marginBottom:"20px"}} type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBackFromAssessments}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>

        </div>
        <DataTable ref={(el) => this.dt = el}
            lazy
            value={this.state.assessmentData}
            dataKey="_id"
            paginator
            onPage={this.onPageChangeUsers}
            // onSort={this.onSortUsers}
            first={this.state.lazyParams.first}
            last={this.state.totalRecords}
            rows={this.state.lazyParams.rows}
            totalRecords={this.state.totalRecords}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tests"
            globalFilter={this.state.globalFilter2}
            header={<h2>{this.props.assessmentType === "Prerequisite"?"Self-Assessment":this.props.assessmentType }</h2>}
            >
            <Column field="user_info.given_name" header="Given Name" />
            {/* <Column field="user_info.family_name" header="Father Name" /> */}
            {/* <Column field="user_info.email" header="Email ID" /> */}
            {/* <Column field="user_info.custom:board" header="Board" /> */}
            {/* <Column field="user_info.grade" header="Class" /> */}
            <Column field="assessment.courseName" header="Course Name" />
            <Column field="assessment.examName" header="Exam Name" />
            <Column field="assessment.noOfQuestions" header="noOfQuestions"/>
            <Column field="assessment.totalMarks" header="Total Marks"/>
            <Column field="assessment.totalTime" header="Total Time"/>
            <Column field="assessment.status" header="Status" body = {(rowData) => {
                return (
                    <Badge severity={rowData.assessment?.status === 'pending' ? "warning" : "info"} value={rowData.assessment?.status.toLowerCase()}></Badge>
                )}}/>
            <Column field="" header="Submitted" body = {(rowData) => {
                let submittedDate = new Date(rowData.assessment.submittedAt)
                return (
                    <>{rowData.assessment?.examStatus === "SUBMITTED" ? <p>{moment(submittedDate).format("DD-MM-YYYY HH:mm:ss")}</p> : "--"}</>
                )}}/>
            <Column field="" header="Result" body = {(rowData) => {
                return (
                    <>{rowData.assessment?.status === "Completed"?  <p>{rowData.assessment?.totalMarksSecured}</p> : "--"}</>
                )}}/>
        </DataTable>
        </div>
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
        {this.state.isLoading && <LoadingComponent />}
        </>
    )
  }
}

export default ShowAssessmentsData