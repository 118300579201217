import React, { Component } from 'react'
import Service from '../../services';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import SvgViewer from './../customComponents/svgViewer';
import MathJax from 'react-mathjax-preview';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import Authentication from './../session';
import { Checkbox } from 'primereact/checkbox';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { withRouter } from 'react-router-dom';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl, onChipsChange } from '../../utile/formHelper';
import { Difficulty, ExamTypes, QuestionSource, QuestionTargets, QuestionTypeKeys, QuestionTypes, QuestionTypesWithAQ, SkillTypes, Status } from './constants';
import { baseUrlAdmin } from '../../store/apiConstants';
import {
    getBoardsData,
    getImageUrl
} from './../../store/actions';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../utile';

import SingleQuestion from './common/singleQuestion';
import { MultiSelect } from 'primereact/multiselect';
import PreviewQuestion from './common/previewQuestion';
import AssignQuestionToTopic from './common/assignQuestionToTopic';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import QuestionInTable from './common/questionInTable';
import { EditIcon, PreviewIcon, WarningIcon } from '../svgIcons';
import _ from 'lodash';
class FetchQuestions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            questionTarget: null,
            boardId:this.props.boards[0]?.boardId,
            classId: null,
            groupId: null,
            classes: [],
            boards: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            subjectId: null,
            chapterId: null,
            topicId: null,


            questionType: null,
            difficulty: null,
            skillType: null,
            status: null,

            isShowQuestionPreview: false,
            isSearchByQuestionId: false,

            questions: [],
            columns: [{ columnName: 'Question Type', isVisible: true, id: 'questionType' },
            { columnName: 'Video Linked', isVisible: true, id: 'videoLinking' },
            { columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
            { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
            { columnName: 'Exam Type', isVisible: false, id: 'examType' },
            { columnName: 'Source', isVisible: false, id: 'source' },
            { columnName: 'Status', isVisible: false, id: 'status' },

            ],
            showSearch: false,
            globalFilter: null,
            globalSearch: '',
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            selectedQuestions: [],

            warningDialog: warningDailogInit,
            disableAssign: false
        }
        this.service = new Service();
    }



    componentDidMount() {
        if (this.props.questionId) {

        } else {



        }


        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
        //  else {

        //     const _classes = [];
        //     const selectedBoard = this.props.boards && this.props.boards.find((board) => board.isBase);

        //     if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        //         selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        //     }
        //     this.setState({
        //         classes: _classes,
        //         boardId: selectedBoard.boardId
        //     });
        // }
        this.onChangeBoard(this.props.boards[0]?.boardId)


    }


    componentDidUpdate(prevProps) {
        // if (prevProps.boards != this.props.boards) {
        //     const _classes = [];
        //     const selectedBoard = this.props.boards && this.props.boards.find((board) => board.isBase);
        //     if (selectedBoard) {


        //         if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        //             selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        //         }
        //         this.setState({
        //             classes: _classes,
        //             boardId: selectedBoard.boardId
        //         });
        //     }
        // }

    }

    onQuestionTargetChange = (questionTarget) => {
        this.setState({
            questionTarget,
            questions: [],
            totalRecords: 0,
            selectedQuestions: []
        }, () => {
        })
    }


    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: [],
            questions: [],
            totalRecords: 0,
            selectedQuestions: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId:this.props.boards[0]?.boardId
            ,
            boardName: selectedBoard?.boardName,
            resourseOrigin: selectedBoard?.isBase ? 'upload' : null

        });

    };
   

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: [],
            questions: [],
            totalRecords: 0,
            selectedQuestions: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === this.state.boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass.className,
                groupId: _groups[0].value,
                groupName: _groups[0].label,
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,

                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };




    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                subjectName: selectedSubject[0].subjectName,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName,
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            });
    };

    onSearchByQuestionIdToggle = (event) => {

        this.setState({
            isSearchByQuestionId: event.target.checked,
            isDateSearch: false,

        }, this.handleClearSearch());
    };


    handleClearSearch = () => {

        this.setState(
            {
                //  boardId: null,
                //  classId: null,
                groupId: null,
                //  classes: [],
                boards: [],
                groups: [],
                subjects: [],
                chapters: [],
                topics: [],
                subjectId: null,
                chapterId: null,
                topicId: null,
                questionId: '',
                selectedQuestions: []

            }, 
            this.onChangeBoard(this.props.boards[0]?.boardId)
             
        );
    };

    onFetch = () => {
        this.setState({
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedQuestions: []
        }, this.onSearch);
    }

    onSearch = () => {

        const { questionTarget,
            boardId, classId,
            groupId, subjectId,
            chapterId, topicId,
            questionType, difficulty,
            skillType, status,
            lazyParams, globalSearch,
            isSearchByQuestionId
        } = this.state;


        if (isSearchByQuestionId) {
            return this.onSearchByQuestionId()
        } else {



            let filters = { questionTarget };

            if (!questionTarget) {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select question Target', life: 3000 });
                return
            }

            if (questionTarget == 'Adhoc' && (chapterId && !topicId)) {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select Topic', life: 3000 });
                return
            }

            if (questionTarget == 'Normal') {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            } else {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            }




            if (questionType && questionType.length) {
                filters = { ...filters, questionType: questionType }
            }
            if (difficulty && difficulty.length) {
                filters = { ...filters, difficulty: difficulty }
            }
            if (skillType && skillType.length) {
                filters = { ...filters, skillType: skillType }
            }
            if (status && status.length) {
                filters = { ...filters, status: status }
            }

            this.setState({
                isLoading: true,
                questions: [],
                totalRecords: 0

            });


            this.service.post(`${baseUrlAdmin}/question/filter/questions/page?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {

                if (data && data.status && data?.res?.data) {

                    let disableAssign = false;
                    if (data.res.data.questions.find(a => a.questionTarget == 'General')) {
                        disableAssign = true;

                    }

                    this.setState({
                        totalRecords: data.res.data.totalRecords,
                        questions: data.res.data.questions,
                        isLoading: false,
                        disableAssign
                    })


                } else {

                    this.setState({
                        isLoading: false,

                    });
                }


            }).catch(e => {
                this.setState({
                    isLoading: false,

                });
                console.log(e);

            });

        }
    }

    onSearchByQuestionId = () => {
        let { questionId } = this.state;

        if (questionId && questionId != '') {
            this.setState({
                isLoading: true,
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            });
            this.service.get(`${baseUrlAdmin}/question/${questionId.trim()}`, true).then((data) => {

                if (data && data.status) {
                    if (data?.res?.questions && Object.keys(data?.res?.questions).length) {
                        this.setState({
                            totalRecords: 1,
                            questions: [data?.res?.questions],
                            isLoading: false
                        })

                    } else {
                        this.setState({

                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Warning', detail: 'No Question found with the given Id', life: 3000 });
                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Some error occured', life: 3000 });
                    this.setState({

                        isLoading: false
                    })
                }


            }).catch(e => {
                this.setState({

                    isLoading: false
                })

                this.toast.show({ severity: 'error', summary: 'Warning', detail: e.message, life: 3000 });
                console.log(e);

            });
        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid question', detail: 'Please enter question Id', life: 3000 });
        }



    }

    onSelectQuestions = (e) => {
        this.setState({ selectedQuestions: e.value })
    }


    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        })





    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
               // selectedQuestions :[]
            }
        }, this.onSearch);
    }

    onPage = (event) => {
        
        
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }


    optionsRender = (options) => {
        return <div>
            {options && options.length ? options.map(option => <div key={option.id}> <SingleQuestion optionN={option.id} mathJaxValue={option.value} attachment={option.imageUrl} /> </div>) : <></>
            }
        </div>

    }






    // renderQuest  = (question) => {
    //     console.log(question)
    //     switch (question.questionType) {
    //         case QuestionTypeKeys.SCCT:
    //         case QuestionTypeKeys.MCCT:
    //             return <>
    //                 <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />
    //                 {this.optionsRender(question.options)}
    //             </>

    //         default: return <></>

    //     }
    // }


    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Questions:  <span>{this.state.totalRecords}</span> </p>
        </>
    }
    showAssignPopup = () => {
        if (this.state.selectedQuestions && this.state.selectedQuestions.length) {
            this.setState({ showAssignPopup: true })
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please Select atleast one question to assign.', life: 3000 });
        }
    }

    onSucessfullyAssigned = () => {
        this.setState({
            showAssignPopup: false
        });
        this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Successfully Assigned to topics.', life: 3000 });
    }


    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/question/movequestionandlink`;
        this.service.post(url, { questionId: this.state.questionToMove.questionId }, true).then((res) => {
            if (res && res.status) {
                this.setState({
                    isLoading: false,

                }, this.onFetch)
                // this.toast.show({ severity: 'success', summary: 'Role Updated', detail: res.res.message, life: 3000 });

            } else {

                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });

        setTimeout(() => {
            this.setState({
                isLoading: false
            });

        }, 2000);

    }
    render() {
        const { questionToMove } = this.state;
        //  console.log('selectedQuestions', this.state.selectedQuestions)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearch: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        const { isSearchByQuestionId, questionId } = this.state;
        return (
            <div>
                <Authorizer permId={PERMISSIONS.EXAM_SEARCH_BY_QUESTIONID} >
                    <Checkbox inputId="usearch" value="true"
                        onChange={this.onSearchByQuestionIdToggle}
                        checked={isSearchByQuestionId}
                    ></Checkbox>
                    <label htmlFor="usearch" className="p-checkbox-label">Search By Question Id</label>
                </Authorizer>
                {
                    isSearchByQuestionId ? <div className='ma-mt20'>
                        <InputText value={questionId} onChange={(e) => {
                            this.setState({
                                questionId: e.target.value
                            })
                        }} className='p-inputtext-style1 ' />
                        <span className='ma-required-mark '>*</span>
                        <div className='ma-mt10'>
                            <Button label="Search" className='' onClick={this.onSearchByQuestionId} />
                            <Button label="Clear" className='ma-m-lr10 p-button-outlined' onClick={this.handleClearSearch} />
                        </div>
                    </div> : <div>
                        <div className='ma-mt10'>
                            <Dropdown value={this.state.questionTarget}
                                className='ma-w200 ma-mt10'
                                options={QuestionTargets}
                                optionLabel="value"
                                optionValue="id"
                                onChange={(e) => this.onQuestionTargetChange(e.value)}
                                placeholder="Question Target" />
                            <span className='ma-required-mark '>*</span>
                            <Dropdown 
                            value={this.state.boardId}
                                className='ma-w400 ma-mr20 ma-mt10'
                                options={this.props.boards}
                                optionLabel="boardName"
                                optionValue="boardId"
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="Select Board"  
                                />
                            {this.state.boardId && <Dropdown value={this.state.classId}
                                options={this.state.classes}
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            }
                            {this.state.groupId && <Dropdown value={this.state.subjectId}
                                options={this.state.subjects}
                                optionLabel='subjectName'
                                optionValue='subjectId'
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                            }

                            {this.state.subjectId && <Dropdown value={this.state.chapterId}
                                options={this.state.chapters}
                                optionLabel='chapterName'
                                optionValue='chapterId'
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />}

                            {this.state.chapterId && <Dropdown value={this.state.topicId}
                                options={this.state.topics}
                                optionLabel='topicName'
                                optionValue='topicId'
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />}

                        </div>
                        <div className='paper-s1 ma-mt20'>
                            <div className='ma-mt20 p-grid ma-no-pm'>
                                <div className='p-col-12 p-md-3 p-lg-3' >
                                    <div className='search-opt'>
                                        <MultiSelect value={this.state.questionType}
                                            className='ma-mr20 ma-w200'
                                            options={QuestionTypesWithAQ}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => this.setState({
                                                questionType: e.value,
                                                filteredContentDetails: [],
                                                questions: [],
                                                totalRecords: 0
                                            })}
                                            placeholder="Question Type" />
                                    </div>       </div>
                                <div className='p-col-12 p-md-3 p-lg-3 '>
                                    <div className='search-opt'>
                                        <MultiSelect
                                            value={this.state.difficulty}
                                            className='ma-w200'
                                            options={Difficulty}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => this.setState({
                                                difficulty: e.value, filteredContentDetails: [],
                                                questions: [],
                                                totalRecords: 0
                                            })}
                                            placeholder="Select Difficulty" />
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3 p-lg-3 '>
                                    <div className='search-opt'>
                                        <MultiSelect
                                            value={this.state.skillType}
                                            className='ma-w200'
                                            options={SkillTypes}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => this.setState({
                                                skillType: e.value, filteredContentDetails: [],
                                                questions: [],
                                                totalRecords: 0
                                            })}
                                            placeholder="Select Skill Type" />
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3 p-lg-3 '>
                                    <div className='search-opt'>
                                        <MultiSelect
                                            value={this.state.status}
                                            className='ma-w200'
                                            options={Status}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => this.setState({
                                                status: e.value, filteredContentDetails: [],
                                                questions: [],
                                                totalRecords: 0
                                            })}
                                            placeholder="Select Status" />
                                    </div>
                                </div>
                            </div>
                            <Toolbar className="ma-toolbard" right={() => {
                                return (
                                    <React.Fragment>
                                        <Button label='Submit' onClick={this.onFetch} />
                                    </React.Fragment>
                                )
                            }}></Toolbar>
                        </div>
                    </div>
                }


                <div className="card datatable-crud-demo fetch-q-tabled ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable scrollHeight="500px"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={this.state.questions}
                        filters={this.state.lazyParams.filters}
                        // loading={this.props.isLoading}

                        className="ma-table-d"
                        tableClassName='ma-table-tbl'
                        selectionPageOnly
                        paginatorLeft={this.questionsAvailableR}


                        paginator
                        lazy
                        onPage={this.onPage}
                        onSort={this.onSort}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        sortField={this.state.lazyParams.sortField}
                        sortOrder={this.state.lazyParams.sortOrder}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        selectionMode="checkbox"
                        // selection={this.state.selectedQuestions}
                        // onSelectionChange={this.onSelectQuestions}

                        //paginatorTemplate={paginatorTemplate}
                        //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        // globalFilter={this.state.globalFilter}
                        header={header}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                        <Column headerStyle={{ width: '4em' }} field='_id' header={<Checkbox inputId={'item.id'}
                            checked={this.isSelectedAllItems(this.state.questions, this.state.selectedQuestions, '_id')}
                            onChange={(e) => { this.selectOrdeselect('list', this.state.selectedQuestions, this.state.questions, e.checked, 'selectedQuestions', '_id') }}
                        ></Checkbox>}
                            body={(rowData) => {
                                return <Checkbox inputId={'item.id'}
                                    checked={this.isSelected(rowData, this.state.selectedQuestions, '_id')}
                                    onChange={(e) => { this.selectOrdeselect('single', this.state.selectedQuestions, rowData, e.checked, 'selectedQuestions', '_id') }}
                                ></Checkbox>
                            }}>
                        </Column>
                        <Column headerClassName='question-id' field="questionId" header="Question ID" />
                        <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                            return (<>
                                <QuestionInTable question={rowData} />
                            </>)
                        }} />
                        {
                            this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="questionType" header="Question Type" />
                        }
                        {
                            this.isColumnVisible('videoLinking') && <Column headerClassName='difficulty' field="videoLinking" header="Video Linked"
                                body={(rowData) => {
                                    return (<>
                                        <InputSwitch
                                            checked={(rowData.videoLinking && rowData.videoLinking.length) ? true : false} />

                                    </>)
                                }}

                            />
                        }
                        {
                            this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="difficulty" header="Difficulty"
                            />}
                        {
                            this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="skillType" header="Skill Type"
                            />}
                        {
                            this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="examType" header="Exam Type"
                                body={(rowData) => {
                                    return (<>
                                        {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                                    </>)
                                }}
                            />}
                        {
                            this.isColumnVisible('source') && <Column headerClassName='difficulty' field="source" header="Source"
                            />}
                        {
                            this.isColumnVisible('status') && <Column headerClassName='difficulty' field="status" header="Status"
                            />}
                        <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                            return (<>
                                <Authorizer permId={PERMISSIONS.EXAM_EDIT_QUESTION} >
                                    <span data-pr-tooltip="Edit Question" data-pr-position="bottom" className={`edit${rowData._id} ma-mr20`} onClick={() => { this.props.onEditQuestion(rowData) }}>
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        {/* <i className="pi pi-pencil"></i> */}
                                        <EditIcon height={13} width={13} />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.EXAM_PREVIEW_QUESTION} >
                                    <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id}`}
                                        onClick={() => {
                                            this.setState({
                                                isShowQuestionPreview: true,
                                                questionToPreview: rowData
                                            })
                                        }}>
                                        {/* <i className='pi pi-eye ma-mr10' /> */}
                                        <PreviewIcon height={13} width={13} />
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        {/* <i className="pi pi-folder-open"></i>  */}
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />
                                </Authorizer>
                                {/* <Authorizer permId={PERMISSIONS.EXAM_PREVIEW_QUESTION} > */}
                                {
                                    rowData.questionTarget == 'General' && <Button
                                        label="Move"
                                        disabled={!rowData.topicId}
                                        className={`ma-ml20 ma-mt5 ${!rowData.topicId ? 'p-button-secondary' : ''}`}
                                        onClick={() => {
                                            this.setState({
                                                warningDialog: {
                                                    visible: true,
                                                    headerMsg: 'Are you Sure?',
                                                    message: `You are moving question to normal type and linking to curriculum`
                                                },
                                                questionToMove: rowData
                                            });
                                        }} />
                                }

                                {/* </Authorizer> */}
                            </>
                            );
                        }} ></Column>
                        {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                    </DataTable>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>


                {
                    this.state.isShowQuestionPreview && <PreviewQuestion
                        question={this.state.questionToPreview}
                        onHide={() => {
                            this.setState({
                                isShowQuestionPreview: false
                            })
                        }}
                    />
                }


                {this.state.showAssignPopup && <AssignQuestionToTopic
                    selectedQuestions={this.state.selectedQuestions}
                    onCloseDialog={() => { this.setState({ showAssignPopup: false }) }}
                    onSucessfullyAssigned={this.onSucessfullyAssigned} />}
                <Authorizer permId={PERMISSIONS.EXAM_QUESTION_ASSIGN} >
                    {
                       (this.state.questions && this.state.questions.length > 0) && <Button label='Assign to Curriculums' className='ma-mt20' onClick={this.showAssignPopup} disabled={this.state.disableAssign } />}

                </Authorizer>
                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }

                <Dialog
                    header={<></>}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    blockScroll={true}
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '40vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, questionToMove: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, questionToMove: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                    <br />
                    {questionToMove && <>
                        <p className='ma-alert-msg'>{questionToMove.boardName} {'>'} {questionToMove.className} {'>'}
                            {questionToMove.subjectName} {'>'}
                            {questionToMove.chapterName} {'>'}
                            {questionToMove.topicName}
                        </p>
                    </>}
                </Dialog>
            </div >
        )
    }
}







const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData,
    getImageUrl

})(Authentication(withRouter(FetchQuestions)));