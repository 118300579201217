import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _, { flatMap } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { TabPanel, TabView } from 'primereact/tabview';
// import CreateCourse from './createCourse';
import { Toast } from 'primereact/toast';
import moment from 'moment/moment';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
// import SearchBar from './SearchBar';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
// import {getBoardsData} from './../../../store/actions';
import { getBoardsData } from './../../store/actions';
import Authentication from './../session';
//  import ShowAssessmentsData from './showAssessmentsData';
import LoadingComponent from '../loadingComponent';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Navbar from '../navbar';
import ShowAssessmentsData from '../teachProffTraining/courseManagement/showAssessmentsData';
import CreateCourse from '../teachProffTraining/courseManagement/createCourse';
import SvgViewer from '../customComponents/svgViewer';
import { DeleteIcon, WarningIcon } from '../svgIcons';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import SearchBar from '../teachProffTraining/courseManagement/SearchBar';
import './styles.scss'
import { InputSwitch } from 'primereact/inputswitch';

const items = [
    { label: 'Analytics' },
    { label: 'Teachers Proffesional Training' },
    { label: 'Trainer View', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


export class TeachersAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseType: "",
            fromDate: "",
            toDate: "",
            courseTypeErr: false,
            fromDateErr: false,
            toDateErr: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedTab: 1,
            courseManagement: true,
            createCourse: false,
            mandatoryList: [],
            choiceBasedList: [],
            currentPage:0,
            itemsPerPage:5,
            type: "mandatory",
            totalRecords: null,
            editCourse: {},
            warningDialog: warningDailogInit,
            deleteCourseId: null,
            selectedTeachers: [],
            attendenceDate:new Date(),
            selectedCoursesObjects: [],
            showAssignUsers: false,
         
            filterData: null,
            users: [],
            userstotalRecords: '',
           
            showSearch: "",
            globalSearchUsers: "",
            selectedUser: [],
            assessmentData: {},
           
            TotalteachersListForAttendence: [],
            absentTeachers:[],
            showAssessmentsData: false,
            assessmentType: "",
            teacherAttendance: true,
            selectAll: true
        }

        this.service = new Service()
    }

    componentDidMount() {

        this.onClickFetchTeachers()
        // if (!this.props?.boards || !this.props?.boards?.length) {
        //     this.props.getBoardsData();
        // }
    }

    onClickFetchTeachers = () => {
        this.setState({isLoading : true})
        let startDate = new Date(this.state.attendenceDate);
        let timezoneOffset = startDate.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
       let dateForAttendence = startDate.toISOString()

        let lazyParams = this.state.lazyParams
        
        const Url = `${baseUrlAdmin}/trainer/teachers`;
        const payload = {
            "courseId": this.props.batchData.courseId,
            "batchId": this.props.batchData?._id,
            "date":dateForAttendence
        }
        this.service.post(Url, payload, true).then((data) => {
            if (data && data.res) {
                
                const formatData = data?.res?.data.map(each => {
                    return { ...each?.user_info,user_info:each?.user_info, attendance:each?.attendance, user_id: each?.user_id, account_id: each?.account_id }
                })


                const absentData = data?.res?.data.filter(each => {
                    if(each?.attendance === "absent"){
                        return each
                    }
                })

             

                
       

                this.setState({
                    isLoading : false,
                    TotalteachersListForAttendence: formatData,
                    absentTeachers:absentData,
                    totalRecords: data?.res?.totalRecords
                   
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Trainer Manegement</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onClickReset = () => {
        this.setState({
            "fromDate": "",
            "toDate": ""
        })
        this.onClickFetchCourses()
    }



    changeTab = (num, string) => {
        this.setState({
            selectedTab: num,
            lazyParams: BasicLazyParams,
            type: string
        }, () => this.onClickFetchCourses())
    }

    onClickCreateNewCourse = () => {
        this.setState({
            courseManagement: false,
            createCourse: true
        })
    }

    onBackFromCreate = (a) => {
        // this.setState({
        //     courseManagement : true,
        //     createCourse : false,
        //     editCourse : {}
        // })
        if (a) {
            this.setState({
                courseManagement: true,
                createCourse: false,
                editCourse: {},
                selectedCourses: [],
            }, () => this.onClickFetchCourses())
            this.toast.show({ severity: 'success', summary: `Successfully course ${a}`, detail: '', life: 3000 });
        } else {
            this.setState({
                courseManagement: true,
                createCourse: false,
                editCourse: {},
                selectedCourses: [],
                // fromDate : "",
                // fromDateErr : false,
                // toDate : "",
                // toDateErr : false,
                // mandatoryList : [],
                // choiceBasedList : []
            })
        }
    }
    onBackTeacherAttendance = () => {
        this.props.onBackTeacherAttendance()
    }

    // onClickEditCourse = (rowData) => {
    //     this.setState({
    //         courseManagement : false,
    //         createCourse : true,
    //         editCourse : rowData
    //     })
    // }


    getTheDataPageWise = ()=>{

        
        const indexOfLastItem = (this.state.currentPage + 1) * (this.state.itemsPerPage);
        const indexOfFirstItem = Math.abs(indexOfLastItem - this.state.itemsPerPage);

  // Get the current items to be displayed on the current page
       const currentItems = this.state.TotalteachersListForAttendence.slice(indexOfFirstItem, indexOfLastItem);
       this.setState({teachersListForAttendence:currentItems})


    }

    onDeleteCourse = (rowData) => {
        if (rowData?.courseAssignedCount >= 0) {
            this.setState({
                warningDialog: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    message: `"${rowData?.courseName}" has been assigned to ${rowData?.courseAssignedCount} users.Do you want to delete the ${rowData?.courseName} from the course list and unassign it from the users?`

                },
                deleteCourseId: rowData
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Unable to Delete Assigned Courses', life: 3000 });
        }
    }

    onClickDelete = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/course/${this.state.deleteCourseId?._id}`;
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res?.res?.status) {
                this.setState({ isLoading: false }, () => {
                    this.onClickFetchCourses();
                })
                this.toast.show({ severity: 'success', summary: 'Course deleted successfully', detail: res.res.message, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    // onPage = (event) => {


    //     this.setState({ lazyParams: event }, () => this.onClickFetchTeachers());
    // }
    // onPage = (event) => {

    //     this.setState((prevState) => {

    //         return {
    //             lazyParams: {
    //                 ...prevState.lazyParams,
    //                 ...event
    //             }
    //         };
    //     }, () => this.getTheDataPageWise());
    // }

    onPageChange = (event)=>{
       console.log(event)
        this.setState({
            currentPage:event?.page,
            itemsPerPage:event?.rows
        },() => this.getTheDataPageWise())

    }


    onSelectCourse = e => this.setState({ selectedCourses: e.value })


    selectAll = () => {


    }
    onSelectAllChange = (event) => {

        const selectAll = event.checked;
        if (selectAll) {

        }

    }
    onSelection = (event) => {

    }

    //  onSelectionChange = (event) => {
    //     const value = event.value;

    //     setSelectedCustomers(value);
    //     setSelectAll(value.length === totalRecords);
    // };

    //  onSelectAllChange = (event) => {
    //     const selectAll = event.checked;

    //     if (selectAll) {
    //         CustomerService.getCustomers().then((data) => {
    //             setSelectAll(true);
    //             setSelectedCustomers(data.customers);
    //         });
    //     } else {
    //         setSelectAll(false);
    //         setSelectedCustomers([]);
    //     }
    // };

    onChangeSelectUser = (rowData, e) => {
        if (e.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        } else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }
    }

    onClikAssginUsers = () => {
        if (this.state.selectedCourses.length > 0) {
            this.setState({ showAssignUsers: true })
        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the courses', life: 3000 });
        }
    }

    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.filterUsersData)
    }
    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })
        let { lazyParams, globalSearchUsers, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearchUsers}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    userstotalRecords: res.res.totalRecords
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
        })
    }

    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onSortUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return { globalSearchUsers: e.target.value, }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }
        });
    }

    startGlobalFilter = () => {
        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;
            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }
            }
            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }

    onClickAssignExamsToUsers = () => {
        if (this.state.selectedUser.length) {
            let obj = {}
            obj.curriculum = {}
            obj.assignIds = this.state.selectedUser

            let examsArr = []
            this.state.selectedCoursesObjects.map(e => {
                examsArr.push(e.prerequisite, e.preAssessment, e.postAssessment)
            })
            obj.curriculum.boardId = "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7"
            obj.curriculum.classId = "ccb7388c-dfc8-477d-8919-b202db3f422b"
            obj.curriculum.groupId = "1aeababe-3477-427c-8a74-0819caf05d36"
            obj.curriculum.exams = examsArr

            let courseArr = []
            this.state.selectedCoursesObjects.map(e => {
                courseArr.push({ id: e._id, name: e.courseName, assigned: e.type === "mandatory" ? true : false })
            })
            obj.courses = courseArr

            obj.assignTo = "user"
            obj.examType = "Assessment"

            let url = `${baseUrlAdmin}/examination/assigntosection`
            this.setState({ isLoading: true })
            this.service.post(url, obj, true).then(res => {
                if (res && res?.status && res?.res?.success) {
                    this.setState({
                        isLoading: false, showAssignUsers: false, users: [], filterData: null,
                        selectedCourses: [], selectedCoursesObjects: [], selectedUser: [],
                    })
                    this.toast.show({ severity: 'success', summary: '', detail: 'Successfully assigned to users', life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }
            }).catch(e => {
            
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
            })

        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the studnets', life: 3000 });
        }
    }
    onClickAssessments = (rowData, assessmentType) => {
        this.setState({
            courseManagement: false,
            showAssessmentsData: true,
            assessmentData: rowData,
            assessmentType: assessmentType
        })
    }

    onBackFromAssessments = () => {
        this.setState({
            courseManagement: true,
            showAssessmentsData: false,
            assessmentData: {},
            assessmentType: '',
            selectedCourses: []
        })
    }

    usersClear = () => {
        this.setState({ users: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }
    isSelected = (rowData, selectedItems, uniqField) => {
        
        return selectedItems.find(a => a[uniqField] === rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts

        })





    }

    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    OnChangeAttendenceDate = (e)=>{
        let startDate = new Date(e.value);
        let timezoneOffset = startDate.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
      

       this.setState({attendenceDate:startDate.toISOString()})
    }

    HandleDateForAttendence = ()=>{
        let today = new Date();
    
    

    let minDate = this.props.batchData?.fromDate;
    minDate = new Date(minDate)
    let maxDate = new Date()


    return (
        <div className="card flex justify-content-center">
            <Calendar dateFormat='dd-mm-yy' style={{width:"180px",fontWeight:"bold"}} value={this.state.attendenceDate} showIcon onChange={(e) => this.OnChangeAttendenceDate(e)} minDate={minDate} maxDate={maxDate} readOnlyInput />
        </div>
    )
    }

    OnMarkAttendence = () => {

       
        const url = `${baseUrlAdmin}/trainer/attendance`;
        //const selectedTeachersUserIds = this.state.selectedTeachers.map(a => a.user_id);
        const usersINfo = this.state.absentTeachers.map(each => {
          
                return {
                    user_id: each?.user_id,
                    account_id: each?.account_id,
                    attendance: each?.attendance

                }  });
         
      
           
      



        const payload = {
            "courseId": this.props.batchData?.courseId,
            "batchId": this.props.batchData?._id,
            "attendanceInfo": [{
                            "date":this.state.attendenceDate,
                            "userInfo":usersINfo
            }]
        }
      if((new Date(this.state?.attendenceDate) <= new Date())&& this.state.teachersListForAttendence?.length !== 0) { 
        this.service.post(url, payload, true).then((res) => {

            if (res?.status || res?.res?.status) {
                this.setState({
                    isLoading: false,
                },()=>  this.onClickFetchTeachers());
              
                this.toast.show({ severity: 'success', summary: "success", detail: res?.res?.messsage, life: 3000 });

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.res?.messsage, life: 3000 });
            }

        }).catch(e => {
            this.setState({ isLoading: false });
            //this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e?.message, life: 3000 });
        })
    }
    else{
        this.state.teachersListForAttendence?.length === 0 ?
        this.toast.show({ severity: 'error', summary: "Empty Data",detail: "Teachers Data Is Empty", life: 3000 })
        :
        this.toast.show({ severity: 'error', summary: "Invalid Date",detail: "Date should be less or equal to Today Date", life: 3000 });
    }
    }

    onclickMarkAttendence = (e,rowData)=>{
       let data= {...rowData}
      
        if (e.value == true) {
            data.attendance = 'present'
        } else {
            data.attendance = 'absent'
        }

        if(data?.attendance === 'absent'){
            //const obsentTeachersIds = this.state.absentTeachers.map(each=>each?.user_id)
            let updatedTeachers = this.state.TotalteachersListForAttendence?.map(teacher=>{
                if((teacher?.user_id === data?.user_id)){
                    return data
                }
                else{
                    return teacher
                }
            });

            this.setState(prevState=>({absentTeachers:[...prevState?.absentTeachers,data],TotalteachersListForAttendence:updatedTeachers}))
        }
        else{

            let updatedTeachers = this.state.TotalteachersListForAttendence?.map(teacher=>{
                if(teacher?.user_id === data?.user_id){
                    return data
                }
                else{
                    return teacher
                }
            });
            let updateAbsentTeachers = this.state.absentTeachers.filter(teacher=>{
                if(teacher?.user_id !== data?.user_id){
                    return teacher
                }
            })

            this.setState(({absentTeachers:updateAbsentTeachers,TotalteachersListForAttendence:updatedTeachers}))

        }
}

      
      

        



    render() {
        
        const header1 = (
            <div className="table-header ma-tbl-header  ">
                <div className="ma-tbl-left">
                    <h2 >Teachers List For <span style={{color:"green",fontWeight:"bold"}}>{(this.props.batchData?.batchName.charAt(0).toUpperCase()+ this.props.batchData?.batchName.slice(1))}</span></h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearchUsers}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    {/* <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearchUsers: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul> */}
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                {this.state.columns.map((column) => {
                                    return <li>{column.columnName}</li>
                                })}
                            </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        return (
            <>
                <div className='p-grid ma-toolbar'>
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    {/* <p className='back-arrow' onClick={this.onBackTeacherAttendance}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                </div>
            <div className='ma-main'>
                <div style={{marginTop:"20px",display:"flex",justifyContent:"space-between"}} >
                     <div className='flex'>
                        <div className='ma-mlr5'>
                            <div>
                                {this.HandleDateForAttendence()}

                            </div>
                            {/* {this.state.fromDateErr && <span className='p-error'>From Date required</span>} */}
                        </div>

                        <div>
                            <Button label='Fetch Teachers' style={{marginTop:"6px"}} className="p-button-success" onClick={() => this.onClickFetchTeachers()} />
                            {/* <Button label='Reset' className="p-button-success ma-mlr5" onClick={() => this.onClickReset()} /> */}
                        </div>
                        <div style={{display:"flex",marginLeft:"50px",marginTop:"5px"}}>
                           <p style={{marginRight:"20px",fontWeight:"400",fontSize:"18px",color:"grey"}}>Batch Start Date: <span style={{fontWeight:"600",fontSize:"20px",color:"black"}}>{moment(this.props.batchData?.fromDate).format('DD-MM-YYYY')}</span></p>
                           <p style={{marginRight:"10px",fontWeight:"400",fontSize:"18px",color:"grey"}}>Batch End Date: <span style={{fontWeight:"600",fontSize:"20px",color:"black"}}>{moment(this.props.batchData?.toDate).format('DD-MM-YYYY')}</span></p>
                        </div>
                    </div>    
                    <div style={{marginTop:"-16px",display:"flex",justifyContent:"end"}}>
                    <Button style={{width:"70px",height:"30px",marginTop:"20px"}} type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBackTeacherAttendance}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
               
                    </div>
                </div>
                    <div className='ma-mt30 '>
                            <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={this.state.TotalteachersListForAttendence}
                            dataKey="_id"
                        
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Boards"
                            globalFilter={this.state.globalFilter2}
                            header={header1}
                            
                            
                            paginatorLeft={() => {
                                return <>
                                    <div className="flex selected-users">
                                        {/* <div className='avail-q ma-mr20'>Selected Users:  <span>{this.state.selectedCourses && this.state.selectedCourses.length}</span> </div> */}
                                        <div className='avail-q '>Available  Teachers:  <span>{this.state.totalRecords || 0}</span> </div>
                                    </div>
                                </>
                            }}

                            // paginatorRight={() => {
                            //     return <>
                            //         <Button className="p-button-success" disabled={!(this.props.batchData?.fromDate <= this.state.attendenceDate <= this.props.batchData?.toDate)}  onClick={this.OnMarkAttendence}>Save Attendance</Button>
                            //     </>
                            // }}
                        >

                            {/* <Column headerStyle={{ width: '4em' }} field='groupId' header={
                                <Checkbox inputId={'item.id'}
                                    checked={this.isSelectedAllItems(this.state.teachersList, this.state.selectedTeachers, 'groupId')}
                                    onChange={(e) => { this.selectOrdeselect('list', this.state.selectedTeachers, this.state.teachersList, e.checked, 'selectedTeachers', 'groupId') }}
                                ></Checkbox>
                            }
                                body={(rowData) => {
                                    return <Checkbox inputId={'item.id'}
                                        checked={this.isSelected(rowData, this.state.selectedTeachers, 'groupId')}
                                        onChange={(e) => { this.selectOrdeselect('single', this.state.selectedTeachers, rowData, e.checked, 'selectedTeachers', 'groupId') }}
                                    ></Checkbox>
                                }}>
                            </Column> */}
                             {/* <Column headerStyle={{ width: '4em' }} field='user_id' header={"Select"}
                                    
                                         body={(rowData) => {
                                    return <Checkbox inputId={'item.id'}
                                        checked={this.isSelected(rowData, this.state.selectedTeachers, 'user_id')}
                                        onChange={(e) => { this.selectOrdeselect('single', this.state.selectedTeachers, rowData, e.checked, 'selectedTeachers', 'user_id') }}
                                    ></Checkbox>
                                }}>
                                </Column> */}

                            <Column headerStyle={{width:"120px"}} field="slNo" header="Sr. No" body={(rowData, rowIndex) => {
                                        return <span style={{textAlign:"center"}}>
                                            {(this.state.currentPage * this.state.itemsPerPage) + rowIndex.rowIndex + 1}
                                        </span>
                            }} />
                            <Column field="username" header="User Name " />
                            <Column field="given_name" header="Given Name " />
                            <Column headerStyle={{textAlign:"center"}} field="email" header="Email Id" body={(rowData) => {
                                    return <>
                                       <p style={{textAlign:"center"}}> {rowData?.email}</p>
                                    </>
                                }} />

                           <Column headerStyle={{textAlign:"center"}} field="phone_number" header="Phone" body={(rowData) => {
                                    return <>
                                       <p style={{textAlign:"center"}}> {rowData?.phone_number}</p>
                                    </>
                                }} />
                            <Column headerStyle={{textAlign:"center"}} field="distname" header="District" body={(rowData) => {
                                    return <>
                                       <p style={{marginLeft:"-10px",textAlign:"center"}}> {rowData?.distname}</p>
                                    </>
                                }} /> 
                            <Column field="attendance" header="Attendance" body={(rowData) => {
                                    return <>
                                    
                                            <InputSwitch
                                                checked={rowData?.attendance === "absent"?false:true}
                                                tooltip={rowData?.attendance === "absent"?"Absent":"Present"}
                                                // disabled={!((new Date(this.props.batchData?.fromDate) <= new Date(this.state.attendenceDate)) && (new Date(this.state.attendenceDate) < new Date(this.props.batchData?.toDate)))}
                                                onChange={(e) => {
                                                    this.onclickMarkAttendence(e, rowData)
                                                }}
                                            />
                                      
                                    </>
                                }} />

                        </DataTable>
                        <Authorizer permId={PERMISSIONS.TRAINER_USERS_ATTENDENCE}>
                            <div style={{display:"flex",justifyContent:"end",marginTop:"15px"}}>
                            <Button className="p-button-success"   onClick={this.OnMarkAttendence}>Save Attendance</Button>
                            {/* disabled={(new Date(this.props.batchData?.fromDate) <= new Date(this.state.attendenceDate) && new Date(this.state.attendenceDate) <= new Date(this.props.batchData?.toDate))} */}
                            </div>
                        </Authorizer>

                    </div>
                </div>



                {this.state.isLoading && <><LoadingComponent /></>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>


        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});
export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(TeachersAttendance))) 