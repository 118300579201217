import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SvgViewer from './../../customComponents/svgViewer';
import Authentication from './../../session';
import _ from 'lodash';
import Service from '../../../services';
import { MultiSelect } from 'primereact/multiselect';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import Navbar from './../../navbar/index';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import cloneDeep from 'lodash/cloneDeep';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import {
    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    selectClass,
    selectGroup,
    clearSelectedOrganizationData,
    updateSubject,
    getSubject,
    dispatchClearCurriculumData,
    startExportToOffline,
    getImageUrl,
    getBoardsData,
    getBranchesLatest
} from './../../../store/actions';
import { Checkbox } from 'primereact/checkbox';

import LoadingComponent from '../../loadingComponent';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import './styles.scss'
import SearchBar from './searchBar';
import { BasicLazyParams } from '../../../utile';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';

const items = [
    { label: 'Academics' },
    { label: 'Assign Content', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class AssignContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId:this.props.boards[0]?.boardId,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            curriculumInfo: {},
            isShowSubjectCreate: false,
            editSubjectData: null,
            isSubjectAdd: true,
            showSubjects: false,
            isShowChapters: false,
            isShowTopics: false,
            editChapterData: null,
            isShowExportToOffline: false,
            isSectionAssign: false,
            filterData: null,
            users: [],
            subjects: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedContent: []

        }
        this.service = new Service();
    }



    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }


        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
       this.onChangeBoard(this.props.boards[0].boardId)

    }

    exportCSV = () => {
        this.dtSections.exportCSV();
    }



    exportUsersCSV = () => {
        this.dt.exportCSV();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });



                this.setState({
                    boards: boards
                });
            }
        }

        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }

        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }

    handleSelect = (subject) => {
        let subjects = this.state.subjects.map((s) => {
            if (s.subjectId == subject.subjectId) {
                s.isSelected = s.isSelected ? false : true;
            }
            return s;
        });

        this.setState({
            subjects
        });
    }


    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({ classes: [], groups: [], classId: null, groupId: null, subjects: [] });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }

        this.setState({
            classes: _classes,
            boardId,

        });

    };
    

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value,
                subjects: []
            }, () => {
                this.getActiveSubjects();
            });
        }
    };


    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }


    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }



    // onChangeGroup = (groupId) => {
    //     this.setState(
    //         {
    //             groupId,
    //         },
    //         () => {
    //             this.getCurriculumData();
    //         }
    //     );
    // };
    // grades selection --- end


    getActiveSubjects = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subjects/active`;
            this.service.get(url, false).then((data) => {
                if (data && data.res && data.res.subjects) {
                    let subjects = [];
                    if (data.res.subjects.length) {
                        subjects = data.res.subjects.map((s) => {
                            s.isSelected = false;
                            return s
                        });
                    } else {

                        this.toast.show({ severity: 'error', summary: 'No Subjcts', detail: 'No active subjects in this board to assign', life: 3000 });
                    }
                    this.setState({
                        subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            // this.snackBarOpen('select board, class and group id');
        }
    };

    //subjects --- start

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })

        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                if (users1 && users1.length) {
                    this.setState({
                        isLoading: false,
                        users: users1,
                        totalRecords: res.res.totalRecords
                    });
                } else {
                    this.setState({
                        isLoading: false,

                    });
                    this.toast.show({ severity: 'error', summary: 'No Users', detail: 'No users found with the search criteria', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.filterUsersData);
    }
    onGlobalSearch = (e) => {

        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
            }
        }, () => {

            this.startGlobalFilter()
        });
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }

    toggleAssignType = () => {
        this.setState({
            isSectionAssign: !this.state.isSectionAssign
        })
    }

    assignSubjects = (toSection, rowData) => {

        const { boardId, classId, groupId, subjects } = this.state;
        let selectedSubjects = subjects.filter((s) => s.isSelected).map(s => s.subjectId);

        if (selectedSubjects.length > 0) {


            let data;
            if (toSection) {

                if (!rowData.length) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select atleast one section', life: 3000 });
                    return
                }


                data = {
                    curriculum: {
                        boardId, classId, groupId,
                        subjects: selectedSubjects,
                    },
                    assignIds: rowData,
                    assignTo: 'section'
                }
            } else {
                if (!rowData) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select atleast one user', life: 3000 });
                    return
                }
                data = {
                    curriculum: {
                        boardId, classId, groupId,
                        subjects: selectedSubjects,
                    },
                    assignUser: rowData,
                    assignTo: 'user'
                }
            }
            this.setState({
                isLoading: true
            });

            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/assigntosection`;
            this.service.post(url, data, true).then((data) => {
                if (data && data.res && data.res.success) {
                    this.setState({
                        isLoading: false,
                        sectionIds:[],
                        selectedUser: []
                    });

                    this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Content assigned successfully.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unkown error', life: 3000 });
                }

            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });
        } else {

            this.toast.show({ severity: 'error', summary: 'Subjects Required', detail: 'Please select atleast one subject to assign', life: 3000 });

        }

    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Assign Content</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    exportDialogFooter = () => {
        return (<div>
            <Button label="Proceed" className='ma-m-lr10' onClick={this.startExportToOffline} />
            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowExportToOffline: false }) }} />
        </div>)
    }

    onSelectContent = (e) => {
        this.setState({ selectedContent: e.value })
    }



    render() {
        // console.log(this.props.branches)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >{this.props.branchLevels && this.props.branchLevels.length && this.props.branchLevels[this.props.branchLevels.length - 1].levelName} List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        const header2 = (
            <div className="table-header ma-tbl-header ">
                <div className="ma-tbl-left">
                    <h2 >Users List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <div className='assign-content' >
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SHOW_BOARDS} >
                            <Dropdown 
                            // value={}
                             value={this.state.boardId}
                                className='ma-w400'
                                options={this.state.boards}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="Select Board" 
                                />
                            <span className='ma-required-mark '>*</span>
                            <Dropdown value={this.state.classId}
                                options={this.state.classes}
                                className='ma-w200'
                                onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            <span className='ma-required-mark '>*</span>
                            {/* <Button label="Fetch Subjects" className='ma-ml10' onClick={this.startExportToOffline} /> */}
                            {/* <Dropdown value={this.state.groupId}
                            options={this.state.groups}
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeGroup(e.value)} placeholder="Select Group" /> */}
                        </Authorizer>

                        <div className='ma-clearFix'></div>
                        {this.state.subjects && this.state.subjects.length ? <>
                            <p className='selct-s ma-mt30'>Select Subjects</p>


                            {this.state.subjects.map((subject) => {
                                return <>
                                    <Checkbox
                                        checked={subject.isSelected}
                                        inputId={subject.subjectId} onChange={() => this.handleSelect(subject)}></Checkbox>
                                    <label htmlFor={subject.subjectId} className="p-checkbox-label ma-mr25">{subject.subjectName}</label>
                                </>
                            })}

                        </> : null}
                        {/* <Checkbox inputId="cb1" onChange={() => { }} ></Checkbox>
                    <label htmlFor="cb1" className="p-checkbox-label">{'Physics'}</label>
                    <Checkbox inputId="cb1" onChange={() => { }} ></Checkbox>
                    <label htmlFor="cb1" className="p-checkbox-label">{'Physics'}</label> */}
                        <div className='ma-clearFix'></div>
                        {(this.state.subjects && this.state.subjects.length) ? <>

                            <div className='ma-mt20'>
                                <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_TO_SECTION} >
                                    {/* <Button label="Assign to Sections" onClick={() => {
                                        this.setState({
                                            isSectionAssign: true
                                        })
                                    }} className={`${this.state.isSectionAssign === true ? '' : 'p-button-outlined'}`} /> */}
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_TO_USERS} >
                                    {/* <Button label="Assign to Users" onClick={() => {
                                        this.setState({
                                            isSectionAssign: false
                                        })
                                    }} className={`${this.state.isSectionAssign === false ? '' : 'p-button-outlined'} ma-ml15`} /> */}
                                </Authorizer>
                            </div>
                            {
                                this.state.isSectionAssign == true ? <>
                                    <div className='ma-mt20'>
                                        {/* <Dropdown value={this.state.classId}
                            options={this.state.classes}
                            className='ma-w200'
                            //onChange={(e) => this.onChangeClass(e.value)} 
                            placeholder="Select Section"
                        /> */}

                                    </div>
                                    <div>


                                        {
                                            this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                                return (!(this.props.branchLevels.length == index + 1) ? <> <Dropdown
                                                    className={`ma-mt10 ma-w200`}
                                                    value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                    // className='ma-w100p'
                                                    //    disabled={this.state.hideUpdateButton}
                                                    optionLabel={"name"}
                                                    optionValue={"key"}
                                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                                    onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                                    placeholder={`--Select ${level.levelName}--`} />
                                                    <span className='ma-required-mark '>*</span></> : null
                                                    //      <MultiSelect
                                                    //     className='ma-m-lr10 ma-w200'
                                                    //     value={this.state.sectionIds ? this.state.sectionIds : []}
                                                    //     // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                    //     onChange={(e) => this.onMultiChange(e.value)}
                                                    //     options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                                    //     optionLabel={"name"}
                                                    //     optionValue={"key"}
                                                    //     placeholder={`--Select ${level.levelName}--`}

                                                    // // onChange={(e) => setCities(e.value)} 
                                                    // />
                                                )
                                            }) : null
                                        }

                                        <div className="card datatable-crud-demo ma-mt10 ">
                                            <DataTable ref={(el) => this.dtSections = el}
                                                //  lazy
                                                //  value={this.state.roles}
                                                // value={this.props.branches}
                                                value={(this.state.sectionsData && this.state.sectionsData['level' + (this.props.branchLevels.length)]) ? this.state.sectionsData['level' + (this.props.branchLevels.length)] : []}
                                                // selection={this.state.selectedProducts}
                                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                                dataKey="key"
                                                paginator
                                                rows={10}
                                                responsiveLayout="scroll"
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                globalFilter={this.state.globalFilter}

                                                selection={this.state.selectedContent}
                                                onSelectionChange={this.onSelectContent}

                                                selectionMode="checkbox"
                                                header={header}>
                                                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                                                <Column sortable field="name" header={this.props.branchLevels && this.props.branchLevels.length && this.props.branchLevels[this.props.branchLevels.length - 1].levelName} />
                                                {/* <Column sortable body={(rowData) => {
                                    return (
                                        <div className="ma-status">
                                            <span className="ma-status-text">Active </span>  <InputSwitch
                                                onChange={(e) => { this.changeStatus(e, rowData) }}
                                                checked={rowData.status == 'Active'} />
                                        </div>
                                    )
                                }} field="status" header="Role Status" /> */}
                                                {/* <Column field="chapterLongDescription" header="Chapter Long Description" /> */}
                                                {/* <Column field="isChapterActive" header="Status" body={this.statusTemplate} /> */}
                                                <Column body={(rowData) => {
                                                    return (<>
                                                        <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_SUBJECTS} >
                                                            <Button label="Assign Subjects" onClick={() => this.assignSubjects(true, [rowData.key])} />
                                                        </Authorizer>
                                                    </>
                                                    )
                                                }}
                                                    header="Actions"></Column>
                                                {/* <Column body={this.showChaptersTemplate}></Column> */}
                                            </DataTable>
                                        </div>
                                        <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_SUBJECTS} >
                                            <Button label="Assign Subjects" className='ma-mt20' onClick={() => this.assignSubjects(true, this.state.selectedContent.map((i) => i.key))} />
                                        </Authorizer>
                                    </div>
                                </> : this.state.isSectionAssign == false ? <>
                                    <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} />
                                    <div className="card datatable-crud-demo ma-mt10">
                                        <DataTable ref={(el) => this.dt = el}
                                            lazy
                                            value={this.state.users}
                                            dataKey="id"
                                            paginator
                                            onPage={this.onPage}
                                            first={this.state.lazyParams.first}
                                            last={this.state.totalRecords}
                                            rows={this.state.lazyParams.rows}
                                            totalRecords={this.state.totalRecords}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            globalFilter={this.state.globalFilter2}
                                            header={header2}>

                                            <Column sortable filterField="given_name" field="given_name" header="Given Name" />
                                            <Column sortable filterField="phone_number" field="phone_number" header="Phone" />
                                            <Column sortable filterField="email" field="email" header="Email ID" />
                                            <Column sortable filterField="custom:board" field="custom:board" header="Board" />
                                            <Column sortable filterField="custom:grade" field="custom:grade" header="Grade" />
                                            <Column header="Actions" body={(rowData) => {
                                                return (<>
                                                    <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_SUBJECTS} >
                                                        <Button label="Assign Subjects" onClick={() => this.assignSubjects(false, rowData)} />
                                                    </Authorizer>
                                                </>
                                                );
                                            }} ></Column>
                                        </DataTable>
                                    </div>
                                </> : <></>
                            }




                        </> : null}



                    </div>



                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    <Dialog header="Export to Offline"
                        closeOnEscape={true}
                        closable={false}
                        blockScroll={true}
                        dismissableMask={true}
                        draggable={false}
                        visible={this.state.isShowExportToOffline} style={{ width: '20vw' }} footer={this.exportDialogFooter} onHide={() => this.setState({ isShowExportToOffline: false })}>
                        <p>Would you like to export to offline this grade. It will take some time to export to offline. once exported, you will get a download button.</p>
                    </Dialog>

                    {
                        this.props.isLoading || this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }
                </Navbar>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    subjectData:
        state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.subjects &&
        state.curriculumData.response.Item.subjects,
    offlineExportStatus: state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.offlineExportStatus &&
        state.curriculumData.response.Item.offlineExportStatus,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    updateSubjectResponse: state.updateSubject && state.updateSubject.response && state.updateSubject.response,
    subjectsUpdateError: state.updateSubject && state.updateSubject.error && state.updateSubject.error,
    updateSubjectLoading: state.updateSubject && state.updateSubject.loading && state.updateSubject.loading,
    getAllImagesUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    // getCurriculum,
    // getActiveOrganizationData,
    // selectBoard,
    // dispatchClearCurriculumData,
    // selectClass,
    // selectGroup,
    // updateSubject,
    // getSubject,
    // clearSelectedOrganizationData,
    // startExportToOffline,
    getImageUrl,
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(AssignContent)));

//Authentication