import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toolbar } from 'primereact/toolbar';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
// import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';
// import { getFormFields, isFieldValid, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
// import FileUpload from '../../fileUpload';
import { Dialog } from 'primereact/dialog';
// import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
// import {getBoardsData} from './../../../store/actions';
import { getBoardsData } from '../../store/actions';
// import Authentication from './../../session';
import Authentication from './../session'
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
// import { baseUrlAdmin } from '../../../store/apiConstants';
// import Service from '../../../services';
import _, { flatMap } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
// import { BasicLazyParams, getContentSource } from '../../../utile';
import { Badge } from 'primereact/badge';
// import { TestTypes } from '../../examination/constants';
import { MultiSelect } from 'primereact/multiselect';
import FileUpload from '../fileUpload';
import LoadingComponent from '../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { BasicLazyParams } from '../../utile';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { getFormFields, isFormValid, onTextChange } from '../../utile/formHelper';
const items = [
    { label: 'Analytics' },
    { label: 'Teachers Proffesional Training' },
    { label: 'Create New Venue', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


var createVenueFieldsJson = require('./createVenueFields.json')



const courseTypeOptions = [
    { label: "Mandatory", value: "mandatory" },
    { label: "Choice Based", value: "choiceBased" }
]

const modeOptions = [
    { label: "Offline", value: "offline" },
    { label: "Self Placed", value: "selfPlaced" }
]

const cardImageOptions = [
    { label: "Standard", value: "standard" }
]

const courseImageOptions = [
    { label: "Standard", value: "standard" }
]

const languages = [
    { label: 'English', value: "English" },
    { label: 'Hindi', value: "Hindi" },
    { label: 'Telugu', value: "Telugu" },
    { label: 'Urdhu', value: "Urdhu" },
]

const certificateOptions = [
    { label: "Certificate format 1", value: "theme1" },
    { label: "Certificate format 2", value: "theme2" },
    // {label : "Certificate format 3",value : "theme3"},
]

const districtOptions = [
    { label: 'District 1', value: 'district1'},
    { label: 'District 2',value: 'district2' },
    { label: 'District 3' ,value: 'district3'},
];

class CreateVenue extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createVenueFieldsJson)
       
        this.state = {
            createVenueFields:this.props.editVenue?.venueName ? this.props.editVenue :  this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            showResource: false,
            globalFilter: '',
            lazyParams: BasicLazyParams,
            showSearch: false,
            globalSearch: "",
            mandalOptions:[{}],
            districtOptions:[{}],
            selectedDistrict:[],
            selectedMandal:[]
        }
        this.service = new Service()
    }

    componentDidMount() {
        this.fetchDistrictOptions()
        this.fetchMandalOptions()    
    }

    fetchDistrictOptions=()=>{
        const url = `${baseUrlAdmin}/venue/districts`;
        this.service.get(url,true).then(res => {
             if (res?.status && res?.res?.data) {
                // console.log(res?.res?.data,"ssssres")
                 const ddistrictOptions = res?.res?.data 
                // console.log(ddistrictOptions,"ddd")
                this.setState({districtOptions:ddistrictOptions});
                        } else {
                          console.error('Error fetching District options:', res?.message);
                        }
                      })
                    .catch((error) => {
                        console.error('Error fetching District options:', error);
                      });
        }
        fetchMandalOptions = () => {  
            const url = `${baseUrlAdmin}/venue/mandals`;
                this.service.get(url,true)
                  .then((res) => {
                    // console.log(res,"mandalresponse")
                    if (res?.status && res?.res.data) {
                      const Options = res?.res?.data
              
                      this.setState({ mandalOptions:Options });
                    } else {
                      console.error('Error fetching Mandal options:', res?.message);
                    }
                  })
                  .catch((error) => {
                    console.error('Error fetching Mandal options:', error);
                  });
              }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Create New Venue</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onBackFromCreate = () => {
        this.props.onBackFromCreate()
    }


    onClickCreateVenue = () => {
        
        let body = this.state.createVenueFields
        let isCompleteFormValid = true;

        const formStatus = isFormValid(createVenueFieldsJson, this.formFields.formValidations, body);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            
            if(this.props.editVenue?.venueName){
                this.setState({isLoading : true})
                const url = `${baseUrlAdmin}/venue/update`;
                this.service.post(url, body, true).then((res) => {
                    console.log(res,"updateresponse")
                    if(res?.status && res?.res?.status){
                        this.setState({
                            isLoading : false,
                        },() => this.props.onBackFromCreate("Updated"))
                    }else{
                        this.setState({isLoading : false})
                         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({isLoading: false});
                     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            }
            else{
            this.setState({ isLoading: true })
            const url = `${baseUrlAdmin}/venue/create`;
            this.service.post(url, body, true).then((res) => {
                console.log(res,"createresponse")
                if (res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                    }, () => this.props.onBackFromCreate("created"))
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }
    }
        
    }
    render() {
        const { formValidations, createCourseFields, createVenueFields } = this.state
        
        return (
            <>
                <div className='p-grid ma-toolbar'>
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    {/* <p className='back-arrow' onClick={this.onBackFromCreate}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                </div>
                <div className='ma-main'>
                   
                    <div style={{display:"flex",justifyContent:'space-between',alignItems:"center"}}>
                      <h2 className='ma-active-item'>{this.props.editVenue.venueName ? "Edit" : "Create New"} Venue</h2>
                      <Button style={{width:"70px",height:"30px"}}  type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBackFromCreate}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
                    </div>
                    <div className='p-card ma-p20'>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-2 ma-mr10">
                                <p className='ma-label-s1'>Venue Name<span className='ma-required'>*</span></p>
                                <InputText className='p-inputtext-style1 ma-w100p md:w-22rem'
                                    value={createVenueFields.venueName}
                                    onChange={(e) => onTextChange(e.target.value, 'venueName', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['venueName'].isValid && <p className="p-error">{formValidations.fields['venueName'].errorMsg}</p>}

                            </div>
                            <div className="p-col-12 p-md-2 ma-mr10">
                                <p className='ma-label-s1'>District<span className='ma-required'>*</span></p>
                                <Dropdown  className='ma-w100p md:w-22rem'
                                    value={createVenueFields.district}
                                    options={this.state.districtOptions}
                                    optionLabel="district"
                                    optionValue="district"
                                    placeholder='Select District'
                                    onChange={(e) => onTextChange(e.target.value, 'district', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['district'].isValid && <p className="p-error">{formValidations.fields['district'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-2 ma-mr10">
                            <p className='ma-label-s1'>Mandal<span className='ma-required'>*</span></p>
                                        <Dropdown  className='ma-w100p md:w-22rem'
                                            value={createVenueFields.mandal}
                                            options={this.state.mandalOptions}
                                            onChange={(e) => onTextChange(e.target.value, 'mandal', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                            optionLabel="mandal"
                                            optionValue="mandal"
                                            placeholder="Select Mandal"
                                        />
                                {formValidations && !formValidations.fields['mandal'].isValid && <p className="p-error">{formValidations.fields['mandal'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-4 ma-mr10">
                                <p className='ma-label-s1 '>Complete Address</p>
                                <InputText  className='ma-w90p md:w-22rem p-inputtext-style1'
                                    value={createVenueFields.address}
                                    onChange={(e) => onTextChange(e.target.value, 'address', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}

                            </div>

                        </div>
                        <div className='p-grid flex ma-mt10'>
                            <div className="p-col-12 p-md-4">
                                  
                                     <p className='ma-label-s1'>Google Maps Location<span className='ma-required'>*</span></p>
                                        <InputText
                                            className='ma-w90p md:w-22rem p-inputtext-style1'
                                            value={createVenueFields.location}
                                            onChange={(e) => onTextChange(e.target.value, 'location', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                        />
                                        {formValidations && !formValidations.fields['location'].isValid && <p className="p-error">{formValidations.fields['location'].errorMsg}</p>}
                             </div>  
                             {/* <div style={{marginTop:"26px",marginLeft:"-30px"}} className="p-col-12 p-md-2">
                                   <Button className='ma-ml10' label = "Paste Location" type="button"/>
                                     
                             </div>       */}
                               
                           
                        </div>
                        <div className='p-grid mt-6 ma-mt10'>
                            <div className="p-col-12 p-md-2">
                                <p className='ma-label-s1'>Venue ID<span className='ma-required'>*</span></p>
                                <InputText className='p-inputtext-style1'
                                    value={createVenueFields.venueId}
                                    onChange={(e) => onTextChange(e.target.value, 'venueId', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['venueId'].isValid && <p className="p-error">{formValidations.fields['venueId'].errorMsg}</p>}

                            </div>
                            <div className="p-col-12 p-md-2">
                                <p className='ma-label-s1'>Co ordinator<span className='ma-required'>*</span></p>
                                <InputText className='p-inputtext-style1' 
                                  value={createVenueFields.coordinator}
                                  onChange={(e) => onTextChange(e.target.value, 'coordinator', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                              />
                              {formValidations && !formValidations.fields['coordinator'].isValid && <p className="p-error">{formValidations.fields['coordinator'].errorMsg}</p>}
                                
                            </div>
                        </div>
                        <div className='p-grid mt-6 ma-mt10'>
                            <div className="p-col-12 p-md-2">
                                <p className='ma-label-s1'>Mobile No of coordinator<span className='ma-required'>*</span></p>
                                <InputText className='p-inputtext-style1'

                                    value={createVenueFields.mobileNo}
                                    onChange={(e) => onTextChange(e.target.value, 'mobileNo', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['mobileNo'].isValid && <p className="p-error">{formValidations.fields['mobileNo'].errorMsg}</p>}

                            </div>
                            <div className="p-col-12 p-md-2">
                                <p className='ma-label-s1'>Email ID of coordinator<span className='ma-required'>*</span></p>
                                <InputText className='p-inputtext-style1'
                                    value={createVenueFields.email}
                                    onChange={(e) => onTextChange(e.target.value, 'email', this, createVenueFieldsJson, createVenueFields, formValidations, 'createVenueFields', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}

                            </div>
                        </div>
                        <div className='p-col-12 p-md-12 flex justify-end'>
                            <Button 
                            label={`${this.props.editVenue?.venueName ? "Update" : "Create"} Venue`}
                                // onClick={this.onClickCreateCourse}
                            onClick={this.onClickCreateVenue}
                            />

                        </div>
                    </div>

                </div>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(CreateVenue)))