export const StaticConstants = {
    ERUDEX_API_KEY: "ed09892d-b315-4f42-b24c-e41bd7e01cc0",
    ERUDEX_SECRET_KEY: "RPFLU7gZORJctpOnUpQ7AlKCoR0G1tybFuUZW/lETY4=",
    ERUDEX_CONTENT_URL: "https://api-q.erudex.com/content/getContentUrl",
    MAX_FILE_SIZE: 209715200
}



export const S3_UPLOAD_CONFIG = {
    bucketName: 'macademy-profiles-new',
    dirName: 'uploads/', /* optional */
    region: 'ap-south-1',
    accessKeyId: 'AKIAYQCXQ37F66I34T63',
    secretAccessKey: 'eSGEqiNgzEE8Gs8m1yrFGp3W+WabG4iZ4zSwQgWZ',
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}

export const MELUHA_BOARD_ID = '498fc658-d02f-4fd1-9181-0fb3912a0046';
export const EncryptionKey="H7dK92jSg5VvLqM0XpT3r4b8ZWnFyE6cI1oHaAiwYkUQDsRo"
export const ORGCODE = "aau"

export const FIELDS_INFO = {
    ORG_NAME: "Enter the name of the organization registered as per the ROC",
    ORG_TITLE: "Enter the title of the organization registered as per the ROC",
    ORG_ADDRESS: 'Enter the address of the organization registered as per the ROC',
    ORG_ESTD: "Enter the company established Years",
    ORG_COUNTRY: "Enter the country of the organization registerd as per the ROC",
    ORG_STATE: "Enter the state of the organization registerd as per the ROC",
    ORG_CITY: "Enter the city of the organization registered as per the ROC",
    ORG_PINCODE: 'Enter the pincode of the organization registered.',
    ORG_MOBILE_NUMBER_OPERATIONAL: "Enter the organization operational mobile number",
    ORG_MOBILE_NUMBER_ACCOUNTS: "Enter the organization accounts mobile number",
    ORG_EMAIL_ID_OPERATIONAL: "Enter the operational email-id of the organization",
    ORG_EMAIL_ID_ACCOUNTS: "Enter the accounts email_id of the organization",
    ORG_WEBSITE: "Enter the website of organization",
    ORG_SMS_CLIENT_WEBSITE: "Enter the sms client website of the organization",
    ORG_EMAIL_CLIENT_WEBSITE: "Enter the email client website of the organization",
    ORG_SUBDOMAIN: "Enter the subdomain of the organization",
    ORG_LOGO_RECTANGUALAR: "Upload the rectangular-logo of the organization",
    ORG_IDCARD_BANNER_IMAGE:"Upload the banner image of the organization",
    ORG_IDCARD_SIGNATURE:"Upload the signature image for the idcard",
    ORG_IDCARD_WATERMARK:"Upload the signature image for the idcard",
    ORG_IDCARD_QRCODE:"Please select the QR code genteration type",
    ORG_QRCODE_URL:"Please enter the valid url",
    ORG_QRCODE_TEXT:"Please enter the valid text",
    ORG_LOGO_SQUARE: "Upload the square-logo of the organization",
    ORG_GSTNO: "Enter the GST number",
    ORG_GST_CERTIFICATE: "Upload GST certificate.",
    ORG_AGREEMENT_TENURE_STARTDATE: "Select the start date of agreement tenure",
    ORG_AGREEMENT_TENURE_ENDDATE: "Select the end date of agreement tenure",
    ORG_AGREEMENT: "Upload agreement of the organization",
    ORG_OTP: "Please enter OTP to verify",
    ORG_ADMIN_USER: "Please enter Super User Phone Number for login",
    ORG_ADMIN_PASSWORD: "Please enter the password",
    ORG_ADMIN_CONFIRM_PASSWORD: "Please confirm the enter above password here",

    ROLE_SELECT: 'Please select the role you want to switch.',

    ADMIN_CHANGE_ROLE: 'Does user can change his role',
    ADMIN_USER_TYPE: 'Please select the user type',
    ADMIN_USER_ROLE: 'Please select user role',
    ADMIN_USER_NAME: 'Please enter the user name',
    ADMIN_USER_LOGIN_NAME: 'Please enter the username for login',
    ADMIN_USER_EMAIL_ID: 'Please enter the email id',
    ADMIN_USER_MOBILE: 'Please enter the mobile number',
    ADMIN_USER_PASSWORD: 'Please enter the password',
    ADMIN_USER_CONFIRM: 'Please renter the password',
    ADMIN_USER_CURRICULUM: 'Please assign the curriculum',

    ADMIN_ROLE_NAME: 'Please enter the role name',
    ADMIN_ROLE_DESCRIPTION: 'Please enter the role description',
    ADMIN_ROLE_TYPE: 'Please select role type',

    USER_USER_NAME: 'Please enter user name',
    USER_PASSWORD: 'Please enter password',
    USER_CONFIRM: 'Please enter confirm password',
    USER_GIVEN_NAME: 'Please enter given name',
    USER_MOBILE_NUMBER: 'Please enter mobile number',
    USER_EMAIL_ID: 'Please enter email id',
    USER_BOARD: 'Please select board',
    USER_CLASS: 'Please select class',
    PLEASE_SELECT: 'Please select ',
    ACADEMIC_YEAR:"Please select academic year",

    SUBJECT_NAME_1: 'Please enter subject name',
    SUBJECT_CODE: 'Please enter subject code',
    SUBJECT_STATUS: 'Please select subject status ',


    CHAPTER_NUMBER: 'Please enter chapter number',
    CHAPTER_NAME: 'Please enter chapter name',
    CHAPTER_CODE: 'Please enter chapter code',
    CHAPTER_DESCRIPTION: 'Please enter chapter description',
    CHAPTER_LONG_DESCRIPTION: 'Please enter chapter long description',
    CHAPTER_STATUS: 'Please select chapter status ',

    TOPIC_NAME: 'Please enter topic name',
    TOPIC_CODE: 'Please enter topic code',
    TOPIC_STATUS: 'Please select topic status',

    BOARD_NAME: 'Please enter board name',
    BOARD_CODE: 'Please enter board code',
    BOARD_STATUS: 'Please select board status',



    TEMPLATE_NAME: 'Please enter template name',
    TEMPALTE_CONTENT: 'Please enter template content',
    TEMPLATE_ID: 'Please enter template Id',

    TEMPALTE_BODY: 'Please enter template body',


    CLASS_NAME: 'Please enter class name',
    CLASS_STATUS: 'Please select class status',




    RESOURCE_TITLE: 'Please enter resource title',
    RESOURCE_FILE: 'Please select the file by clicking on select file, the selected file name will display here.',
    RESOURCE_TYPE: 'Please select resource type',
    RESOURCE_ORIGIN: 'Please select resource origin',
    RESOURCE_URLKEY: 'Please enter resource URL or key',
    RESOURCE_SOURCE: 'Resource source',
    RESOURCE_FILENAME: 'Resource file name',
    RESOURCE_LANGUAGE:'Select Content Language',



    LEVEL_NAME: 'Please enter the level name',
    SCHEME_OF_VALIDATION: 'Please enter the schema of validation.',
    KEY_ANSWER: 'Please enter the key or answer to validate the answer.',
    QUESTION: 'Please enter the question.',
    PARAGRAPH: 'Please enter the paragraph.',
    OPTION: 'Please enter the option.',
    KEY: 'Please select the key',
    KEYS: 'Please select the keys',
    HINT: 'Please enter the hint',
    EXPLAINATION: 'Please enter the explaination',
    DIFFICULTY: 'Please select the question difficulty.',
    SKILLTYPE: 'Please select the skill type.',
    EXAM_TYPE: 'Please select the exam type.',
    QUESTION_SOURCE: 'Please select the source type.',
    QUESTION_STATUS: 'Please select the question status.',
    QUESTION_TAGS: 'Please enter the question tags.',
    EXAM_NAME: 'Please enter the name of the test',
    NO_OF_QUESTIONS: 'Please enter the number of questions',
    EXAM_TIME: 'Please enter the exam time in minutes',
    TOTAL_MARKS: 'Please enter the total marks',
    VALID_FROM: 'Please enter the exam start date & time',
    VALID_TO: 'Please enter the exam end date & time',
    TEST_TYPE: 'Please select test type.',
    TEMPLATE: 'Please select exam template .',
    BOARD_SELECT: 'Please select board',
    CLASS_SELECT: 'Please select class',
    QUESTION_TYPES: 'Please select question types',
    SUBJECT_SELECT: 'Please select subject',
    EXAM_SELECT: 'Please select exam',

    NAME_OF_BREAK: 'Please enter name of break',
    DURATION: 'Please enter duration',

    TEACHER_NAME: 'Please select teacher',
    SUBJECT_NAME: 'Please select subject',
    DESCRIPTION: 'Please enter description',
    LEARNING_OBJECTIVE: 'Please enter learning objective',
    LEARNING_OUTCOME: 'Please enter learning outcome',
    NOTIFICATION_NAME: 'Please enter notification name',
    NOTIFICATION_TYPE: 'Please enter notification type',
    NOTIFICATION_SCHEDULE: 'Please enter notification schedule',
    DATEANDTIME: 'Please enter date and time',
    NOTIFICATION_MODE: 'Please enter notification mode',
    NOTIFICATION_TITLE: 'Please enter notification title',
    NOTIFICATION_DESCRIPTION: 'Please enter notification description',

    API_KEY: 'Please enter API Key',
    DLT_HEADER: 'Please enter DLT header',

    PHONE_NUMBER_ID: 'Please enter phone number Id registered in whatsapp.',
    SYSTEM_USER_TOKEN: 'Please system user token',

    HOST: 'Please enter host address',
    EMAIL: 'Please enter email',
    PASSWORD: 'Please enter password',
    FROM_NAME: 'Please enter from address',

    WHATSAPP_TEMPLATE_NAME: 'Please select whatsapp template',




    FEE_TYPE: 'Please enter Fee Type',
    IS_CONSESSION_APPLICABLE: 'Please enter is consession applicable for this fee type.',
    IS_STUDENT_SPECIFIC: 'Please select student specific',
    RELIGION : "Please select religion",
    CASTE : "Please select caste",
    GENDER : "Please select gender",
    ADMISSION_DATE :"Please select admission date",
    AADHAR_NUMBER : "Please enter aadhar number",
    DATE_OF_BIRTH:"Please select date of birth",
    AGE : "Please enter age",
    FATHER_NAME : "Please Enter Father Name",
    OCCUPATION : "Please Select Occopation",
    QUALIFICATION : "Please Select Qualification",
    MOTHER_NAME : "Please Enter Mother Name",
    GUARDIAN_NAME : "Please Enter Guardian Name",
    HOUSE_NUMBER : "Please Enter House Number",
    STREET : "Please Enter Street",
    MANDAL : "Please Enter Mandal",
    CITY : "Please Enter City",
    STATE : "Please Enter State",
    PINCODE : "Please Enter PIN-Code",
    UPLOAD : "Upload a file"
}


export const FeeApplicableType = [
    {
        label: "New Students",
        value: 'new'
    },
    {
        label: "Promoted Students",
        value: 'promoted'
    }
]


export const FeeRegistrationType = [
    {
        label: "Day Scholar",
        value: 'days'
    },
    {
        label: "Residential",
        value: 'residential'
    },
    {
        label: 'Semi Residential',
        value: 'semiresidential'

    }
]