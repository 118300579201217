import React from 'react';
import './login.scss';
import logoImg from '../../images/logo.png';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { userSignIn, clearSignInData, getUserData } from './../../store/actions';
import Authentication from './../session';
import { Link, withRouter } from 'react-router-dom';
import LoadingComponent from '../loadingComponent';
import { Carousel } from 'primereact/carousel';
import { I18Next } from '../../internationalization';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';

const INITIAL_STATE = {
    number: '',
    password: '',
    error: null,
    passwordErr: null
};
const carouselData = [
    {
        header: 'Welcome to Teacher Continuous Professional Development',
        desc: "Apps purpose is to empower you through continuous professional development. The benefits of using the app in their daily work life will be acquiring new age skills, resolving recurring issues and also focus on interactivity through which overall professional success is achieved for self and students in Telangana are benefited with the accomplishments you have and improves the passion towards education"
    },


]

const carouselData1 = [
    {
        img: 'new-assets/carouselImage1.png',
        name: 'image1'

    },


]
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            totalCounts: [],
            pvisible: true,
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 3,
                    numScroll: 3
                },
                {
                    breakpoint: '600px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '480px',
                    numVisible: 1,
                    numScroll: 1
                }
            ]
        };
        this.service = new Service()
    }

    isVaildNumber = (mobile) => {
        if (/^[0-9\b]+$/.test(mobile)) {
            return true;
        } else {
            return false;
        }
    };

    getCounts = (a) => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/organization/stats`;
        
        this.service.get(url, true).then((data) => {
            if (data && data.res && data.res.data) {
                this.setState({
                    totalCounts: data.res.data,
                    isLoading: false
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
        //  getCurriculum(boardId, classId, groupId);

    };

    componentDidMount() {
        this.getCounts()
        if (this.props.authenticated) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
            if (this.props.authenticated) {
                this.props.getUserData();
                setTimeout(() => {
                    this.props.history.push('/');
                }, 10);
            }
        }
    }

    userSignIn = () => {
        // const number = "+919052565771"  //'shashi2puppy'// `+91${this.state.number}`;
        // const pw ="Lodu@9052565771"       /// 'Dinesh@123'  // this.state.password;
        // const number = `+91${this.state.number}`;
        const pw = this.state.password;
        this.props.userSignIn(this.state.number, pw);
    };


    productTemplate(product) {
        return (
            <div className="product-item">
                <div className="product-item-content p-grid">
                    <div className="col p-col-4   product-image1 ">
                        <img src={`new-assets/carouseltop.png`} alt={product.name} className="product-image" />
                    </div>
                    <div className='col p-col-7'>
                        <h4 className="p-mb-1 carousel-title ">{product.header}</h4>
                        <h6 className="p-mt-0 p-mb-3 carousel-desc ">{product.desc}</h6>
                    </div>
                </div>
            </div>
        );
    }

    productTemplate1(product) {
        return (
            <div className="product-item ml-3">
                <img src={product.img} alt={product.name} className="carousel-image" />

            </div>
        );
    }

    render() {
        const { userSignIn, number, password, numberErr, passwordErr, totalCounts } = this.state;
        // console.log(this.props.orgCode)
        const showBtn = number === '' || password === '';
        //|| !this.isVaildNumber(number)
        const { logoRectangle } = this.props;
        return (
            <div id="login">
                <div className='p-grid ml-2 '>
                    <div className='p-grid top-section p-col-12 p-md-12 p-sm-12'>
                        <div className='  p-col-0 p-lg-1 p-md-0 p-sm-0'></div>
                        <div className=' left-section  p-col-8 p-lg-8 p-md-10  p-sm-11 '>
                            <h1 className='login-title'>Telangana Teacher Continuous Professional Development </h1>
                            {/* <h1 className='login-title2'> </h1> */}

                            <div className="carousel-demo">
                                <Carousel className='' style={{ backgroundColor: "#2f4dd1", borderRadius: "20px" }} value={carouselData} numVisible={1} numScroll={1} responsiveOptions={this.state.responsiveOptions} itemTemplate={this.productTemplate}
                                ></Carousel>

                            </div>
                        </div>

                        <div className=' right-section m-auto p-col-3 p-lg-2 bodercard  md:w-50'>
                            <div className='login-header text-center '>
                                <h3 className='login-header-title'>Welcome back</h3>
                                <p className='login-header-desc'>Welcome back please enter your details</p>
                            </div>
                            <div className="col-12 ">
                                <label className='login-label'>Please Enter Employee id<span className='ma-required'>*</span></label>
                                <input
                                    onChange={(e) => {
                                        this.setState({ number: e.target.value });
                                    }}
                                    value={number}
                                    ref="number"
                                    type="text"
                                    className="input-1"
                                    placeholder="username"
                                />
                                <div className="ma-danger">{numberErr ? 'Please enter mobile number' : ' '}</div>
                            </div>
                            <div className="col-12  ">
                                <label className='login-label'>Password <span className='ma-required'>*</span></label>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <input
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        onBlur={() =>
                                            password === ''
                                                ? this.setState({ passwordErr: 'please enter the password' })
                                                : this.setState({ passwordErr: '' })
                                        }
                                        value={password}
                                        ref="password"
                                        type={this.state.pvisible ? "password" : "text"}
                                        className="input-1"
                                        placeholder="Password"
                                    />
                                    <i className={this.state.pvisible ? 'pi pi-eye' : 'pi pi-eye-slash'} style={{alignSelf:'center',  right:110 , position:'absolute' }} onClick={() => this.setState({
                                        pvisible: !this.state.pvisible
                                    })} />
                                </div>
                                <div className="ma-danger">{passwordErr ? 'Please enter password' : ' '}</div>
                            </div>
                            <div className="text-center text-warning">
                                {this.props.errMsg && this.props.errMsg.message ? this.props.errMsg.message : null}
                            </div>
                            <div style={{ textAlign: 'center', color: 'red' }}>{this.props.errMsg && this.props.errMsg}</div>
                            <div className="mt-3  ">
                                <Button
                                    disabled={showBtn}
                                    className='login_btn ma-w100p'
                                    label="Sign In"
                                    onClick={() => this.userSignIn()} />
                            </div>
                        </div>

                    </div>
                    <div className='p-grid bottom-section p-col-12'>
                        <div className='col  p-col-1 p-lg-1 p-md-0 p-sm-1'></div>
                        <div className='left-section p-col-8 p-lg-8 p-md-12 p-sm-12'>
                            <div className='p-grid ma--white'>
                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-1 ma-pointer'
                                    >
                                        <p className="card-title1">{"Total teachers Available"}</p>
                                        <p className="card-value1">{totalCounts?.totalTeachers || 0}</p>
                                    </div>
                                </div>

                                <div className='p-col-12  p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-2 ma-pointer'
                                    //  onClick={() => this.onClickCard(userStats.activeStudents, "Total Teachers")}
                                    >
                                        <p className="card-title1">{"Total Active users"} </p>
                                        <p className="card-value1">{totalCounts?.totalActiveUsers|| 0}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-3 ma-pointer'
                                    // onClick={() => this.onClickCard(userStats.activeStudents, 'Inactive Students')}
                                    >
                                        <p className="card-title1">{'Total Trainers'} </p>
                                        <p className="card-value1">{totalCounts?.totalTrainers|| 0}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-4 ma-pointer'
                                    // onClick={() => this.onClickCard(userStats.activeStudents, "Inactive Teachers")}
                                    >
                                        <p className="card-title1">{"Total courses"}  </p>
                                        <p className="card-value1">{totalCounts?.totalCourses|| 0}</p>
                                    </div>
                                </div>
                            </div>




                            <div className='p-grid ma--white'>
                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-1 ma-pointer'
                                    // onClick={() => this.onClickCard(userStats.activeStudents, "Total Users")}
                                    >
                                        <p className="card-title1"> {"Total Enrolled"}</p>
                                        <p className="card-value1">{totalCounts?.totalEnrolled|| 0}</p>
                                    </div>
                                </div>

                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-2 ma-pointer'
                                    //  onClick={() => this.onClickCard(userStats.activeStudents, "Total Teachers")}
                                    >
                                        <p className="card-title1">{"Total Certificates Issued"} </p>
                                        <p className="card-value1">{totalCounts?.totalIsIssueCertificate|| 0}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-3 ma-pointer'
                                    // onClick={() => this.onClickCard(userStats.activeStudents, 'Inactive Students')}
                                    >
                                        <p className="card-title1">{'Total Sessions'} </p>
                                        <p className="card-value1">{totalCounts?.totalSessions|| 0}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3 ma-center-a'>
                                    <div className='dashboard-card1 dc-4 ma-pointer'
                                    // onClick={() => this.onClickCard(userStats.activeStudents, "Inactive Teachers")}
                                    >
                                        <p className="card-title1">{"Total Venues"}  </p>
                                        <p className="card-value1">{totalCounts?.totalVenue|| 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='right-section mr-0  p-col-3 p-lg-3 p-md-5 p-sm-12'>
                            <div className="carousel-demo ">
                                <div className="">
                                    <Carousel className='carousel-bottom-img' value={carouselData1} numVisible={1} numScroll={1} responsiveOptions={this.state.responsiveOptions} itemTemplate={this.productTemplate1}
                                    ></Carousel>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.props.isLoading || this.props.loading) && <LoadingComponent />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.login && state.login,
    authenticated: state.auth && state.auth.authenticated,
    loading: state.signInData && state.signInData.loading && state.signInData.loading,
    error: state.signInData && state.signInData.error && state.signInData.error,
    errMsg: state.signInData && state.signInData.errMsg && state.signInData.errMsg.message,
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgCode: state.orgData.orgCode,
    isLoading: state.orgData.isLoading,
    isSuperOrg: state.orgData.isSuper,
});

export default connect(mapStateToProps, { userSignIn, clearSignInData, getUserData })(
    Authentication(withRouter(LoginPage))
);
