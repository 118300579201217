import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { I18Next } from './../../../internationalization';
import { connect } from 'react-redux';
import Authentication from './../../session';
import { ProgressBar } from 'primereact/progressbar';


const jsonFuny = [
    {
        subject: "Maths",
        num1: 123456,
        num2: 654561
    }, {
        subject: "Maths",
        num1: 123456,
        num2: 654561
    }, {
        subject: "Maths",
        num1: 123456,
        num2: 654561
    }, {
        subject: "Maths",
        num1: 123456,
        num2: 654561
    }, {
        subject: "Maths",
        num1: 123456,
        num2: 654561
    },

]


class TopBranches extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,


        }



        this.service = new Service();


        // const userAnalyticsBySubjectT = userAnalyticsBySubject.map(item => {
        //     if (item.mp4 > 0 || item.pdf > 0 || item.zip > 0 || item.quiz > 0) {
        //         isGraphDataAvailable = true;
        //     }
        //     return { Videos: item.mp4, Pdf: item.pdf, Ppt: item.zip, Quiz: item.quiz, quizCount: item.quizCount, subjectId: item.subjectId, subjectName: item.subjectName };
        // });
    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/analytics/resourceUsageReport`;
        this.service.get(url, true).then(res => {


            if (res && res.status) {
                this.setState({
                    ...res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        // this.getReport();

    }
    render() {
        const { defaultLanguage } = this.props;
        const { graphData, stats, inlineLoading } = this.state;
        const langType = defaultLanguage


        return (
            <div className='ma-relative'>
                <div className='resource-usage-report'>
                    <div className='flex justify-between'>
                            <h3 className="resource-title ma-mt10"><img src="./images/down_arrow_icon.svg" style={{height:'14px'}} />{I18Next('topBranches', langType).COMMON_KEY}</h3>
                        <div style={{ display: 'flex', gap: '5px', marginTop: "2%" }}>
                            <p>icon</p>
                            {/* <img src="./images/down_arrow_icon.svg" /> */}

                        </div>
                    </div>
                    <hr></hr>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-12' >
                            {
                                <div className='trending-table-rup' style={{ width: "120%" }}>
                                    <table className='trending-table'>
                                        {/* <tr>
                                            <th style={{ color: "#800000", font: "normal normal bold 14px/16px Roboto" }}>{I18Next('number', langType).COMMON_KEY}</th>
                                            <th style={{ color: "#3cb371", font: "normal normal bold 14px/16px Roboto" }}>{I18Next('type', langType).COMMON_KEY}</th>
                                            <th style={{ color: "#778899", font: "normal normal bold 14px/16px Roboto" }}>{I18Next('count', langType).COMMON_KEY}</th>
                                        </tr> */}
                                        {/* {this.props?.resourceWiseContent?.slice(0, 3)?.map((branch, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    {branch._id === "video" ? <td>Videos</td> : ""}
                                                    {branch._id === "mp4" ? <td>MP4</td> : ""}
                                                    {branch._id === "pdf" ? <td>PDF</td> : ""}
                                                    {branch._id === "ppt" ? <td>PPT</td> : ""}
                                                    {branch._id === "zip" ? <td>Zip</td> : ""}
                                                    {branch._id === "quiz" ? <td>Quiz</td> : ""}
                                                    <td>{branch.count}</td>
                                                </tr>
                                            </>
                                        )
                                    })} */}
                                        {
                                            jsonFuny.map((e) => {
                                                return (
                                                    <tr>
                                                        <td>{e.subject}</td>
                                                        <td >{e.num1}</td>
                                                        <td >{e.num2}</td>
                                                    </tr>
                                                )
                                            })
                                        }


                                    </table>
                                </div>
                            }
                        </div>
                        {/* <div className='p-col-12 p-md-1'>
                            {
                                <div className='usage-trend'>
                                    <p className='trend-title'>{I18Next('usageTrend', langType).COMMON_KEY}</p>
                                    <img src='./new-assets/Path_3270.png' style={{ height: '20px', marginLeft: '20px' }} />
                                    <p className='trend-mark'>+ {75}%</p>
                                </div>
                            }

                        </div> */}

                    </div>

                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(TopBranches));


