import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import CkEditorComponent from './common/ckEditor';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Authentication from './../session';
import { withRouter } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import MathJax from 'react-mathjax-preview';
import FileUpload from '../fileUpload';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { baseUrlAdmin } from '../../store/apiConstants';
import { Chips } from 'primereact/chips';
import Service from '../../services';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl, onChipsChange } from '../../utile/formHelper';
import { Difficulty, ExamTypes, QuestionSource, QuestionTargets, QuestionTypeKeysWithAQ, QuestionTypes, QuestionTypesWithAQ, SkillTypes, Status } from './constants';
import {
    getBoardsData,
    getImageUrl
} from './../../store/actions';
import FileUploadLambda from '../fileUpload/fileUploadLambda';
import uuidv4 from 'uuid/v4';
import PreviousExam from './common/previousExamAdd';
import AddFromRepo from '../contentMaster/addFromRepo';
import VideoLink from './common/videoLink';
import ContentPlayers from '../contentMaster/contentPlayers';

import _ from 'lodash';
import PreviewQuestion from './common/previewQuestion';
import { AlphabetSingleKey, generateNumKey, isValid, isValidNum, OptionToAlphabet, warningDailogInit } from '../../utile';
import IntegerKey from './common/integerKeySelectionPad';
import NumPad from './common/numPad';
import ParaQuestion from './common/paraQuestionAdd';
import VideosLinking from './common/videosLinking';
import SingleQuestion from './common/singleQuestion';
import AttachPreviewDelete from './common/attachPreviewDelete';
import LoadingComponent from '../loadingComponent';
import { InputTextarea } from 'primereact/inputtextarea';
import { FIELDS_INFO } from '../../constants';
import InputTextB from '../customComponents/inputTextB';
import { WarningIcon } from '../svgIcons';
const removeHtmlTags = (htmlStr) => {
    htmlStr = htmlStr.replace(/&nbsp;/g, " ");
    return htmlStr;
};



class AddQustion extends Component {


    constructor(props) {
        super(props);

        this.state = {
            questionTarget: 'Normal',
            boardId: this.props.boards[0].boardId,
            classId: null,
            groupId: null,
            classes: [],
            boards: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            boardName: null,
            className: null,
            groupName: null,
            subjectName: null,
            chapterName: null,
            topicName: null,
            questionType: null,
            questionInfo: {},
            formValidations: {},


            isShowQuestionPreview: false,
            warningDialog: warningDailogInit,


        }
        this.service = new Service();
    }



    componentDidMount() {
        if (this.props.editQuestion) {

            this.generateEditForm(this.props.editQuestion);


        }

        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {

            // const _classes = [];
            // const selectedBoard = this.props.boards && this.props.boards.find((board) => board.isBase);

            // if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            //     selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
            // }
            // this.setState({
            //     classes: _classes,
            //     boardId: selectedBoard.boardId
            // });
          this.onChangeBoard(this.props.boards[0].boardId)
        }
       


    }


    componentDidUpdate(prevProps) {
        if (prevProps.boards != this.props.boards) {
            const _classes = [];
            // const selectedBoard = this.props.boards && this.props.boards.find((board) => board.isBase);
            // if (selectedBoard) {


            //     if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            //         selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
            //     }
            //     this.setState({
            //         classes: _classes,
            //         boardId: selectedBoard.boardId
            //     });
            // }
        }

    }



    preProcessFormDataToEdit = (editQustion, questionType) => {
        let data = {
            ...editQustion
        };

        if (questionType == QuestionTypeKeysWithAQ.SCCT || questionType == QuestionTypeKeysWithAQ.MCCT) {
            if (questionType == QuestionTypeKeysWithAQ.SCCT) {
                data.key = AlphabetSingleKey(data.key);
            } else if (questionType == QuestionTypeKeysWithAQ.MCCT) {
                if (data.key && data.key.length) {
                    data.key = data.key.map((k) => {
                        k = AlphabetSingleKey(k)
                        return k
                    })
                }
            }
        } else if (questionType == QuestionTypeKeysWithAQ.PSGS || questionType == QuestionTypeKeysWithAQ.PSGM) {

            data.questions = data.questions.map(q => {
                if (q.questionType == QuestionTypeKeysWithAQ.PQS) {
                    q.key = AlphabetSingleKey(q.key);
                } else if (q.questionType == QuestionTypeKeysWithAQ.PQM) {
                    if (q.key && q.key.length) {
                        q.key = q.key.map((k) => {
                            k = AlphabetSingleKey(k)
                            return k
                        })
                    }
                }

                let obg = {
                    data: q,
                    assigned: true,
                    id: uuidv4()
                }
                return obg;
            });
        }
        return data;
    }


    formGradesDataForEdit = (editQuestion) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editQuestion.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editQuestion.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editQuestion.groupId)

                this.setState(
                    {
                        boardId: editQuestion.boardId,
                        classId: editQuestion.classId,
                        groupId: editQuestion.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }

    generateEditForm = (editQuestion) => {

        this.fields = this.getFields(editQuestion.questionType);

        let dataa = this.preProcessFormDataToEdit(editQuestion, editQuestion.questionType);

        this.formFields = getFormFields(this.fields, dataa);


        this.setState({
            questionTarget: editQuestion.questionTarget,
            questionType: editQuestion.questionType,
            questionInfo: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }, () => {
            this.formGradesDataForEdit(editQuestion)
        });


    }


    onQuestionTargetChange = (questionTarget) => {
        this.setState({
            questionTarget
        })
    }


    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };
   

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass.className,
                groupId: _groups[0].value,
                groupName: _groups[0].label,
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,

                content: null,

            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start
    getSelectedCurriculum = (subjects) => {
        let { subjectId, chapterId, topicId } = this.props.editQuestion;

        let selectedSubject = subjects && subjects.length && subjects.find((subject) => subject.subjectId == subjectId);
        let selectedChapter = selectedSubject && selectedSubject.chapters && selectedSubject.chapters.find(c => c.chapterId == chapterId);
        let selectedTopic = selectedChapter && selectedChapter.topics && selectedChapter.topics.find(c => c.topicId == topicId);

        return {
            subjects,
            chapters: selectedSubject.chapters,
            topics: (selectedChapter && selectedChapter.topics) ? selectedChapter.topics : [],
            subjectId,
            chapterId,
            topicId,
            subjectName: selectedSubject.subjectName,
            chapterName: selectedChapter ? selectedChapter.chapterName : null,
            topicName: selectedTopic ? selectedTopic.topicName : null,
            content: null,

        };

    }

    getCurriculumData = (isEdit) => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {



                if (data && data.res && data.res.Item) {
                    let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}

                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,

                        ...selected
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });

                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                subjectName: selectedSubject[0].subjectName,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,

            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,

            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName
            });
    };


    onQuestionTypeChange = (questionType) => {
        this.generateForm(questionType);
    }

    getFields = (questionType) => {
        switch (questionType) {
            case QuestionTypeKeysWithAQ.SCCT:
                return require("./scctQuestion.json");

            case QuestionTypeKeysWithAQ.MCCT:
                return require("./scctQuestion.json");
            case QuestionTypeKeysWithAQ.INT:
                return require("./integerQuestion.json");
            case QuestionTypeKeysWithAQ.NUM:
                return require("./integerQuestion.json");
            case QuestionTypeKeysWithAQ.FB:
                return require("./fbQuestion.json");
            case QuestionTypeKeysWithAQ.PSGS:
                return require("./psg1Question.json");
            case QuestionTypeKeysWithAQ.PSGM:
                return require("./psg1Question.json");
            case QuestionTypeKeysWithAQ.VSAQ:
            case QuestionTypeKeysWithAQ.SAQ:
            case QuestionTypeKeysWithAQ.LAQ:
                return require("./vsaqQuestion.json");


            default:
                return []

        }
    }

    preProcessFormDataToAdd = (questionType) => {
        let data = {
            // previousExam: [{
            //     id: uuidv4(),
            //     exam: '',
            //     year: ''
            // }]
            difficulty: 'Easy',
            previousExam: []
        };
        if (questionType == QuestionTypeKeysWithAQ.SCCT || questionType == QuestionTypeKeysWithAQ.MCCT) {
            data = {
                ...data,
                options: [{
                    id: uuidv4(),
                    imageUrl: '',
                    value: '',
                }, {
                    id: uuidv4(),
                    imageUrl: '',
                    value: '',
                }, {
                    id: uuidv4(),
                    imageUrl: '',
                    value: '',
                }, {
                    id: uuidv4(),
                    imageUrl: '',
                    value: '',
                }]



            }
        } else if (questionType == QuestionTypeKeysWithAQ.PSGS || questionType == QuestionTypeKeysWithAQ.PSGM) {
            let q = {
                assigned: false,
                id: uuidv4()
            }
            let q2 = {
                assigned: false,
                id: uuidv4()
            }


            data = {
                ...data,
                questions: [q, q2]
            }

        }




        return data;




    }
    generateForm = (questionType) => {
        this.fields = this.getFields(questionType);

        let dataa = this.preProcessFormDataToAdd(questionType);

        this.formFields = getFormFields(this.fields, dataa);


        this.setState({
            questionType,
            questionInfo: this.formFields.data,
            formValidations: this.formFields.formValidations,
        })


    }

    addAttachment = (fieldName, fileName, data) => {

        let questionInfo = {
            ...this.state.questionInfo,
            [fieldName]: data.imageStorageKey
        }
        let formValidations = isFieldValid(this.fields.find(fm => fm.FieldName == fieldName), this.state.formValidations, questionInfo);


        this.setState((prevState) => {
            return {
                questionInfo,
                formValidations
            }
        });
    }
    _afterFilesUploaded = (files) => {

    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    _onLogoRectSuccess = (fileName, data) => {


        //  this.props.getImageUrl(data.imageStorageKey);
        this.addAttachment('attachmentUrl', fileName, data);

    }

    _onAttachSuccess = (fieldName) => (fileName, data) => {


        // this.props.getImageUrl(data.imageStorageKey);
        this.addAttachment(fieldName, fileName, data);

    }

    removeAttach = (fieldName) => {
        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    [fieldName]: ''
                }
            };
        });

    }

    _onOptionImageSuccess = (optionId) => (fileName, data) => {
        let questionInfo = this.state.questionInfo;
        questionInfo.options = questionInfo.options.map((o) => {
            if (o.id == optionId) {
                o.imageUrl = data.imageStorageKey;
            }
            return o;
        });
        // this.props.getImageUrl(data.imageStorageKey);
        //  this.addAttachment('attachmentUrl', fileName, data);

        this.setState({
            questionInfo
        })

    }


    onOptionChange = (optionId, value) => {

        let questionInfo = this.state.questionInfo;
        questionInfo.options = questionInfo.options.map((o) => {
            if (o.id == optionId) {
                o.value = value;
            }
            return o;
        });
        //this.props.getImageUrl(data.imageStorageKey);
        //  this.addAttachment('attachmentUrl', fileName, data);

        this.setState({
            questionInfo
        })
    }

    removeOptionsAttach = (optionId) => {

        let questionInfo = this.state.questionInfo;
        questionInfo.options = questionInfo.options.map((o) => {
            if (o.id == optionId) {
                o.imageUrl = '';
            }
            return o;
        });
        //this.props.getImageUrl(data.imageStorageKey);
        //  this.addAttachment('attachmentUrl', fileName, data);

        this.setState({
            questionInfo
        })
    }

    onRemoveOption = (optionId) => {
        let questionInfo = this.state.questionInfo;
        if (questionInfo.options.length > 1) {
            questionInfo.options = questionInfo.options.filter((o) => o.id != optionId);
            questionInfo.key = '';
            this.setState({
                questionInfo,

            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Can not remove, atleast one options is required.', life: 3000 });
        }
    }

    onAddOption = () => {
        let questionInfo = this.state.questionInfo;
        if (questionInfo.options.length > 4) {
            this.toast.show({ severity: 'error', summary: 'Can not add', detail: 'Can not be add more than 5 options', life: 3000 });
        } else {
            questionInfo.options = [
                ...questionInfo.options,
                {
                    id: uuidv4(),
                    imageUrl: '',
                    value: '',
                }];
            questionInfo.key = '';
            this.setState({
                questionInfo
            })
        }

    }

    onKeyChange = (key) => {
        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    key
                }
            };
        });
    }


    onSave = (data) => {
        let previousExam = this.state.questionInfo.previousExam.map((item) => {
            if (item.id == data.id) {
                return { ...item, ...data };
            } else {
                return item;
            }
        });

        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    previousExam: previousExam
                },
            }
        });
    }

    addMore = () => {

        if (this.state.questionInfo.previousExam.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    questionInfo: {
                        ...prevState.questionInfo,
                        previousExam: [...prevState.questionInfo.previousExam, { id: uuidv4(), assigned: false }],
                    }
                }
            }
            );
        } else {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Please save to add more', life: 3000 });

        }
    }



    removePreviousExamRow = (id) => {
        let formValidations = this.state.formValidations;

        formValidations.fields['previousExam'] = { ...formValidations.fields['previousExam'], isValid: true, errorMsg: `` }
        if (this.state.questionInfo.previousExam.length) {
            let previousExam = this.state.questionInfo.previousExam.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    questionInfo: {
                        ...prevState.questionInfo,
                        previousExam
                    },
                    formValidations
                }
            });
        }
    }

    onSelectContent = (checkedContetForAssign, contentRepo) => {
        this.setState({
            checkedContetForAssign, contentRepo
        })
    };

    onAssignVideos = (videos) => {
        let { videoLinking } = this.state.questionInfo;
        videos.forEach(v => {
            if (!videoLinking.find((vid) => vid.contentId == v.contentId)) {

                delete v.childCurriculumContentKeys;
                delete v.questionIds;
                videoLinking.push(v);


            };

        })

        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    videoLinking
                },
                isShowLinkVideo: false
            }
        });

    }


    removeLinkedVideo = (video) => {
        let { videoLinking } = this.state.questionInfo;
        videoLinking = videoLinking.filter((v) => v.contentId != video.contentId);

        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    videoLinking
                }
            }
        });
    }




    onNumberClick = (e) => {

        if (isValidNum(this.state.questionInfo.key, e)) {
            // this.setState((prevState) => {
            //     return {
            //         questionInfo: {
            //             ...prevState.questionInfo,
            //             key: prevState.questionInfo.key + e
            //         }
            //     }
            // });
            onTextChange(this.state.questionInfo.key + e, 'key', this, this.fields, this.state.questionInfo, this.state.formValidations, 'questionInfo', 'formValidations')
            //  this.setState(prevState => ({ numKeyValue: prevState.questionInfo.key + e, numKeyValueError: '' }));
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid Entry', detail: 'Please enter the values between -9999.99 to 9999.99', life: 3000 });
        }

    };

    onClearLastClick = () => {
        if (this.state.questionInfo.key && this.state.questionInfo.key.length > 0) {
            onTextChange(this.state.questionInfo.key.slice(0, -1), 'key', this, this.fields, this.state.questionInfo, this.state.formValidations, 'questionInfo', 'formValidations')
        }
    };

    isGradeValid = () => {
        let { boardId, classId, groupId, subjectId, chapterId, topicId, questionTarget, questionType } = this.state;
        if (questionTarget == 'General') {
            // if (boardId && !topicId) {
            //     return false;
            // } else {
            return true
            // }

        } else if (!boardId || !classId || !groupId || !subjectId || (chapterId && !topicId)) {
            return false
        } else if (questionTarget == 'Normal' && (!chapterId || !topicId)) {
            return false
        } else {
            return true
        }
    }

    isAdditionalValid = () => {
        let { questionType, questionInfo, formValidations } = this.state;
        let isValid = true;

        if (questionType == QuestionTypeKeysWithAQ.SCCT) {
            if (questionInfo.options && questionInfo.options.length) {
                questionInfo.options = questionInfo.options.map((option) => {
                    if (option.value == '' && option.imageUrl == '') {

                        isValid = false;
                    }
                    return option
                })
            }
            //this.toast.show({ severity: 'error', summary: 'Options Required', detail: 'Please enter all options', life: 3000 });
        }


        if (questionType == QuestionTypeKeysWithAQ.PSGS || questionType == QuestionTypeKeysWithAQ.PSGM) {
            if (!(questionInfo.questions && questionInfo.questions.length)) {
                formValidations.fields['questions'] = { ...formValidations.fields['questions'], isValid: false, errorMsg: `Please save qustions` }
                isValid = false;
            } else if (!(questionInfo.questions.every(i => i.assigned))) {
                isValid = false;
                formValidations.fields['questions'] = { ...formValidations.fields['questions'], isValid: false, errorMsg: `Please save qustions` }
            } else {
                formValidations.fields['questions'] = { ...formValidations.fields['questions'], isValid: true, errorMsg: `` }
            }
        }

        if ((questionInfo.previousExam && questionInfo.previousExam.length && !(questionInfo.previousExam.every(i => i.assigned)))) {
            formValidations.fields['previousExam'] = { ...formValidations.fields['previousExam'], isValid: false, errorMsg: `Please save or remove Previous Exam` }
            isValid = false;
        } else {
            formValidations.fields['previousExam'] = { ...formValidations.fields['previousExam'], isValid: true, errorMsg: `` }
        }

        return isValid;
    }




    preProcessForm = (data, questionType) => {
        if (questionType == QuestionTypeKeysWithAQ.SCCT || questionType == QuestionTypeKeysWithAQ.MCCT) {
            data.question = data.question
            data.options.forEach((o, index) => {
                o.value = o.value
                o.id = OptionToAlphabet('Option ' + (index + 1));
            });

            data.explanation = data.explanation
            data.hint = data.hint
        }
        if (questionType == QuestionTypeKeysWithAQ.SCCT) {
            data.key = OptionToAlphabet(data.key);
        } else if (questionType == QuestionTypeKeysWithAQ.MCCT) {

            if (data.key && data.key.length) {
                data.key = data.key.map((k) => {
                    k = OptionToAlphabet(k)
                    return k
                })

            }

        } else if (questionType == QuestionTypeKeysWithAQ.NUM) {
            if (data.key != '') {
                data.key = generateNumKey(data.key);
                data.explanation = data.explanation
                data.hint = data.hint
            }


        } else if (questionType == QuestionTypeKeysWithAQ.FB) {
            data.key = data.key;
            data.alternateKey = data.alternateKey;
            data.explanation = data.explanation
            data.hint = data.hint
        } else if (questionType == QuestionTypeKeysWithAQ.PSGS || questionType == QuestionTypeKeysWithAQ.PSGM) {


            if (data.questions && data.questions.length) {
                if (data.questions && data.questions.length) {
                    data.questions = data.questions.map(question => {
                        if (question.assigned) {
                            question = question.data;

                            question.question = question.question
                            question.options.forEach((o, index) => {
                                o.value = o.value
                                o.id = OptionToAlphabet('Option ' + (index + 1));
                            });
                            question.explanation = question.explanation
                            question.hint = question.hint



                            if (question.questionType == QuestionTypeKeysWithAQ.PQS) {
                                question.key = OptionToAlphabet(question.key);
                            } else if (question.questionType == QuestionTypeKeysWithAQ.PQM) {

                                if (question.key && question.key.length) {
                                    question.key = question.key.map((k) => {
                                        k = OptionToAlphabet(k)
                                        return k
                                    })

                                }

                            }

                        }


                        return question
                    })

                }
            }
        }


        data.tags.forEach(tag => {
            tag = tag.trim();
        })



        return data;


    }

    onSubmitClick = (isPreview, isMove) => {
        const { questionInfo, questionType } = this.state;

        let data = _.cloneDeep(questionInfo);
        data = this.preProcessForm(data, questionType)
        const formStatus = isFormValid(this.fields, this.formFields.formValidations, data);
        if (!formStatus.formValidations.isFormValid || !this.isAdditionalValid()) {

            this.setState({
                //  isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all * marked fields', life: 3000 });
        } else if (!this.isGradeValid()) {
            this.toast.show({ severity: 'error', summary: 'Grades Required', detail: 'Please select grades', life: 3000 });
        } else {
            let {
                boardId,
                classId,
                groupId,
                subjectId,
                chapterId,
                topicId,
                boardName,
                className,
                groupName,
                subjectName,
                chapterName,
                topicName,
                questionType,
                questionTarget
            } = this.state;
            let questionObj = {
                ...data,
                boardId,
                classId,
                groupId,
                subjectId,
                boardName,
                className,
                groupName,
                subjectName,
                questionType,
                questionTarget
            }

            ///  if (chapterId && topicId && chapterName && topicName) {
            questionObj = {
                ...questionObj,
                chapterId,
                topicId,
                chapterName,
                topicName,
            }
            ///  }


            //     console.log(questionObj)
            // this.toast.show({ severity: 'success', summary: 'Valid', detail: 'valid', life: 3000 });
            if (isPreview) {
                this.setState({
                    isShowQuestionPreview: true,
                    questionToPreview: questionObj
                })
            } else {

                if (isMove) {
                    questionObj.questionTarget = 'Normal';
                    questionObj.isMove = true;



                }

                if (this.props.editQuestion) {

                    this.setState({
                        isLoading: true
                    });


                    this.service.put(`${baseUrlAdmin}/question/${data.questionId}`, questionObj, true).then((data) => {


                        if (data?.res?.success) {

                            this.setState({
                                questionTarget: 'Normal',
                                questionType: null,
                                boardId: null,

                                classes: [], groups: [],
                                classId: null, groupId: null,
                                subjects: [], subjectId: null,
                                chapters: [], chapterId: null,
                                topics: [], topicId: null,
                                content: null,
                                questionInfo: null,
                                formValidations: null,
                                isLoading: false
                            }, this.props.onQuestionUpdate)
                            // this.formFields = undefined;



                        } else {

                            this.setState({
                                isLoading: false
                            })

                            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Some error occured', life: 3000 });
                        }


                    }).catch(e => {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Warning', detail: e.message, life: 3000 });
                        console.log(e);

                    });
                } else {


                    this.setState({
                        isLoading: true
                    });

                    this.service.post(`${baseUrlAdmin}/question/saveQuestion`, questionObj, true).then((data) => {


                        if (data?.res?.success) {
                            this.fields = this.getFields(questionType);

                            let dataa = this.preProcessFormDataToAdd(questionType);

                            this.formFields = getFormFields(this.fields, dataa);





                            this.setState({
                                // questionTarget: 'Normal',
                                // questionType: null,
                                //  boardId: null,

                                //  classes: [], groups: [],
                                //   classId: null, groupId: null,
                                //    subjects: [], subjectId: null,
                                //     chapters: [], chapterId: null,
                                //     topics: [], topicId: null,
                                //    content: null,
                                questionInfo: this.formFields.data,
                                formValidations: _.cloneDeep(this.formFields.formValidations),
                                isLoading: false
                            })
                            // 


                            this.toast.show({ severity: 'success', summary: 'Question Created', detail: data?.res?.successResponse[0].message, life: 10000 });

                        } else {

                            this.setState({
                                isLoading: false
                            })
                            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Some error occured', life: 3000 });
                        }


                    }).catch(e => {
                        this.toast.show({ severity: 'error', summary: 'Warning', detail: e.message, life: 3000 });
                        console.log(e);
                        this.setState({
                            isLoading: false
                        })
                    });

                }
            }
            //  
        }


    }


    onAssignClick = (data) => {
        let formValidations = this.state.formValidations;
        let questions = this.state.questionInfo.questions.map((item) => {
            if (item.id == data.id) {
                return { ...item, ...data };
            } else {
                return item;
            }
        });

        formValidations.fields['questions'] = { ...formValidations.fields['questions'], isValid: true, errorMsg: `` }
        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    questions: questions
                },
                formValidations
            }
        });
    }

    addMoreQuestion = () => {

        if (this.state.questionInfo.questions.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    questionInfo: {
                        ...prevState.questionInfo,
                        questions: [...prevState.questionInfo.questions, { id: uuidv4(), assigned: false }],
                    }
                }
            }
            );
        } else {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Please save question before adding another one', life: 3000 });

        }
    }



    removeTempQuestion = (id) => {
        if (this.state.questionInfo.questions.length > 2) {
            let questions = this.state.questionInfo.questions.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    questionInfo: {
                        ...prevState.questionInfo,
                        questions
                    },
                }
            });
        } else {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Can not delete question. Minimum 2 questions are required.', life: 3000 });
        }
    }


    fieldRenderWithCkEditor = (label, filedName, attachmentName, id, isMandatory, FIELD) => {

        const { questionInfo, formValidations } = this.state;
        return <div id={filedName}>
            <p className='add-q-label'>{label} <span className='ma-required'>{isMandatory ? '*' : ''}</span> </p>
            <div className="p-grid ma-p10" >
                <div className="p-col-12 p-md-12 p-lg-6">
                    <div>
                        <InputTextB info={FIELDS_INFO[FIELD]} id="cr1DA1">
                            {/* <InputTextarea rows={3} cols={30}
                                value={questionInfo[filedName]}
                                onChange={(e) => onTextChange(e.target.value, filedName, this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                multiple={true}
                                className='latex-input ma-w100p' /> */}
                            <CkEditorComponent
                                value={questionInfo[filedName]}
                                onChange={(value) =>
                                    onTextChange(value, filedName, this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')
                                    //  this.onOptionChange(opt.id, value)
                                }
                            />
                        </InputTextB>
                        {formValidations && formValidations.fields && !formValidations.fields[filedName].isValid && <p className="p-error">{formValidations.fields[filedName].errorMsg}</p>}
                        <FileUploadLambda id={id}
                            multiple={false}
                            onProgress={this._onProgress}
                            onSucess={(fileName, data) => this._onAttachSuccess(attachmentName)(fileName, data)}
                            maxFileSize={307200}
                            onError={this._onFilsUploadError}
                            accept={'images'}
                            title={'Images'}
                            dirName=''
                            afterFilesUploaded={this._afterFilesUploaded}
                        >
                            <label className='attachment-label' htmlFor={id}>
                                Attachment <i className='pi pi-file' />
                            </label>
                        </FileUploadLambda>
                        <div className='ma-clearFix'>
                        </div>
                        {this.props.getAllImagesUrlInfo[questionInfo[attachmentName]] &&
                            <AttachPreviewDelete
                                imagePath={this.props.getAllImagesUrlInfo[questionInfo[attachmentName]]}
                                removeAttach={() => { this.removeAttach(attachmentName) }}

                            />}
                    </div>
                </div>
                <div className="p-col-12  p-md-12 p-lg-6">
                    <div className='preview-qo'>
                        <SingleQuestion
                            id={'23'}
                            mathJaxValue={questionInfo[filedName]}
                            attachment={questionInfo[attachmentName]} />
                        {/* <SingleQuestion
                        id={'24'}
                        mathJaxValue={opt.value}
                        attachment={opt.imageUrl} /> */}
                    </div>
                </div>
            </div>
        </div>
    }
    fieldRender = (label, filedName, attachmentName, id, isMandatory, FIELD) => {

        const { questionInfo, formValidations } = this.state;
        return <div id={filedName}>
            <p className='add-q-label'>{label} <span className='ma-required'>{isMandatory ? '*' : ''}</span> </p>
            <div className="p-grid ma-p10" >
                <div className="p-col-12 p-md-12 p-lg-6">
                    <div>
                        <InputTextB info={FIELDS_INFO[FIELD]} id="cr1DA1">
                            <InputTextarea rows={3} cols={30}
                                value={questionInfo[filedName]}
                                onChange={(e) => onTextChange(e.target.value, filedName, this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                multiple={true}
                                className='latex-input ma-w100p' />
                        </InputTextB>
                        {formValidations && formValidations.fields && !formValidations.fields[filedName].isValid && <p className="p-error">{formValidations.fields[filedName].errorMsg}</p>}
                    </div>
                    <div>
                        <FileUploadLambda id={id}
                            multiple={false}
                            onProgress={this._onProgress}
                            onSucess={(fileName, data) => this._onAttachSuccess(attachmentName)(fileName, data)}
                            maxFileSize={307200}
                            onError={this._onFilsUploadError}
                            accept={'images'}
                            title={'Images'}
                            dirName=''
                            afterFilesUploaded={this._afterFilesUploaded}
                        >
                            <label className='attachment-label' htmlFor={id}>
                                Attachment <i className='pi pi-file' />
                            </label>
                        </FileUploadLambda>
                        <div className='ma-clearFix'>
                        </div>
                        {this.props.getAllImagesUrlInfo[questionInfo[attachmentName]] &&
                            <AttachPreviewDelete
                                imagePath={this.props.getAllImagesUrlInfo[questionInfo[attachmentName]]}
                                removeAttach={() => { this.removeAttach(attachmentName) }}

                            />}
                    </div>
                </div>
                <div className="p-col-12  p-md-12 p-lg-6">
                    <div className='preview-qo'>
                        <SingleQuestion
                            id={'23'}
                            mathJaxValue={questionInfo[filedName]}
                            attachment={questionInfo[attachmentName]} />
                        {/* <SingleQuestion
                            id={'24'}
                            mathJaxValue={opt.value}
                            attachment={opt.imageUrl} /> */}
                    </div>
                </div>
            </div>
        </div>
    }

    questionRender = () => {
        return <>
            {this.fieldRender('Question', 'question', 'attachmentUrl', 'questinImg', true, 'QUESTION')}
        </>
    }

    vsaqRender = () => {
        return <>
            {this.fieldRender('Question', 'question', 'attachmentUrl', 'questinImg', true, 'QUESTION')}
            {this.fieldRender('Key', 'key', 'keyUrl', 'keyUrlImg', true, 'KEY_ANSWER')}
            {this.fieldRender('Scheme of Validation', 'schemeOfValidation', 'schemeOfValidationUrl', 'schemeOfValidationUrlImg', true, 'SCHEME_OF_VALIDATION')}
        </>
    }


    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
        }, () => {

            this.onSubmitClick(false, true)
        })

    }

    optionsRender = () => {
        const { questionInfo, formValidations, questionType } = this.state;
        return <>
            <div className='ma-mt20'>
                <p className='add-q-label'>Options</p>
                <div className='ma-p10'>
                    {questionInfo.options && questionInfo.options.length ? <>
                        {questionInfo.options.map((opt, index) => {
                            return <div key={opt.id}>
                                <p className='add-option-label'>Option {index + 1} <span className='ma-required'>*</span> <span className='ma-right ma-pointer' onClick={() => { this.onRemoveOption(opt.id) }}><i className='pi pi-times'></i></span></p>
                                <div className="p-grid" >
                                    <div className="p-col-12 p-md-12 p-lg-6">
                                        <div>
                                            {/* <CkEditorComponent
                                                value={opt.value}
                                                onChange={(value) => this.onOptionChange(opt.id, value)}
                                            /> */}
                                            <InputTextB info={FIELDS_INFO.OPTION} id="crW11">
                                                <InputTextarea rows={3} cols={30}
                                                    value={opt.value}
                                                    onChange={(e) => this.onOptionChange(opt.id, e.target.value)}
                                                    className='latex-input ma-w100p' />
                                            </InputTextB>
                                        </div>
                                        <div>
                                            <FileUploadLambda id={`opt${opt.id.substring(0, 5)}`}
                                                multiple={false}
                                                onProgress={this._onProgress}
                                                onSucess={(filename, data) => this._onOptionImageSuccess(opt.id)(filename, data)}
                                                maxFileSize={307200}
                                                onError={this._onFilsUploadError}
                                                accept={'images'}
                                                title={'Images'}
                                                dirName=''
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <label className='attachment-label' htmlFor={`opt${opt.id.substring(0, 5)}`}>
                                                    Attachment <i className='pi pi-file' />
                                                </label>
                                            </FileUploadLambda>
                                            <div className='ma-clearFix'>

                                            </div>
                                            {opt.imageUrl &&
                                                <AttachPreviewDelete
                                                    imagePath={this.props.getAllImagesUrlInfo[opt.imageUrl]}
                                                    removeAttach={() => { this.removeOptionsAttach(opt.id) }}

                                                />}
                                        </div>
                                    </div>
                                    <div className="p-col-12  p-md-12 p-lg-6">
                                        <div className='preview-qo'>
                                            <SingleQuestion
                                                id={'24'}
                                                mathJaxValue={opt.value}
                                                attachment={opt.imageUrl} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                        })}

                    </>
                        : <></>}
                </div>
                {formValidations && formValidations.fields && !formValidations.fields['options'].isValid && <p className="p-error">{formValidations.fields['options'].errorMsg}</p>}
                <Button label="Add Option" className='p-button-outlined ma-ml10' icon="pi pi-plus" iconPos='left' onClick={this.onAddOption} />
                {questionInfo.options && questionInfo.options.length ? <>
                    <div className="ma-mt20 key-selector">
                        {/* <div className="p-col-3 p-md-2 p-lg-1">
                            <p className='add-q-label'>Key</p>
                        </div> */}
                        <span className='add-q-label'>Key</span> <span className='ma-required ma-mr20'>*</span>
                        {/* <div className="p-col-8 p-md-8 p-lg-8 ma-mt2"> */}
                        {questionType == QuestionTypeKeysWithAQ.MCCT ? <>
                            <InputTextB info={FIELDS_INFO.KEYS} id="crW11">
                                <MultiSelect value={questionInfo.key}
                                    className='ma-w200'
                                    options={questionInfo.options.map((o, index) => { return { label: `Option ${index + 1}`, value: `Option ${index + 1}` } })}
                                    onChange={(e) => onChipsChange(e.value, 'key', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                    placeholder="Select Key" />
                            </InputTextB>
                        </> : <>
                            <InputTextB info={FIELDS_INFO.KEY} id="cAr11">
                                <Dropdown value={questionInfo.key}
                                    className='ma-w200'
                                    options={questionInfo.options.map((o, index) => { return { label: `Option ${index + 1}`, value: `Option ${index + 1}` } })}
                                    onChange={(e) => onChipsChange(e.value, 'key', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                    placeholder="Select Key" />
                            </InputTextB>
                        </>}
                        {formValidations && formValidations.fields && !formValidations.fields['key'].isValid && <p className="p-error">{formValidations.fields['key'].errorMsg}</p>}
                        {/* </div> */}
                    </div>
                </> : <></>}
            </div>
        </>
    }

    explainationAndHintRender = () => {
        return <div className='ma-mt20'>
            {this.fieldRender('Explanation', 'explanation', 'explainationAttachment', 'explaimg', false, 'EXPLAINATION')}
            {this.fieldRender('Hint', 'hint', 'hintAttachment', 'hintimg', false, 'HINT')}
        </div>
    }


    intKeyRender = () => {
        const { questionInfo, formValidations } = this.state;
        return <>
            <div>
                <p className='add-q-label'>Key <span className='ma-required'>*</span></p>
                <IntegerKey value={questionInfo.key}
                    onClick={(key) => onTextChange(key.toString(), 'key', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                />
                {formValidations && formValidations.fields && !formValidations.fields['key'].isValid && <p className="p-error">{formValidations.fields['key'].errorMsg}</p>}
            </div>
        </>
    }

    numKeyRender = () => {
        const { questionInfo, formValidations } = this.state;
        return <>
            <div>
                <p className='add-q-label'>Key  <span className='ma-required'>*</span></p>
                <div className='ma-ml10'>
                    <InputText value={questionInfo.key} disabled className='p-inputtext-style1' />
                    <Button label='1234' className='btn-1234' onClick={(event) => this.menu.toggle(event)} />
                    {formValidations && formValidations.fields && !formValidations.fields['key'].isValid && <p className="p-error">{formValidations.fields['key'].errorMsg}</p>}
                </div>
                <Menu
                    className='numpad-menu'
                    model={[
                        {
                            template: (<NumPad onNumberClick={(e) => this.onNumberClick(e)}
                                onClearLastClick={(e) => this.onClearLastClick(e)} />)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        </>
    }

    fbAnswerRender = () => {
        const { questionInfo, formValidations } = this.state;
        return <>
            <div className='ma-mt20' >
                <p className='add-q-label'>Answers</p>
                <div className="p-grid ma-p10 " >
                    <div className="p-col-12 p-md-12 p-lg-6">
                        <p className='add-option-label'>Answer <span className='ma-required'>*</span></p>
                        <div>
                            {/* <CkEditorComponent
                                value={questionInfo.key}
                                onChange={(value) => onTextChange(value, 'key', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                            /> */}
                            <InputTextarea rows={3} cols={30}
                                value={questionInfo.key}
                                onChange={(e) => onTextChange(e.target.value, 'key', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                className='latex-input ma-w100p' />
                            {formValidations && formValidations.fields && !formValidations.fields['key'].isValid && <p className="p-error">{formValidations.fields['key'].errorMsg}</p>}
                        </div>

                    </div>
                    <div className="p-col-12  p-md-12 p-lg-6">
                        <div className='preview-qo preview-alt-ans'>
                            <MathJax math={questionInfo.key} />

                        </div>
                    </div>
                </div>
                <div className="p-grid ma-p10 " >
                    <div className="p-col-12 p-md-12 p-lg-6">
                        <p className='add-option-label'>Alternate answer</p>
                        <div>
                            {/* <CkEditorComponent
                                value={questionInfo.alternateKey}
                                onChange={(value) => onTextChange(value, 'alternateKey', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                            /> */}
                            <InputTextarea rows={3} cols={30}
                                value={questionInfo.alternateKey}
                                onChange={(e) => onTextChange(e.target.value, 'alternateKey', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                className='latex-input ma-w100p' />
                            {formValidations && formValidations.fields && !formValidations.fields['alternateKey'].isValid && <p className="p-error">{formValidations.fields['alternateKey'].errorMsg}</p>}
                        </div>

                    </div>
                    <div className="p-col-12  p-md-12 p-lg-6">
                        <div className='preview-qo preview-alt-ans'>
                            <MathJax math={questionInfo.alternateKey} />

                        </div>
                    </div>
                </div>

            </div>
        </>
    }


    paraRender = () => {
        return <>
            {this.fieldRender('Paragraph', 'paragraph', 'paragraphUrl', 'paragraphUrl', true, 'PARAGRAPH')}
        </>
    }

    paraQuestionsRender = () => {
        const { questionInfo, formValidations } = this.state;
        return <>
            <p className='add-q-label'>Questions</p>
            <div className='ma-p10 '>
                {questionInfo.questions && questionInfo.questions.length ? <>
                    {questionInfo.questions.map((temp, index) => <ParaQuestion data={temp.data}
                        questions={questionInfo.questions}
                        key={temp.id}
                        id={temp.id}
                        assigned={temp.assigned}
                        index={index + 1}
                        onAssignClick={this.onAssignClick}
                        removeTempQuestion={this.removeTempQuestion}
                        questionType={this.state.questionType}
                    />)}

                </> : <></>}
            </div>
            {formValidations && formValidations.fields && !formValidations.fields['questions'].isValid && <p className="p-error">{formValidations.fields['questions'].errorMsg}</p>}
            <Button className='ma-mt20 p-button-outlined' onClick={this.addMoreQuestion} icon="pi pi-plus" iconPos='left' label='Add Question' />
        </>
    }

    getQuestionRender = (questionType) => {
        switch (questionType) {
            case QuestionTypeKeysWithAQ.SCCT:
            case QuestionTypeKeysWithAQ.MCCT:
                return <>
                    {this.questionRender()}
                    {this.optionsRender()}
                    {this.explainationAndHintRender()}
                </>;
            case QuestionTypeKeysWithAQ.INT:
                return <>
                    {this.questionRender()}
                    {this.intKeyRender()}
                    {this.explainationAndHintRender()}
                </>;
            case QuestionTypeKeysWithAQ.NUM:
                return <>
                    {this.questionRender()}
                    {this.numKeyRender()}
                    {this.explainationAndHintRender()}
                </>;
            case QuestionTypeKeysWithAQ.FB:
                return <>
                    {this.questionRender()}
                    {this.fbAnswerRender()}
                    {this.explainationAndHintRender()}
                </>;
            case QuestionTypeKeysWithAQ.PSGS:
            case QuestionTypeKeysWithAQ.PSGM:
                return <>
                    {this.paraRender()}
                    {this.paraQuestionsRender()}
                </>;
            case QuestionTypeKeysWithAQ.VSAQ:
            case QuestionTypeKeysWithAQ.SAQ:
            case QuestionTypeKeysWithAQ.LAQ:
                return <>
                    {this.vsaqRender()}
                </>;

            default:
                return <>Question type not supported</>
        }
    }

    render() {
        // console.log('props-add', this.props)
        const { questionInfo, formValidations } = this.state;

        return (<>
            <div className='add-qustion'>
                <div>
                    <Dropdown value={this.state.questionTarget}
                        className='ma-w200 ma-mt10'
                        options={QuestionTargets}
                        optionLabel="value"
                        optionValue="id"
                        disabled={this.props.editQuestion}
                        onChange={(e) => this.onQuestionTargetChange(e.value)}
                        placeholder="Question Target" />
                    <span className='ma-required-mark '>*</span>
                    <Dropdown value={this.state.questionType}
                        className='ma-w200 ma-mt10'
                        options={QuestionTypesWithAQ}
                        optionLabel="label"
                        optionValue="value"
                        disabled={this.props.editQuestion}
                        onChange={(e) => this.onQuestionTypeChange(e.value)}
                        placeholder="Question Type" />
                    <span className='ma-required-mark '>*</span>
                    {
                        // (this.state.questionTarget != "General" || this.props.editQuestion) &&
                        <>
                            <Dropdown 
                            value={this.state.boardId}
                                className='ma-w400 ma-mt10'
                                options={this.props.boards}
                                // disabled={this.props.editQuestion && this.props.editQuestion.questionTarget != 'General'}
                                optionLabel="boardName"
                                optionValue="boardId"
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="Select Board"
                                />
                            {this.state.questionTarget != 'General' ? <span className='ma-required-mark '>*</span> : <span className='ma-mr20'></span>}
                            {
                                this.state.boardId && <><Dropdown value={this.state.classId}
                                    options={this.state.classes}
                                    disabled={this.props.editQuestion && this.props.editQuestion.questionTarget != 'General'}
                                    optionLabel='className'
                                    optionValue='classId'
                                    className='ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                    {this.state.questionTarget != 'General' ? <span className='ma-required-mark '>*</span> : <span className='ma-mr20'></span>}</>
                            }
                            {
                                this.state.groupId && <><Dropdown value={this.state.subjectId}
                                    options={this.state.subjects}
                                    disabled={this.props.editQuestion && this.props.editQuestion.questionTarget != 'General'}
                                    optionLabel='subjectName'
                                    optionValue='subjectId'
                                    className='ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                                    {this.state.questionTarget != 'General' ? <span className='ma-required-mark '>*</span> : <span className='ma-mr20'></span>}</>
                            }


                            {
                                this.state.subjectId && <><Dropdown value={this.state.chapterId}
                                    options={this.state.chapters}
                                    disabled={this.props.editQuestion && this.props.editQuestion.questionTarget != 'General'}
                                    optionLabel='chapterName'
                                    optionValue='chapterId'
                                    className='ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                                    {this.state.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-mr20'></span>}


                                </>
                            }
                            {
                                this.state.chapterId && <> <Dropdown value={this.state.topicId}
                                    options={this.state.topics}
                                    disabled={this.props.editQuestion && this.props.editQuestion.questionTarget != 'General'}
                                    optionLabel='topicName'
                                    optionValue='topicId'
                                    className='ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                                    {(this.state.questionTarget == 'Normal') ? <span className='ma-required-mark '>*</span> : <span className='ma-mr20'></span>}

                                </>
                            }

                        </>
                    }



                </div>
                {this.state.questionType && <>
                    <div className='paper-s1 ma-p20 ma-mt20'>
                        {this.getQuestionRender(this.state.questionType)}
                    </div>

                    <div className='paper-s1  ma-p20 ma-mt20'>
                        <p className='add-q-label'>Meta Information</p>
                        <div className='question-additonal'>
                            <div className="p-grid ma-w100p " >
                                <div className="p-col-12 p-md-4 p-lg-4 ma-pr0">
                                    <p className='ma-label-s2'>Difficulty</p>
                                    <InputTextB info={FIELDS_INFO.DIFFICULTY} id="cqr1D">
                                        <Dropdown
                                            value={questionInfo.difficulty}
                                            className='ma-w200'
                                            options={Difficulty}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => onDropDownChange(e.value, 'difficulty', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                            placeholder="Select Difficulty" />
                                    </InputTextB>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4 ma-pr0">
                                    <p className='ma-label-s2'>Skill Type</p>
                                    <InputTextB info={FIELDS_INFO.SKILLTYPE} id="cr1gD">
                                        <Dropdown
                                            value={questionInfo.skillType}
                                            className='ma-w200'
                                            options={SkillTypes}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => onDropDownChange(e.value, 'skillType', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                            placeholder="Select Skill Type" />
                                    </InputTextB>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4 ma-pr0">
                                    <p className='ma-label-s2'>Exam Type</p>
                                    <InputTextB info={FIELDS_INFO.EXAM_TYPE} id="wcwr1D">
                                        <MultiSelect
                                            value={questionInfo.examType}
                                            className='ma-w200'
                                            options={ExamTypes}
                                            optionLabel="label"
                                            optionValue="value"
                                            onChange={(e) => onChipsChange(e.value, 'examType', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                            placeholder="Select Exam Type" />
                                    </InputTextB>
                                </div>
                            </div>
                            <p className='ma-label-s2 ma-mt10'>Source</p>
                            <InputTextB info={FIELDS_INFO.QUESTION_SOURCE} id="xcwr1D">
                                <Dropdown
                                    value={questionInfo.source}
                                    className='ma-w200'
                                    options={QuestionSource}
                                    optionLabel="label"
                                    optionValue="value"
                                    onChange={(e) => onDropDownChange(e.value, 'source', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                    placeholder="Select Question Source" />
                            </InputTextB>
                            <p className='ma-label-s2 ma-mt10'>Status</p>
                            <InputTextB info={FIELDS_INFO.QUESTION_STATUS} id="ncwr1D">
                                <Dropdown
                                    value={questionInfo.status}
                                    className='ma-w200'
                                    options={Status}
                                    optionLabel="label"
                                    optionValue="value"
                                    onChange={(e) => onDropDownChange(e.value, 'status', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                    placeholder="Select Status" />
                            </InputTextB>
                            {formValidations && formValidations.fields && !formValidations.fields['status'].isValid && <p className="p-error">{formValidations.fields['status'].errorMsg}</p>}
                            <div>

                                <p className='ma-label-s2 ma-mt10'>Previous Exam</p>
                                <div className='previous-exam'>
                                    {
                                        questionInfo && questionInfo.previousExam && questionInfo.previousExam.map((temp) => {
                                            return <div key={temp.id} >
                                                <PreviousExam data={temp.data}
                                                    previousExam={questionInfo.previousExam}
                                                    key={temp.id} id={temp.id}
                                                    onSave={this.onSave}
                                                    removePreviousExamRow={this.removePreviousExamRow} />
                                            </div>
                                        })
                                    }
                                    {/* <Button
                                    icon='pi pi-plus'
                                    iconPos='left'
                                        label={`Add More`}
                                        onClick={this.addMore}
                                    /> */}
                                    <span onClick={this.addMore} className='ma-addmore ma-pointer'>
                                        <i className="pi pi-plus-circle"></i>
                                    </span>
                                </div>
                                {formValidations && formValidations.fields && !formValidations.fields['previousExam'].isValid && <p className="p-error">{formValidations.fields['previousExam'].errorMsg}</p>}
                            </div>
                            <div>
                                <p className='ma-label-s2 ma-mt20'>Tags</p>
                                <InputTextB info={FIELDS_INFO.QUESTION_TAGS} id="cwer1D">
                                    <Chips
                                        className='question-tags'
                                        value={questionInfo.tags} onChange={(e) => onChipsChange(e.value, 'tags', this, this.fields, questionInfo, formValidations, 'questionInfo', 'formValidations')}
                                    ></Chips>
                                </InputTextB>
                            </div>
                            {
                                this.state.questionTarget == 'Normal' && <div className='ma-mt20'>

                                    <p className='ma-label-s2 ma-mt20'>Videos Linking</p>
                                    <VideosLinking
                                        onSelectContent={this.onSelectContent}
                                        onAssignVideos={this.onAssignVideos}
                                        videoLinking={questionInfo.videoLinking}
                                        removeLinkedVideo={this.removeLinkedVideo}
                                    />


                                </div>
                            }

                        </div>
                    </div>
                </>}

            </div>
            <div className='ma-clearFix'>

            </div>
            {
                this.state.questionType && <Toolbar className="ma-toolbar ma-mt20"
                    right={() => {
                        return (<>
                            <Button label='Preview' className='p-button-outlined' onClick={() => this.onSubmitClick(true)} />
                            <Button label={'Submit'} className='ma-m-lr10' onClick={() => this.onSubmitClick()} />
                            {
                                (this.state.questionTarget == 'General' && this.props.editQuestion) && <Button
                                    label="Move"
                                    disabled={!this.state.topicId}
                                    className={`ma-ml20 ma-mt5 ${!this.state.topicId ? 'p-button-secondary' : ''}`}
                                    onClick={() => {
                                        this.setState({
                                            warningDialog: {
                                                visible: true,
                                                headerMsg: 'Are you Sure?',
                                                message: `Update question and move`
                                            }
                                        });
                                    }} />
                            }

                        </>)
                    }}></Toolbar>
            }
            {
                //this.state.isShowQuestionPreview
                this.state.isShowQuestionPreview && <PreviewQuestion
                    question={this.state.questionToPreview}
                    onHide={() => {
                        this.setState({
                            isShowQuestionPreview: false
                        })
                    }}
                />
            }

            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            <Dialog
                header={<></>}
                draggable={false}
                closeOnEscape={true}
                className='ma-alert-box'
                blockScroll={true}
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '40vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, questionToMove: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, })}>
                <div>
                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                <br />
                {/* {questionToMove && <>
                    <p className='ma-alert-msg'>{questionToMove.boardName} {'>'} {questionToMove.className} {'>'}
                        {questionToMove.subjectName} {'>'}
                        {questionToMove.chapterName} {'>'}
                        {questionToMove.topicName}
                    </p>
                </>} */}
            </Dialog>
        </>
        )
    }
}




const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData,
    getImageUrl

})(Authentication(withRouter(AddQustion)));