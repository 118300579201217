import React, { Component } from 'react';

export default class ScormPlayer extends Component {

    render() {
        const { contentStorageKey } = this.props.contentInfo
        return <div style={{height:'73vh'}}>
            {contentStorageKey && contentStorageKey.length && contentStorageKey.indexOf('.zip') ? <iframe width={'100%'} height={'100%'} controlsList="nodownload" className=" iframe_video" allowFullScreen={true} src={`https://s3.ap-south-1.amazonaws.com/meluhaunzippedcontent-new/${contentStorageKey && contentStorageKey.length && contentStorageKey.split('.')[0]}/story.html`}></iframe> : <span>No Results Found</span>}
        </div>;
    }
}
