import React, { Component } from 'react'
import Service from '../../../services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from 'primereact/toolbar';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import SvgViewer from './../../customComponents/svgViewer';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { InputText } from 'primereact/inputtext';
import {
  getBoardsData,
} from '../../../store/actions';
import { baseUrlAdmin } from '../../../store/apiConstants';
import SingleQuestion from '../../questionBank/common/singleQuestion';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { Dropdown } from 'primereact/dropdown';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../utile/formHelper';
import { MultiSelect } from 'primereact/multiselect';
import { Difficulty, QuestionTypeKeys, QuestionTypes, SkillTypes, Status } from '../../questionBank/constants';
import { BasicLazyParams, getServerTime } from '../../../utile';
import PreviewQuestion from '../../questionBank/common/previewQuestion';
import uuidv4 from 'uuid/v4';
import { Calendar } from 'primereact/calendar';
import QuizPreview from './quizPreview';
import moment from 'moment';
import _ from 'lodash';
import QuestionInTable from '../../questionBank/common/questionInTable';
import { PreviewIcon } from '../../svgIcons';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import LoadingComponent from '../../loadingComponent';
const quizFields = require('./../examForms/assessment.json');

const processEditQuiz = (editQuiz) => {

  // editUser = _.cloneDeep(editUser);
  // editUser.gradeId = editUser.classId;
  // editUser.phone_number = editUser.phone_number.substring(3);

  // editUser.grade = editUser['custom:grade'];
  // editUser.group = editUser['custom:group'];
  // editUser.board = editUser['custom:board'];
  // editUser.board = editUser['custom:board'];
  return editQuiz;
}

const testTypeOptions = [
  {label: "Self-Assessment",value : "prerequisite"},
  {label: "Pre Assessment",value : "preAssessment"},
  {label: "Post Assessment",value : "postAssessment"},
]

class CreateAssessment extends Component {
  constructor(props) {
    super(props);
    this.formFields = getFormFields(quizFields, this.props.editQuiz ? processEditQuiz(this.props.editQuiz) : {});
    this.state = {
      exam: this.formFields.data,
      formValidations: this.formFields.formValidations,
      boards: [],
      classes: [],
      groups: [],
      subjects: [],
      chapters: [],
      topics: [],
      boardId: null,
      classId: null,
      groupId: null,
      subjectId: null,
      chapterId: null,
      topicId: null,
      boardName: null,
      className: null,
      groupName: null,
      subjectName: null,
      chapterName: null,
      topicName: null,
      isLoading: false,
      columns: [
        { columnName: 'Question Type', isVisible: true, id: 'questionType' },
        { columnName: 'Video Linked', isVisible: true, id: 'videoLinking' },
        { columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
        { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
        { columnName: 'Exam Type', isVisible: false, id: 'examType' },
        { columnName: 'Source', isVisible: false, id: 'source' },
        { columnName: 'Status', isVisible: false, id: 'status' },

      ],
      showSearch: false,
      globalSearch: '',
      lazyParams: BasicLazyParams,
      questions: [],
      currentStep: 1,
      selectedQIds: []
      // questions: quess
    }

    this.service = new Service();
  }

  isColumnVisible = (id) => {
    return this.state.columns.filter(c => c.id == id)[0].isVisible;
  }



  formGradesDataForEdit = (editQuestion) => {

    if (this.props.boards && this.props.boards.length) {
      const _classes = [];
      const _groups = [];
      const selectedBoard =
        this.props.boards &&
        this.props.boards &&
        this.props.boards.length > 0 &&
        this.props.boards.find((board) => board.boardId === editQuestion.boardId);
      if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
      }

      const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editQuestion.classId);
      if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
        selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
        _groups.find((g) => g.groupId == editQuestion.groupId)

        this.setState(
          {
            boardId: editQuestion.boardId,
            classId: editQuestion.classId,
            groupId: editQuestion.groupId,
            classes: _classes,
            groups: _groups,
            boardName: selectedBoard.boardName,
            className: selectedClass.className,

          }, () => {
            this.getCurriculumData(true);
          });
      }
    }
  }

  componentDidMount() {
    if (!this.props.boards || !this.props.boards.length) {
      this.props.getBoardsData();
    }


    if (this.props.editExamId) {

      this.setState({
        isLoading: true
      });
      this.service.get(`${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/Assessment`, true).then((data) => {




        if (data && data.res && data.res.Item && data.res.Item.length) {
          this.setState({
            exam: data.res.Item[0],
            selectedQIds: data.res.Item[0].questions.map((q) => q.questionId),
            isLoading: false,
          }, () => this.formGradesDataForEdit(data.res.Item[0]));
        } else {
          this.setState({
            isLoading: false
          })
        }
      }).catch(e => {
        this.setState({
          isLoading: false
        });
      });



    }
  }




  // grades selection --- start
  onChangeBoard = (boardId) => {
    this.setState((prevState) => {
      return {
        classes: [], groups: [],
        classId: null, groupId: null,
        subjects: [], subjectId: null,
        chapters: [], chapterId: null,
        topics: [], topicId: null,
        content: null,
        exam: {
          ...prevState.exam,
          questions: []

        },
        selectedQIds: [],
        questions: [],
        totalRecords: 0

      };
    });

    const _classes = [];
    const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

    if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
      selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
    }
    this.setState({
      classes: _classes,
      boardId,
      boardName: selectedBoard.boardName,
      resourseOrigin: selectedBoard.isBase ? 'upload' : null

    });

  };

  onChangeClass = (classId) => {

    this.setState((prevState) => {
      return {
        groups: [], groupId: null,
        subjects: [], subjectId: null,
        chapters: [], chapterId: null,
        topics: [], topicId: null,
        content: null,
        exam: {
          ...prevState.exam,
          questions: []

        },
        selectedQIds: [],
        questions: [],
        totalRecords: 0,
      };
    });


    const boardId = this.state.boardId;
    const _groups = [];
    const selectedBoard =
      this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
    const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
    if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
      selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
      this.setState({
        groups: _groups,
        classId,
        className: selectedClass.className,
        groupId: _groups[0].value,
        groupName: _groups[0].label,
      }, () => {
        this.getCurriculumData();
      });
    }
  };


  //subjects --- start
  getSelectedCurriculum = (subjects) => {
    let { subjectId, chapterId, topicId } = this.state.exam;

    let subjectIds = subjectId;
    let chapterIds = chapterId;
    let chapters = [];
    //let chapterId = [];



    subjects.map((subject) => {
      // subjectIds.map((subjectId) => {
        if (subjectId == subject.subjectId) {
          chapters.push({
            label: subject.subjectName,
            items: subject.chapters.map((item) => { return { value: item.chapterId, label: item.chapterName, topics: item.topics } }),
          })
          return;
        }
      // });
    });

    let topics = [];


    let allChapters = []
    chapters.map((s) => {

      allChapters = allChapters.concat(s.items);

    })


    allChapters.map((chapter) => {
      chapterIds.map((chapterId) => {
        if (chapterId == chapter.value) {
          topics.push({
            label: chapter.label,
            items: chapter.topics.map((item) => { return { value: item.topicId, label: item.topicName } }),
          })
          // chapters.push({ chapterId: chapter.chapterId, chapterName: chapter.chapterName })
          return;
        }
      });
    });


    return {
      subjects,
      chapters,
      topics,
      subjectId,
      chapterId,
      topicId
    };
  }

  getCurriculumData = (isEdit) => {
    const { boardId, classId, groupId } = this.state;
    if (boardId && classId && groupId) {
      this.setState({
        isLoading: true
      })
      const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
      this.service.get(url, true).then((data) => {



        if (data && data.res && data.res.Item) {
          let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}
          if(isEdit){
            let sId = selected.subjectId[0]
            selected.subjectId= sId
          }
          this.setState({
            curriculumInfo: data.res.Item,
            subjects: data.res.Item.subjects,
            isLoading: false,

            ...selected
          }, () => {

            if (isEdit) {
              this.onSearch()
            }
          });
        } else {
          this.setState({
            isLoading: false
          })
        }
      });
      //  getCurriculum(boardId, classId, groupId);
    } else {
      this.setState({
        isLoading: false
      })
      // this.snackBarOpen('select board, class and group id');
    }
  };




  onChangeMultiSubject = (id) => {

    let chapters = [];
    let chapterId = [];
    let subjectIds = new Array()
    subjectIds.push(id)

    if(Array.isArray(subjectIds)){
      this.setState({subjectId : subjectIds})

    this.state.subjects.map((subject) => {
      subjectIds.map((subjectId) => {
        if (subjectId == subject.subjectId) {
          chapters.push({
            label: subject.subjectName,
            // subjectId: subject.subjectId,
            items: subject.chapters.map((item) => { return { value: item.chapterId, label: item.chapterName, chapterId: item.chapterId, topics: item.topics } }),
          })
          return;
        }
      });
    });

    // if (this.state.subjectId && this.state.chapterId) {
    //   if (subjectIds.length > this.state.subjectId.length) {
    //     chapterId = this.state.chapterId
    //   } else {
    //     let removedSubjects = [];
    //     this.state.subjectId.forEach((cId) => {
    //       if (!subjectIds.includes(cId)) {
    //         removedSubjects.push(cId);
    //       }
    //     });

    //     let chaptersInRemovedSubjects = [];
    //     removedSubjects.forEach(rmvdCId => {
    //       this.state.chapters.forEach((c) => {
    //         if (rmvdCId == c.subjectId) {
    //           chaptersInRemovedSubjects = chaptersInRemovedSubjects.concat(c.items.map(c => { return c.value }));
    //         }
    //       });
    //     });

    //     this.state.chapterId.forEach((tId) => {
    //       if (!chaptersInRemovedSubjects.includes(tId)) {
    //         chapterId.push(tId);
    //       }
    //     })
    //   }
    // }

    chapters = chapters.filter((c) => c.items.length);

    let topicArr = []
    let chapterArr = []

    if(chapters.length){
      chapters.map(e => {
        e.items.map(f => {
          chapterArr.push(f.chapterId)
          f.topics.map(g => {
            topicArr.push(g.topicId)
          })
        })
      })
    }


    this.setState((prevState) => {
      return {
        chapters: chapters,
        subjectId: id,
        chapterId: chapterArr,

        topics: [],
        topicId: topicArr,
        exam: {
          ...prevState.exam,
          questions: []

        },
        selectedQIds: [],
        questions: [],
        totalRecords: 0,
      };
    });
  }
  };

  // onChangeChapter = (chapterId) => {
  //   let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
  //   this.setState(
  //     {
  //       topics: selectedChapter[0].topics,
  //       chapterId,
  //       chapterName: selectedChapter[0].chapterName,
  //       topicId: null,
  //       content: null,

  //     });
  // };

  onChangeMultiChapter = (chapterIds) => {
    let topics = [];
    let topicId = [];



    let allChapters = []
    this.state.chapters.map((s) => {

      allChapters = allChapters.concat(s.items);

    })


    let chapters = [];
    allChapters.map((chapter) => {
      chapterIds.map((chapterId) => {
        if (chapterId == chapter.value) {
          topics.push({
            label: chapter.label,
            chapterId: chapter.chapterId,
            items: chapter.topics.map((item) => { return { value: item.topicId, label: item.topicName } }),
          })
          chapters.push({ chapterId: chapter.chapterId, chapterName: chapter.chapterName })
          return;
        }
      });
    });

    if (this.state.chapterId && this.state.topicId) {
      if (chapterIds.length > this.state.chapterId.length) {
        topicId = this.state.topicId
      } else {
        let removedChapters = [];
        this.state.chapterId.forEach((cId) => {
          if (!chapterIds.includes(cId)) {
            removedChapters.push(cId);
          }
        });

        let topicsInRemovedChapters = [];
        removedChapters.forEach(rmvdCId => {
          this.state.topics.forEach((c) => {
            if (rmvdCId == c.chapterId) {
              topicsInRemovedChapters = topicsInRemovedChapters.concat(c.items.map(c => { return c.value }));
            }
          });
        });

        this.state.topicId.forEach((tId) => {
          if (!topicsInRemovedChapters.includes(tId)) {
            topicId.push(tId);
          }
        })
      }
    }


    topics = topics.filter((c) => c.items.length);


    this.setState((prevState) => {
      return {
        topics: topics,
        chapterId: chapterIds,
        topicId: topicId,
        exam: {
          ...prevState.exam,
          questions: []

        },
        selectedQIds: [],
        questions: [],
        totalRecords: 0,
      };
    });


  };

  onChangeTopic = (topicId) => {
    this.setState((prevState) => {
      return {
        topicId,
        exam: {
          ...prevState.exam,
          questions: []

        },
        selectedQIds: [],

        questions: [],
        totalRecords: 0,
      };
    });
  };


  onChipsChange = (value, fieldName, formFields, formData, formValidations) => {

    let data = JSON.parse(JSON.stringify(formData));
    data[fieldName] = value;
    let fieldInfo = formFields.filter((f) => f.FieldName == fieldName)
    fieldInfo = fieldInfo[0];
    if (formValidations.fields[fieldName].isRequired) {
      if (!data[fieldName].length) {
        formValidations.fields[fieldName] = {
          ...formValidations.fields[fieldName],
          isValid: false,
          errorMsg: fieldInfo.RequiredMsg ? fieldInfo.RequiredMsg : `${fieldInfo.Label} is required.`
        }
        formValidations.isFormValid = false;
      } else {
        formValidations.fields[fieldName] = {
          ...formValidations.fields[fieldName],
          isValid: true,
          errorMsg: ``
        }
      }
    }


    this.setState((prevState) => {
      return {
        exam: { ...data, questions: [] },
        formValidations: formValidations,
        selectedQIds: [],
        questions: [],
        totalRecords: 0,
      };
    });
  };
  // onChangeTopic = (topicId) => {
  //   let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
  //   this.setState(
  //     {
  //       topicId,
  //       topicName: selectedTopic.topicName
  //     });
  // };


  isAdditionalValid = (exam) => {

    let isValid = true

    let { formValidations } = this.state;

    if (exam.questions.length != parseInt(exam.noOfQuestions)) {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please add the required number of questions.', life: 3000 });
      isValid = false;
    }

    if (!(moment(exam.timeFrameStart).isAfter(getServerTime()))) {
      formValidations.fields['timeFrameStart'] = {
        ...formValidations.fields['timeFrameStart'],
        isValid: false,
        errorMsg: `Exam start time must greater than current time.`
      }
      isValid = false;
    }


    if (!(moment(exam.timeFrameStart).isBefore(exam.timeFrameEnd))) {
      formValidations.fields['timeFrameEnd'] = {
        ...formValidations.fields['timeFrameEnd'],
        isValid: false,
        errorMsg: `Exam end time must greater than start time.`
      }
      isValid = false;
    }

    this.setState({
      formValidations
    })

    return isValid;

  }

  onProceed = () => {
    const {
      exam,

    } = this.state;


    const formStatus = isFormValid(quizFields, this.formFields.formValidations, exam);
    if (!formStatus.formValidations.isFormValid) {
      this.setState({
        isSubmitClick: true,
        formValidations: formStatus.formValidations,
      });
    } else if (!this.isAdditionalValid(exam)) {

    } else {

      this.setState({
        currentStep: 2
      })


      // this.toast.show({ severity: 'success', summary: 'valid', detail: '', life: 3000 });

      // console.log('exam', this.state.exam)


    }
  }


  processExamData = (ex) => {
    let exam = _.cloneDeep(ex)
    exam.questions.sort((a, b) => a.psgQId.localeCompare(b.psgQId));

    exam.questions = exam.questions.map((a, index) => {
      a.qNo = index + 1;

      return a;
    });

    return exam;
  }

  onSubmit = () => {

    const {
      exam,
      boardId,
      classId,
      groupId,
      subjectId,
      chapterId,
      topicId,
      boardName,
      className,
      groupName,
      subjectName,
      chapterName,
      topicName,

    } = this.state;

    let examInfo = {
      examType: 'Assessment',
      ...exam,
      boardId,
      classId,
      groupId,
      subjectId,
      chapterId,
      topicId,
      boardName,
      className,
      groupName,
      subjectName,
      chapterName,
      topicName,
      active: true
    };


    // console.log('examInfo', examInfo)
    let examToSave = this.processExamData(examInfo);
    examToSave.subjectId = [this.state.subjectId]
    examInfo.subjectId = [this.state.subjectId]

    if (this.props.editExamId) {
      this.setState({
        isLoading: true
      });



      this.service.put(`${baseUrlAdmin}/examination/${exam.examId}/exam-type/Assessment/update-exam`, examInfo, true).then((data) => {



        if (data && data.res && data.res.success) {

          this.setState({
            isLoading: false
          }, () => {
            this.props.onCreateSuccess(true);
          });



        } else {


          this.setState({
            isLoading: false
          })
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to create exam record', life: 3000 });
        }

        // if (data && data.status) {

        //     if (data?.res?.data) {

        //         this.setState({
        //             totalRecords: data.res.data.totalRecords,
        //             questions: data.res.data.questions
        //         })

        //     }
        // }


      }).catch(e => {


        this.setState({
          isLoading: false
        })
        console.log(e);

      });
    } else {




      this.setState({
        isLoading: true
      });
      this.service.post(`${baseUrlAdmin}/examination/add-exam-test`, examToSave, true).then((data) => {



        if (data && data.res && data.res.success) {

          this.setState({
            isLoading: false
          }, () => {
            this.props.onCreateSuccess();
          });

        } else {

          this.setState({
            isLoading: false
          })
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to create exam record', life: 3000 });
        }

        // if (data && data.status) {

        //     if (data?.res?.data) {

        //         this.setState({
        //             totalRecords: data.res.data.totalRecords,
        //             questions: data.res.data.questions
        //         })

        //     }
        // }


      }).catch(e => {


        this.setState({
          isLoading: false
        })
        console.log(e);

      });
    }
  }

  onFetchQuestions = () => {

    this.setState((prevState) => {
      return {
        exam: {
          ...prevState.exam,
          questions: []
        },
        selectedQIds: [],
        questions: [],
        totalRecords: 0,

      };
    }, this.onSearch);
  }

  onSearch = () => {

    const { topicId,
      lazyParams, globalSearch,
      exam,
      formValidations
    } = this.state;




    if (topicId && topicId != '') {
      let { questionType, difficulty,
        skillType, status, } = exam;

      let filters = {};


      if (topicId) {
        filters = { ...filters, topicId: topicId }
      }





      if (questionType && questionType.length) {
        filters = { ...filters, questionType: questionType }
      }
      if (difficulty && difficulty.length) {
        filters = { ...filters, difficulty: difficulty }
      }
      if (skillType && skillType.length) {
        filters = { ...filters, skillType: skillType }
      }
      if (status && status.length) {
        filters = { ...filters, status: status }
      }

      this.setState((prevState) => {
        return {
          isLoading: true,
          questions: [],
          totalRecords: 0,

        };
      });


      this.service.post(`${baseUrlAdmin}/question/getTopicLinkedQuestions`, filters, true).then((data) => {

        if (data && data.status && data?.res?.questions) {
          if(data?.res?.questions.length){
            this.setState({
              questions: data?.res?.questions,
              isLoading: false
            });
          }else{
            this.setState({isLoading : false})
            this.toast.show({ severity: 'info', summary: '', detail: 'There are no topics for selected subject', life: 3000 })
          }

        } else {
          this.setState({
            isLoading: false
          })
        }


      }).catch(e => {
        this.setState({
          isLoading: false
        })
        console.log(e);

      });


    } else {
      this.setState({
        isLoading: false
      })
      if(topicId?.length === 0){
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'There are no topics for selected subject', life: 3000 });
      }else{
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class, subject', life: 3000 });
      }
    }




  }


  onPage = (event) => {

    this.setState((prevState) => {

      return {
        lazyParams: {
          ...prevState.lazyParams,
          ...event
        }
      };
    }, this.onSearch);
  }



  onSort = (event) => {


    this.setState((prevState) => {

      return {
        lazyParams: {
          ...prevState.lazyParams,
          ...event
        }
      };
    }, this.onSearch);
  }

  onFilter = (event) => {

    event['first'] = 0;
    this.setState((prevState) => {

      return {
        lazyParams: {
          ...prevState.lazyParams,
          filters: event.filters
        }
      };
    }, this.onSearch);
    //  this.setState({ lazyParams: event }, this.getOrganizations);
  }


  onSelectQuestions = (e) => {

    let { exam } = this.state;
    if (exam.noOfQuestions && exam.noOfQuestions != '') {


      if (exam.questions.length < parseInt(exam.noOfQuestions) || e.value.length < exam.questions.length) {
        this.setState((prevState) => {
          return {
            exam: {
              ...prevState.exam,
              questions: e.value
            },
          };
        });
        // if (selectedQuestions && selectedQuestions.length) {
        //     if ((selectedQuestions.length + exam.questions.length) <= parseInt(exam.noOfQuestions)) {
        //         exam.questions = [...exam.questions, ...selectedQuestions];
        //         exam.questions = [... new Set(exam.questions)]
        //         this.setState({
        //             exam: exam,
        //             selectedQuestions: []
        //         });
        //     } else {
        //         let removeCount = (selectedQuestions.length + exam.questions.length) - parseInt(exam.noOfQuestions);
        //         this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Number questions are exceeded, Remove ${removeCount} questions`, life: 3000 });
        //     }
        // }
      } else {
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'you have already selected maximum number of  questions required for the Assessment.', life: 3000 });
      }
    } else {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
    }


    // let { exam } = this.state;
    // if (exam.questions && exam.questions.length) {



    //     let isAlreadyAdded = false;
    //     for (let i = 0; i < exam.questions.length; i++) {
    //         const q = exam.questions[i];
    //         let examF = e.value.find((sq) => sq._id == q._id)
    //         if (examF) {
    //             isAlreadyAdded = true;
    //             break;
    //         }
    //     }
    //     if (!isAlreadyAdded) {
    //         this.setState({ selectedQuestions: e.value })
    //     } else {
    //         this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This question already added', life: 3000 });

    //     }



    // } else {
    //     this.setState({ selectedQuestions: e.value })
    // }




  }
  onSelectQuestionss = (e) => {
    let { exam } = this.state;
    if (exam.questions && exam.questions.length) {



      let isAlreadyAdded = false;
      for (let i = 0; i < exam.questions.length; i++) {
        const q = exam.questions[i];
        let examF = e.value.find((sq) => sq._id == q._id)
        if (examF) {
          isAlreadyAdded = true;
          break;
        }
      }
      if (!isAlreadyAdded) {
        this.setState({ selectedQuestions: e.value })
      } else {
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This question already added', life: 3000 });

      }



    } else {
      this.setState({ selectedQuestions: e.value })
    }




  }

  onSelectQuestion = (question, e, psgQ) => {


    console.log(e)
    let { exam } = this.state;
    if (exam.noOfQuestions && exam.noOfQuestions != '') {
      if (e.checked) {
        //  if (exam.questions.length < parseInt(exam.noOfQuestions)) {

        if (question.questionType == 'SCCT' || question.questionType == 'MCCT' || question.questionType == 'NUM' || question.questionType == 'INT' || question.questionType == 'FB') {
          this.setState((prevState) => {
            return {
              exam: {
                ...prevState.exam,
                questions: [...prevState.exam.questions, { ...question, psgQId: '' }]
              },

              selectedQIds: [...prevState.selectedQIds, question.questionId]
            };
          });
        } else if (question.questionType == 'PQS' || question.questionType == 'PQM') {
          let subQObj = {
            ...question,
            paragraph: psgQ.paragraph,
            paragraphUrl: psgQ.paragraphUrl,
            videoLinking: psgQ.videoLinking,
            psgQId: psgQ.questionId,
            subjectId: psgQ.subjectId,
            subjectName: psgQ.subjectName,
            difficulty: psgQ.difficulty,
            curriculumsOfQuestion: psgQ.curriculumsOfQuestion
          }

          this.setState((prevState) => {
            return {
              exam: {
                ...prevState.exam,
                questions: [...prevState.exam.questions, subQObj]
              },
              selectedQIds: [...prevState.selectedQIds, question.questionId]
            };
          });
        } else if (question.questionType == 'PSGS' || question.questionType == 'PSGM') {

          let examQuestions = _.cloneDeep(this.state.exam.questions);
          let selectedQIds = _.cloneDeep(this.state.selectedQIds);


          question.questions.forEach((subQ) => {
            let subQObj = {
              ...subQ,
              paragraph: question.paragraph,
              paragraphUrl: question.paragraphUrl,
              videoLinking: question.videoLinking,
              psgQId: question.questionId,
              subjectId: question.subjectId,
              subjectName: question.subjectName,
              difficulty: question.difficulty,
              curriculumsOfQuestion: question.curriculumsOfQuestion
            }

            examQuestions.push(subQObj);
            selectedQIds.push(subQ.questionId);
          });

          this.setState((prevState) => {
            return {
              exam: {
                ...prevState.exam,
                questions: examQuestions
              },
              selectedQIds: selectedQIds
            };
          });





        }



        // } else {
        //      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'you have already selected maximum number of  questions required for the QUIZ.', life: 3000 });
        //  }
      } else {

        if (question.questionType == 'PSGS' || question.questionType == 'PSGS') {
          let selectedQuestions = this.state.exam.questions;
          let selectedQIds = this.state.selectedQIds;

          question.questions.forEach((qu) => {
            selectedQuestions = selectedQuestions.filter((q) => q.questionId != qu.questionId);
            selectedQIds = selectedQIds.filter((i) => i != qu.questionId)
          });

          this.setState((prevState) => {
            return {
              exam: {
                ...prevState.exam,
                questions: selectedQuestions
              },

              selectedQIds: selectedQIds
            };
          });

        } else {
          let selectedQuestions = this.state.exam.questions.filter((q) => q.questionId != question.questionId);
          let selectedQIds = this.state.selectedQIds.filter((i) => i != question.questionId)
          this.setState((prevState) => {
            return {
              exam: {
                ...prevState.exam,
                questions: selectedQuestions
              },

              selectedQIds: selectedQIds
            };
          });
        }





      }
    } else {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
    }


    // let { exam } = this.state;
    // if (exam.questions && exam.questions.length) {



    //     let isAlreadyAdded = false;
    //     for (let i = 0; i < exam.questions.length; i++) {
    //         const q = exam.questions[i];
    //         let examF = e.value.find((sq) => sq._id == q._id)
    //         if (examF) {
    //             isAlreadyAdded = true;
    //             break;
    //         }
    //     }
    //     if (!isAlreadyAdded) {
    //         this.setState({ selectedQuestions: e.value })
    //     } else {
    //         this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This question already added', life: 3000 });

    //     }



    // } else {
    //     this.setState({ selectedQuestions: e.value })
    // }




  }


  checkIsChecked = (question) => {
    let qIds = this.state.selectedQIds
    if (question.questionType == 'PSGS' || question.questionType == 'PSGM') {
      return question.questions.some((q) => qIds.includes(q.questionId))
    } else {
      return this.state.selectedQIds.includes(question.questionId);
    }


  }

  onAddQuestions = () => {
    let { selectedQuestions, exam } = this.state;
    if (selectedQuestions && selectedQuestions.length) {
      if (exam.noOfQuestions && exam.noOfQuestions != '') {
        if (exam.questions.length < parseInt(exam.noOfQuestions)) {
          if (selectedQuestions && selectedQuestions.length) {
            if ((selectedQuestions.length + exam.questions.length) <= parseInt(exam.noOfQuestions)) {
              exam.questions = [...exam.questions, ...selectedQuestions];
              exam.questions = [... new Set(exam.questions)]
              this.setState({
                exam: exam,
                selectedQuestions: []
              });
            } else {
              let removeCount = (selectedQuestions.length + exam.questions.length) - parseInt(exam.noOfQuestions);
              this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Number questions are exceeded, Remove ${removeCount} questions`, life: 3000 });
            }
          }
        } else {
          this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'You have already selected maximum number of question required for the Exam', life: 3000 });
        }
      } else {
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
      }
    } else {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select questions to add', life: 3000 });
    }
  }

  removeQuestion = (remQ) => {
    let { exam } = this.state;
    exam.questions = exam.questions.filter((q) => q._id != remQ._id);
    this.setState({
      exam: exam
    });
  }

  onTenureStartChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {
    let data = JSON.parse(JSON.stringify(formData));

    if ((moment(value).isAfter(getServerTime()))) {
      data[fieldName] = new Date(value).toString();
      formValidations.fields[fieldName] = {
        ...formValidations.fields[fieldName],
        isValid: true,
        errorMsg: ``
      }
    } else {
      data[fieldName] = new Date(value).toString();
      formValidations.fields[fieldName] = {
        ...formValidations.fields[fieldName],
        isValid: false,
        errorMsg: `Exam start date and time must be greater than current date and time.`
      }
    }

    if (!(moment(value).isBefore(data.timeFrameEnd))) {
      data.timeFrameEnd = '';
      formValidations.fields['timeFrameEnd'] = {
        ...formValidations.fields['timeFrameEnd'],
        isValid: false,
        errorMsg: `Please select exam end date, start date changed.`
      }
    }

    thisObj.setState({
      [formName]: data,
      [formValidationName]: formValidations
    });
  }

  onTenureEndChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

    let data = JSON.parse(JSON.stringify(formData));
    data[fieldName] = new Date(value).toString();
    if (moment(value).isAfter(data.timeFrameStart)) {

      formValidations.fields[fieldName] = {
        ...formValidations.fields[fieldName],
        isValid: true,
        errorMsg: ``
      }
    } else {
      formValidations.fields[fieldName] = {
        ...formValidations.fields[fieldName],
        isValid: false,
        errorMsg: `Exam End date must be greater than start date.`
      }
    }
    thisObj.setState({
      [formName]: data,
      [formValidationName]: formValidations
    });
  }

  groupedItemTemplate(option) {
    return (
      <div className="p-d-flex p-ai-center country-item">
        {/* <img alt={option.label} src="showcase/demo/images/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${option.code.toLowerCase()}`} /> */}
        <div>{option.label}</div>
      </div>
    );
  }


  onColumnClick = (column) => {
    let columns = this.state.columns.map(c => {
      if (column.id == c.id) {
        c.isVisible = !c.isVisible;
      }
      return c;
    });
    this.setState({
      columns
    });
  }


  isColumnVisible = (id) => {
    return this.state.columns.filter(c => c.id == id)[0].isVisible;
  }
  questionsAvailableR = () => {
    return <>
      <p className='avail-q'> Available Questions:  <span>{this.state.questions.length}</span> </p>
    </>
  }

  render() {
    const { exam, formValidations, questions } = this.state;
    //     console.log(exam, 'exam')
    const header = (
      <div className="table-header ma-tbl-header">
        <div className="ma-tbl-left">
          <h2 className='ma-table-title'>Questions List</h2>
          {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

        </div>

        <div className="p-input-icon-left ma-tbl-filter">
          <ul className="ma-tbl-li">
            {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} >
              <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
              <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
            </Authorizer> */}
            {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
            {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
            <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
            {/* </Authorizer> */}
            {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
              <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
              <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
            </Authorizer> */}
          </ul>
        </div>
        <Menu
          className='table-filter-w'
          model={[
            {
              template: (<div className='table-filter'>
                <h4 className="filter-title">View Columns</h4>
                <ul>
                  {this.state.columns.map((column, inde) => {
                    return <li key={'li' + inde} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                      checked={column.isVisible}
                    ></Checkbox>
                      <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                    </li>
                  })}
                </ul>
              </div>)
            }]
          }
          popup
          ref={el => this.menu = el} />
      </div>
    );
    // console.log('this.props', this.props)
    return (<div className='create-quiz'>
      {this.state.currentStep == 1 && <>

        <div className='ma-p20 paper-s1 '>

          <div>
            <div className='p-grid'>
              <div className="p-col-12 p-md-2">
                <p className='ma-label-s1'>Test Type<span className='ma-required'>*</span></p>
                <Dropdown className='ma-w200' options={testTypeOptions} optionLabel='label' optionValue='value' placeholder="Select Test type" 
                  value={exam.teacherTestType} onChange={(e) => { onDropDownChange(e.target.value, 'teacherTestType', this, quizFields, exam, formValidations, 'exam', 'formValidations')}}
                    />
                  {formValidations && !formValidations.fields['teacherTestType'].isValid && <p className="p-error">{formValidations.fields['teacherTestType'].errorMsg}</p>}
                </div>
            </div>
            <div className="p-grid ma-w100p" >
              <div className="p-col-12 p-md-3">
                <p className='ma-label-s2'>Name of the Test<span className='ma-required'>*</span></p>
                <InputTextB info={FIELDS_INFO.EXAM_NAME} id="exWTT">
                  <InputText value={exam.examName}
                    onChange={(e) => { onTextChange(e.target.value, 'examName', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                    className='p-inputtext-style1 ma-w100p' />
                </InputTextB>
                {formValidations && !formValidations.fields['examName'].isValid && <p className="p-error">{formValidations.fields['examName'].errorMsg}</p>}
              </div>
              <div className="p-col-12 p-md-3">
                <p className='ma-label-s2'>Number of Questions<span className='ma-required'>*</span></p>
                <InputTextB info={FIELDS_INFO.NO_OF_QUESTIONS} id="WexpT">
                  <InputText value={exam.noOfQuestions}
                    onChange={(e) => { onNumberChange(e.target.value, 'noOfQuestions', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                    className='p-inputtext-style1 ma-w100p' />
                </InputTextB>

                {formValidations && !formValidations.fields['noOfQuestions'].isValid && <p className="p-error">{formValidations.fields['noOfQuestions'].errorMsg}</p>}
              </div>
              <div className="p-col-12 p-md-3">
                <p className='ma-label-s2'>Total Time(In minutes)<span className='ma-required'>*</span></p>
                <InputTextB info={FIELDS_INFO.EXAM_TIME} id="dxpT">
                  <InputText value={exam.totalTime}
                    onChange={(e) => { onNumberChange(e.target.value, 'totalTime', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                    className='p-inputtext-style1 ma-w100p' />
                </InputTextB>
                {formValidations && !formValidations.fields['totalTime'].isValid && <p className="p-error">{formValidations.fields['totalTime'].errorMsg}</p>}
              </div>
              <div className="p-col-12 p-md-3">
                <p className='ma-label-s2'>Total Marks<span className='ma-required'>*</span></p>
                <InputTextB info={FIELDS_INFO.TOTAL_MARKS} id="cxWpT">
                  <InputText value={exam.totalMarks}
                    onChange={(e) => { onNumberChange(e.target.value, 'totalMarks', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                    className='p-inputtext-style1 ma-w100p' />
                </InputTextB>
                {formValidations && !formValidations.fields['totalMarks'].isValid && <p className="p-error">{formValidations.fields['totalMarks'].errorMsg}</p>}
              </div>

            </div>
            <div className="p-grid ma-w100p" >
              <div className="p-col-12 p-md-3">
                <p className='ma-label-s2'>Valid From<span className='ma-required'>*</span></p>
                <InputTextB info={FIELDS_INFO.VALID_FROM} id="bxWpT">
                  <Calendar value={exam.timeFrameStart ? new Date(exam.timeFrameStart) : null}
                    showIcon={true}
                    placeholder='Start Date'
                    id='tenurestart'
                    showTime
                    onChange={(e) => this.onTenureStartChange(e.value, 'timeFrameStart', this, quizFields, exam, formValidations, 'exam', 'formValidations')}
                    className='ma-w100p'
                  ></Calendar>
                </InputTextB>
                {formValidations && !formValidations.fields['timeFrameStart'].isValid && <p className="p-error">{formValidations.fields['timeFrameStart'].errorMsg}</p>}
              </div>
              <div className="p-col-12 p-md-3">
                <p className='ma-label-s2'>Valid Upto<span className='ma-required'>*</span></p>
                <InputTextB info={FIELDS_INFO.VALID_TO} id="axWpT">
                  <Calendar value={exam.timeFrameEnd ? new Date(exam.timeFrameEnd) : null}
                    showIcon={true}
                    placeholder='End Date'
                    id='tenurestart'
                    showTime
                    onChange={(e) => this.onTenureEndChange(e.value, 'timeFrameEnd', this, quizFields, exam, formValidations, 'exam', 'formValidations')}
                    className='ma-w100p'
                  ></Calendar>
                </InputTextB>
                {formValidations && !formValidations.fields['timeFrameEnd'].isValid && <p className="p-error">{formValidations.fields['timeFrameEnd'].errorMsg}</p>}
              </div>
            </div>
          </div>
          <div>
            <div>
              <Dropdown value={this.state.boardId}
                className='ma-w200 ma-mt10'
                options={this.props.boards}
                optionLabel="boardName"
                optionValue="boardId"
                onChange={(e) => this.onChangeBoard(e.value)}
                placeholder="Select Board" />
              <span className='ma-required-mark '>*</span>
              <Dropdown value={this.state.classId}
                options={this.state.classes}
                optionLabel='className'
                optionValue='classId'
                className='ma-w200 ma-mt10'
                onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
              <span className='ma-required-mark '>*</span>
              <Dropdown value={this.state.subjectId}
                options={this.state.subjects}
                optionLabel='subjectName'
                optionValue='subjectId'
                className='ma-w200 ma-mt10'
                onChange={(e) => this.onChangeMultiSubject(e.value)} placeholder="Select Curriculum" />
              <span className='ma-required-mark '>*</span>

              {/* <MultiSelect value={this.state.chapterId}
                options={this.state.chapters} //.map((item) => { return { value: item.topicId, label: item.topicName } })
                // optionLabel='topicName'
                // optionValue='topicId'
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                className=' ma-w200 ma-mt10'
                optionGroupTemplate={this.groupedItemTemplate}
                onChange={(e) => this.onChangeMultiChapter(e.value)} placeholder="Select Chapters" />
              <span className='ma-required-mark '>*</span>
              <MultiSelect value={this.state.topicId}
                options={this.state.topics} //.map((item) => { return { value: item.topicId, label: item.topicName } })
                // optionLabel='topicName'
                // optionValue='topicId'
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                className='ma-w200 ma-mt10'
                optionGroupTemplate={this.groupedItemTemplate}
                onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topics" />
              <span className='ma-required-mark '>*</span> */}
            </div>
            <div className='ma-mt20'>
              <MultiSelect value={exam.questionType}
                options={QuestionTypes}
                className='ma-mr20 ma-w200 ma-mt10'
                onChange={(e) => this.onChipsChange(e.value, 'questionType', quizFields, exam, formValidations)}
                placeholder="Select Question Type" />
              <MultiSelect value={exam.difficulty}
                options={Difficulty}
                className='ma-mr20 ma-w200 ma-mt10'
                onChange={(e) => this.onChipsChange(e.value, 'difficulty', quizFields, exam, formValidations)}
                placeholder="Select Difficulty" />
              <MultiSelect value={exam.skillType}
                options={SkillTypes}
                className='ma-mr20 ma-w200 ma-mt10'
                onChange={(e) => this.onChipsChange(e.value, 'skillType', quizFields, exam, formValidations)}
                placeholder="Select Skill Type" />
              <MultiSelect value={exam.status}
                options={Status}
                className='ma-mr20 ma-w200 ma-mt10'
                onChange={(e) => this.onChipsChange(e.value, 'status', quizFields, exam, formValidations)}
                placeholder="Select Status" />
            </div>
          </div>

          <Toolbar className="ma-toolbard  ma-mt20" right={() => <>
            {/* <Button label="Reset" className='p-button-outlined' onClick={this.resetFilter} /> */}
            <Button label='Generate' className='ma-m-lr10'
              onClick={this.onFetchQuestions}
            /></>}></Toolbar>
        </div>



        <div className="card datatable-crud-demo ma-mt20">
          <DataTable ref={(el) => this.dt = el}
            scrollable scrollHeight="500px"
            responsiveLayout="scroll"
            dataKey="_id"
            value={this.state.questions}

            // loading={this.props.isLoading}
            paginator
            // lazy
            // filters={this.state.lazyParams.filters}
            // onPage={this.onPage}
            // onSort={this.onSort}
            // onFilter={this.onFilter}
            // first={this.state.lazyParams.first}
            // last={this.state.totalRecords}
            // rows={this.state.lazyParams.rows}
            // sortField={this.state.lazyParams.sortField}
            // sortOrder={this.state.lazyParams.sortOrder}
            // totalRecords={this.state.totalRecords}
            paginatorLeft={this.questionsAvailableR}
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            selectionMode="checkbox"
            // selection={exam.questions}
            // onSelectionChange={this.onSelectQuestions}

            //paginatorTemplate={paginatorTemplate}
            //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // globalFilter={this.state.globalFilter}
            header={header}>
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
            <Column headerStyle={{ width: '130px' }} body={(rowData) => {
              return (<><Checkbox inputId="cb1" onChange={e => this.onSelectQuestion(rowData, e)}
                checked={this.checkIsChecked(rowData)}
              ></Checkbox>
              </>)
            }} header="Select"
            />
            <Column headerStyle={{ width: '130px' }} field="questionId" header="Question ID" />


            <Column headerClassName='question' header="Question and solution" body={(rowData) => {
              return (<>
                <QuestionInTable isCreateTest={true} selectedQIds={this.state.selectedQIds} onSelectQuestion={this.onSelectQuestion} question={rowData} />
              </>)
            }} />
            {
              this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="questionType" header="Question Type" />}
            {
              this.isColumnVisible('videoLinking') && <Column headerClassName='difficulty' field="videoLinking" header="Video Linked"
                body={(rowData) => {
                  return (<>
                    <InputSwitch
                      checked={(rowData.videoLinking && rowData.videoLinking.length) ? true : false} />

                  </>)
                }}

              />
            }
            {
              this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="difficulty" header="Difficulty"

              />}
            {
              this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="skillType" header="Skill Type"

              />}
            {
              this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="examType" header="Exam Type"
                body={(rowData) => {
                  return (<>
                    {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                  </>)
                }}
              />}
            {
              this.isColumnVisible('source') && <Column headerClassName='difficulty' field="source" header="Source"
              />}
            {
              this.isColumnVisible('status') && <Column headerClassName='difficulty' field="status" header="Status"
              />}
            <Column headerStyle={{ width: '140px' }} header="Edit" body={(rowData) => {
              return (<>
                <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id} ma-mr20`}
                  onClick={() => {
                    this.setState({
                      isShowQuestionPreview: true,
                      questionToPreview: rowData
                    })
                  }}>
                  <PreviewIcon width={20} height={20} />
                  {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                  {/* <i className="pi pi-folder-open"></i>  */}
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />
              </>
              );
            }} ></Column>
            {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
          </DataTable>
          <Toolbar className="ma-toolbard ma-mt20"
            left={() => <>
              {exam.noOfQuestions && exam.noOfQuestions.length && <>
                <p className='selected-q-count'>Selected Questions: <span> {exam.questions.length}/{exam.noOfQuestions}</span></p>
              </>}
            </>

            }

            right={() => <>

              <Button label='Proceed' className='ma-m-lr10'
                onClick={this.onProceed}
              /></>}
          // left={() => <Button label='Add Questions' onClick={this.onAddQuestions} />}

          ></Toolbar>

        </div>

      </>}






      {
        this.state.currentStep == 2 && <>
          <QuizPreview exam={exam} />
          <Toolbar className="ma-toolbard ma-mt20" right={() => <>
            <Button label='Previous' className='ma-m-lr10 p-button-outlined'
              onClick={() => {
                this.setState({
                  currentStep: 1
                })
              }} />
            <Button label={this.props.editExamId ? 'Update' : 'Save'} className='ma-m-lr10'
              onClick={this.onSubmit}

            />

          </>}

          ></Toolbar>
        </>}

      {
        this.state.isShowQuestionPreview && <PreviewQuestion
          question={this.state.questionToPreview}
          onHide={() => {
            this.setState({
              isShowQuestionPreview: false
            })
          }}
        />
      }
      <Toast ref={(el) => this.toast = el} position="bottom-right" />
      {this.state.isLoading && <LoadingComponent />}
    </div >)
  }
}


const mapsStatesToProps = (state) => {
  return {
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
  };
};

export default connect(mapsStatesToProps, {
  getBoardsData,
})(withRouter(CreateAssessment));
