import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toolbar } from 'primereact/toolbar';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LoadingComponent from '../loadingComponent';
import { Toast } from 'primereact/toast';
import { getFormFields, isFieldValid, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../utile/formHelper';
import FileUpload from '../fileUpload';
import { Dialog } from 'primereact/dialog';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { getBoardsData } from './../../store/actions';
import Authentication from './../session';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import _, { flatMap } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { BasicLazyParams,warningDailogInit, getContentSource } from '../../utile';
import { Badge } from 'primereact/badge';
import { TestTypes } from '../examination/constants';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import { DeleteIcon, WarningIcon } from '../svgIcons';
// import { warningDailogInit } from '../../../utile'



const home = { icon: 'pi pi-home' }

var createBatchFieldsJson = require('./createBatchFields.json')


const courseTypeOptions = [
    { label: "Mandatory", value: "mandatory" },
    { label: "Choice Based", value: "choiceBased" }
]

const AddExceptionOptions = [
    { label: "YES", value: "yes" },
    { label: "NO", value: "no" }
]
const FormationRulesOptions = [
    { label: "YES", value: "yes" },
    { label: "NO", value: "no" }
]
const items = [
    { label: 'Analytics' },
    { label: 'Teachers Proffesional Training' },
    { label: "Create/Edit Batch", className: 'ma-active-item' }
];

const cardImageOptions = [
    { label: "Standard", value: "standard" }
]

const courseImageOptions = [
    { label: "Standard", value: "standard" }
]
const NotificationTypes = [
    { name: 'Sms', key: 'S' },
    { name: 'Email', key: 'E' },
    { name: 'App', key: 'A' },

];

const languages = [
    { label: 'English', value: "English" },
    { label: 'Hindi', value: "Hindi" },
    { label: 'Telugu', value: "Telugu" },
    { label: 'Urdhu', value: "Urdhu" },
]
const StatusOptions = [
    { label: 'Scheduled', value: "Scheduled" },
    { label: 'Ongoing', value: "Ongoing" },
    { label: 'Cancelled', value: "Cancelled" },
    { label: 'ReScheduled', value: "ReScheduled" },
    { label: 'Completed', value: "Completed" },
]

const certificateOptions = [
    { label: "Certificate format 1", value: "theme1" },
    { label: "Certificate format 2", value: "theme2" },
    // {label : "Certificate format 3",value : "theme3"},
]
const priorities = [
    { label: "P1", value: 1 },
    { label: "P2", value: 2 },
    { label: "P3", value: 3 },
    { label: "P4", value: 4 },
    { label: "P5", value: 5 },
    { label: "P6", value: 6 },
    { label: "P7", value: 7 },
    { label: "P8", value: 8 },
    { label: "P9", value: 9 },
    { label: "P10", value: 10 },
    { label: "P11", value: 11 },
    { label: "P12", value: 12 },
    { label: "P13", value: 13 },
    { label: "P14", value: 14 },
    { label: "P15", value: 15 },
    { label: "P16", value: 16 },
    { label: "P17", value: 17 },
    { label: "P18", value: 18 },
    { label: "P19", value: 19 },
    { label: "P20", value: 20 },
]

class CreateBatch extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createBatchFieldsJson);
        this.state = {
            createBatchFields: this.props.editBatch?._id ? this.props.editBatch : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            // showResource : false,
            // boardId: null,
            // classId: null,
            // groupId: null,
            // boards: [],
            // classes: [],
            // groups: [],
            // subjects: [],
            // chapters : [],
            // subjectId: null,
            // chapterId: null,
            // topicId: null,
            // content: null,
            // filteredContentDetails : [],
            // globalFilter : '',
            selectedResource: null,
            showSelectPrerequisite: false,
            selectedPrerequisite: {},
            showSelectPreAssessment: false,
            selectedPreAssessment: {},
            showSelectPostAssessment: false,
            selectedPostAssessment: {},
            onDeleteUserDialog:false,
            deleteUserRowData:{},
            testType: "",
            testTypeArr: [],

            // testDialog : false,
            lazyParams: BasicLazyParams,
            // prerequisiteLabel : "",
            // preAssessmentLabel : "",
            // postAssessmentLabel: "",
            // showSearch : false,
            // globalSearch : ""
            fetchUserDialog: false,
            priorityDropDownOptions: priorities,
            trainerDropDownOptions: [],
            venueDropDownOptions: [],
            selectedNotificationsTypes: [],
            //priorityOrder:this.props.editBatch?._id ? this.props.editBatch?.priorityOrder:[],
            roomStrength: "",

            //moment(this.props.editBatch.fromDate).format('DD-MM-YYYY')
            // notificationType:this.props.editBatch?._id ? this.props.editBatch:[],
            savedUsersList: [],
            selectedUsers: this.props.editBatch?._id ? this.props.editBatch?.assignedTeacher : [],
            selectedUser: [],
            AddException: "",
            formationRules: "",
            notifiTypesError: "",
            priorityError: "",
            warningDialog: warningDailogInit,
            totalUsers: '',
            selectedPriorities: [],
            //batchStatus:this.props.editBatch?._id ? this.props.editBatch?.status:"",
            usersList: []


        }
        this.service = new Service()

    }

    componentDidMount() {

        this.getDropDownOptions()
        if (!this.props.boards || !this.props.boards?.length) {
            this.props.getBoardsData();
        }
        if (this.props.editBatch?._id) {
            const notifiTypes = NotificationTypes.filter(each => {
                if (this.props.editBatch?.notificationType.includes(each.name)) {
                    return each
                }
            })

            this.setState({
                savedUsersList: this.props.editBatch?.assignedTeacher.map(each => { return { ...each?.user_info, user_id: each?.user_id, account_id: each?.account_id } }),
                selectedPriorities: this.props.editBatch?.priorityOrder,
                selectedNotificationsTypes: notifiTypes,
                formationRules: this.props.editBatch?.batchFormationRules,
                AddException: this.props.editBatch?.addException,



            })
        }
    }


    getDropDownOptions = () => {

        const lazyParams = this.state.lazyParams

       /*  let url = `${baseUrlAdmin}/batch/priorities?limit=${lazyParams.rows}&page=${lazyParams.page + 1}`;
        // const priorityOrderUrl = "http://192.168.29.154:3000/v1/data/batch/priorities?limit=10&page=1&search=";
        this.service.get(url, true).then((data) => {
            if (data && data.res) {
                let newData1 = data?.res?.data?.map((item) => {
                    return {
                        value: item._id,
                        label: item.name
                    }
                });

                // this.setState({
                //     priorityDropDownOptions: newData1
                // });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        }); */
        let TrainersUrl = `${baseUrlAdmin}/batch/trainers`;
        // const TrainersUrl = "http://192.168.29.154:3000/v1/data/batch/trainers";
        this.service.get(TrainersUrl, true).then((data) => {
            if (data && data.res) {
                let newData2 = data?.res?.data?.map((item) => {
                    return {
                        value: item._id,
                        label: item.name
                    }
                });

                this.setState({
                    trainerDropDownOptions: newData2
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
        let venuesUrl = `${baseUrlAdmin}/venue`;
        // const venuesUrl = "http://192.168.29.154:3000/v1/data/venue/";
        this.service.get(venuesUrl, true).then((data) => {
            if (data && data.res) {
                let NewData3 = data?.res?.data.map((item) => {
                    return {
                        value: item._id,
                        label: item.venueName
                    }
                });



                this.setState({
                    venueDropDownOptions: NewData3
                })

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });




    }

    formGradesDataForEdit = (editCourse) => {
        if (this.props.boards && this.props.boards?.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard = this.props.boards.length > 0 && this.props.boards.find((board) => board.boardId === editCourse.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editCourse.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editCourse.groupId)

                this.setState(
                    {
                        boardId: editCourse.boardId,
                        classId: editCourse.classId,
                        groupId: editCourse.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }

    getCurriculumData = (a) => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {
                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                    if (a && this.props.editCourse?.courseName) {
                        let c = this.props.editCourse?.curriculum
                        this.setState({ subjectId: c })
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        let createBatchFields = this.state.createBatchFields
        createBatchFields["prerequisite"] = ""
        createBatchFields["preAssessment"] = ""
        createBatchFields["postAssessment"] = ""
        this.setState({
            classes: _classes,
            boardId,
            subjectId: "",
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null,
            createBatchFields: createBatchFields,
            prerequisiteLabel: "",
            preAssessmentLabel: "",
            postAssessmentLabel: ""
        });
    };

    onChangeClass = (classId) => {
        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let createBatchFields = this.state.createBatchFields
            createBatchFields["prerequisite"] = ""
            createBatchFields["preAssessment"] = ""
            createBatchFields["postAssessment"] = ""
            this.setState({
                groups: _groups,
                classId,
                subjectId: "",
                groupId: _groups[0].value,
                createBatchFields: createBatchFields,
                prerequisiteLabel: "",
                preAssessmentLabel: "",
                postAssessmentLabel: ""
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);
        let createBatchFields = this.state.createBatchFields
        createBatchFields["prerequisite"] = ""
        createBatchFields["preAssessment"] = ""
        createBatchFields["postAssessment"] = ""
        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,
                filteredContentDetails: [],
                createBatchFields: createBatchFields,
                prerequisiteLabel: "",
                preAssessmentLabel: "",
                postAssessmentLabel: ""
            };
        });
    };

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>{this.props.editBatch?._id ? "Edit" : "Create New"} Batch</h2>

                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onBackFromCreate = () => {
        this.props.onBackFromCreate()
    }

    onClickCreateCourse = () => {
        let body = this.state.createBatchFields
        body.boardId = this.state.boardId
        body.classId = this.state.classId
        body.curriculum = this.state.subjectId
        body.groupId = this.state.groupId

        const formStatus = isFormValid(createBatchFieldsJson, this.formFields.formValidations, body);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (!body.prerequisite?.examName || !body.preAssessment?.examName || !body.postAssessment?.examName) {
            isCompleteFormValid = false
            this.toast.show({ severity: 'error', summary: '', detail: "Please select the assessment tests", life: 3000 });
        }
        if (isCompleteFormValid) {
            if (this.props.editCourse?.courseName) {
                this.setState({ isLoading: true })

                const url = `${baseUrlAdmin}/course`;
                this.service.put(url, body, true).then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onBackFromCreate("Updated"))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            } else {
                this.setState({ isLoading: true })
                const url = `${baseUrlAdmin}/course`;
                this.service.post(url, body, true).then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onBackFromCreate("created"))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            }
        }
    }

    _onProgress = (filesInfo) => {

    }

    _onWatermarkSuccess = (fileName, data) => {
        this.addAttachment('signature', fileName, data);
        return
    }

    addAttachment = (fieldName, fileName, data) => {
        let createBatchFields = {
            ...this.state.createBatchFields,
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,
            }
        }
        let formValidations = isFieldValid(createBatchFieldsJson.find(fm => fm.FieldName == fieldName), this.state.formValidations, createBatchFields);
        this.setState((prevState) => {
            return { createBatchFields, formValidations }
        });
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    _afterFilesUploaded = (files) => {

    }

    onClickCreateBatch = () => {
        let body = this.state.createBatchFields



        let startDate = body?.startDate ?new Date(body?.startDate):null
        let batchStartDate = ""
        if(startDate){  
        let timezoneOffsetForBatchFrom = startDate.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - timezoneOffsetForBatchFrom);
        batchStartDate = startDate.toISOString()
        }

        let endDate=body?.endDate ? new Date(body?.endDate):null
        let batchendDate = ""
        if(endDate){
        let timezoneOffsetForBatchTo = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - timezoneOffsetForBatchTo);
        batchendDate = endDate.toISOString()

        }


        body.assignedTeacher = this.state.savedUsersList.map(each => {
            return {
                "user_id": each.user_id,
                "account_id": each.account_id,
                "isIssueCertificate":true
            }
        })
        body.notificationType = this.state.selectedNotificationsTypes?.map(each => { return each.name })||[]
        body.priorityOrder = this.props.course?.type === "mandatory" ? [1]:this.state.selectedPriorities
        body.courseId = this.props.courseId
        body.status = "Scheduled"
        body.startDate = batchStartDate
        body.endDate = batchendDate






        let isCompleteFormValid = true;

        if ( body.priorityOrder?.length === 0 || body.assignedTeacher.length === 0 ) {
            if (this.state.selectedPriorities.length === 0) {
                this.setState({
                    priorityError: "Select Atleast One Priority",

                })
            }
            if (body.assignedTeacher.length === 0) {
                this.toast.show({ severity: 'error', summary: 'Select AtLeast One User', detail: "Select AtLeast One User", life: 3000 });
            }

            isCompleteFormValid = false;
        }
        const formStatus = isFormValid(createBatchFieldsJson, this.formFields.formValidations, body);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
                const payload = {
                    "_id": this.props.editBatch?._id,
                    "courseId": this.props.courseId,
                    "fromDate": body.startDate,
                    "toDate": body.endDate,
                    "venue": body.venue,
                    "trainer": body.trainer,
                    "batchName": body.batchName,
                    "priorityOrder": body.priorityOrder,
                    "roomStrength": body.roomStrength,
                    "notificationType": body.notificationType,
                    "exception": body.exception,
                    "batchFormationRules": body.batchFormationRules,
                    "status": body.status,
                    "assignedTeacher": body.assignedTeacher
                }
            if (this.props.editBatch?._id) {
                this.setState({ isLoading: true })
                let url = `${baseUrlAdmin}/batch/update`;
                // const url = "http://192.168.29.154:3000/v1/data/batch/update";
                this.service.post(url, payload, true).then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onBackFromCreate("Updated"))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            }
            else {
               
                this.setState({ isLoading: true })
                let url = `${baseUrlAdmin}/batch/create`;
                // const url =" http://192.168.29.154:3000/v1/data/batch/create";
                this.service.post(url, payload, true).then((res) => {

                    if (res?.status || res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onBackFromCreate("created"))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    //this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e?.message, life: 3000 });
                })
            }
        }


    }

    filterHead = () => {
        if (this.state.showSelectPrerequisite) {
            return "Prerequisite"
        } else if (this.state.showSelectPreAssessment) {
            return "Pre Assessment"
        } else if (this.state.showSelectPostAssessment) {
            return "Post Assessment"
        }
    }

    onGlobalSearch = (e) => {
        let assessmentType = ""
        if (this.state.showSelectPrerequisite) {
            assessmentType = "prerequisite"
        }
        if (this.state.showSelectPreAssessment) {
            assessmentType = "preAssessment"
        }
        if (this.state.showSelectPostAssessment) {
            assessmentType = "postAssessment"
        }

        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => this.getAssessments(assessmentType));
    }

    onHideDialog = () => {
        this.setState({ fetchUserDialog: false, usersList: [] })

    }
    onHideDialogforDeleteUser = () => {
        this.setState({ onDeleteUserDialog: false,deleteUserRowData:{}})

    }

    onClickAssessmentselect = (a) => {
        let isHierarchySelected = true
        if (this.state.boardId === "" || this.state.boardId === null || this.state.boardId === undefined) {
            isHierarchySelected = false
        } else if (this.state.classId === "" || this.state.classId === null || this.state.classId === undefined) {
            isHierarchySelected = false
        } else if (this.state.subjectId === "" || this.state.subjectId === null || this.state.subjectId === undefined) {
            isHierarchySelected = false
        }
        if (isHierarchySelected) {
            if (a === "prerequisite") {
                this.setState({
                    showSelectPrerequisite: true,
                    testDialog: true
                }, () => this.getAssessments('prerequisite'))
            }
            if (a === "preAssessment") {
                this.setState({
                    showSelectPreAssessment: true,
                    testDialog: true
                }, () => this.getAssessments('preAssessment'))
            }
            if (a === "postAssessment") {
                this.setState({
                    showSelectPostAssessment: true,
                    testDialog: true
                }, () => this.getAssessments('postAssessment'))
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Please select board,class,curriculum', detail: '', life: 3000 });
        }
    }

    preSelectAssessmentType = (type) => {
        if (this.state.showSelectPrerequisite) {
            let s = this.props.editCourse?.prerequisite
            this.setState({
                selectedResource: s
            })
        }
        if (this.state.showSelectPreAssessment) {
            let s = this.props.editCourse?.preAssessment
            this.setState({
                selectedResource: s
            })
        }
        if (this.state.showSelectPostAssessment) {
            let s = this.props.editCourse?.postAssessment
            this.setState({
                selectedResource: s
            })
        }
    }
    getAssessments = (assessmentType) => {

        this.setState({ isLoading: true })
        const lazyParams = this.state.lazyParams

        const url = `${baseUrlAdmin}/examination/filter?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`;
        this.service.post(url, {
            "examType": "Assessment", "teacherTestType": assessmentType,
            // "boardId" : this.state.boardId,"classId": this.state.classId,
            // "subjectId" : this.state.subjectId
        }, true).then((res) => {
            if (res?.status && res?.res?.success) {
                this.setState({
                    isLoading: false,
                    testTypeArr: res?.res?.data?.exams,
                    totalRecords: res?.res?.data?.totalRecords
                })
                if (this.props.editCourse?.courseName) {
                    this.preSelectAssessmentType(assessmentType)
                }
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.message, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }
    onMultiChange = (value, field) => {

        let formValidations = this.state.formValidations;
        if (value && value.length) {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
        }
        this.setState((prevState) => {
            return {
                createBatchFields: {
                    ...prevState.createBatchFields,
                    [field]: value
                },
                formValidations
            };
        });

    }

    onPage = (event) => {
        let assessmentType = ""
        if (this.state.showSelectPrerequisite) {
            assessmentType = "prerequisite"
        }
        if (this.state.showSelectPreAssessment) {
            assessmentType = "preAssessment"
        }
        if (this.state.showSelectPostAssessment) {
            assessmentType = "postAssessment"
        }

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, () => this.getAssessments(assessmentType));
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsers);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }//, this.getAssessments
        );
    }
    onCategoryChange = (e) => {
        let _selectedCategories = [...this.state.selectedNotificationsTypes];

        if (e.checked)
            _selectedCategories.push(e.value);
        else
            _selectedCategories = _selectedCategories.filter(category => category.key !== e.value.key);

        this.setState({ selectedNotificationsTypes: _selectedCategories })
    };

    fetchUsersList = () => {
        this.setState({
            usersList: [],
            totalUsers: 0,
            selectedUsers: [],
            selectedUser: []
        });
        let url = `${baseUrlAdmin}/batch/users`;

        let data = {
            priority: this.props.course?.type === "mandatory" ?[1]:this.state.selectedPriorities,
            courseId: this.props.courseId,
            exception: this.state.createBatchFields.exception,
            fromDate: this.state.createBatchFields.startDate,
            toDate: this.state.createBatchFields.endDate
        }
   
        this.service.post(url, data, true).then((data) => {
            if (data?.status) {

                const formatData = data?.res?.data?.map(each => {
                    return { ...each.user_info, user_id: each.user_id, account_id: each.account_id }
                })
                

                this.setState({
                    usersList: formatData,
                    totalUsers: data?.res?.totalRecords,
                    
                });

            } else {
                this.setState({
                    isLoading: false,
                  
                })
                this.toast.show({ severity: 'error', summary: data?.errMessage, detail: "Try With Other Priorities", life: 3000 });
            }
        });
        this.setState({ fetchUserDialog: true })
    }

    onSaveUsers = () => {
       
        const updatedData = this.state.savedUsersList.map(each => { return each.user_id })
        const updatedSelectedUsers = this.state.selectedUsers?.filter(each => {
            const itemExists = updatedData.includes(each.user_id);
            if (!itemExists) {
                return each
            }
        })
        this.setState(prevState => ({ savedUsersList: [...prevState.savedUsersList, ...updatedSelectedUsers], fetchUserDialog: false }))
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        let x = selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
        return
    }

    selectOrdeselect = (type, selectedItems, rowData, items, isSelect, selectedItemsField, uniqField) => {

        if (items.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        }
        else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }

        let selected = _.cloneDeep(this.state.selectedUser)
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {

                let data = selected.find(a => a == rowData.user_id);
                if (!data) {
                    selectedIts.push(rowData);
                }
            } else {
                rowData.forEach(i => {
                    selectedIts = selected.filter(a => a != i["user_id"]);
                })
            }
        } else {
            if (items.checked) {
                selectedIts.push(rowData)
            } else {
                selectedIts = selectedIts.filter((i) => i["user_id"] != rowData?.user_id);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts


        })


    }
    onChangeSelectUser = (rowData, e) => {
        if (e.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        }
        else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }
    }

    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    onDeleteSelectedUser = (rowData) => {
            this.setState({onDeleteUserDialog:true,deleteUserRowData:rowData})
      }

    handleDeleteLocation = ()=>{
      
    if (this.props.editBatch?._id && this.state.savedUsersList?.length === 1) {
        let url = `${baseUrlAdmin}/batch/${this.props.courseId}/${this.props.editBatch?._id}/${this.state.deleteUserRowData?.user_id}`;
        this.service.put(url, {},true).then((data) => {
            if (data?.status) {
                
              const updatedUsersList = this.state.savedUsersList.filter((user) => user.user_id !== this.state.deleteUserRowData?.user_id);
           

                this.setState({
                    onDeleteUserDialog: false,
                    savedUsersList: updatedUsersList,
                    deleteUserRowData:{} 
                },()=> this.props.onBackFromCreate("Deleted"));
               
               
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });
            }
        });
    }
    else if (this.props.editBatch?._id) {
        let url = `${baseUrlAdmin}/batch/${this.props.courseId}/${this.props.editBatch?._id}/${this.state.deleteUserRowData?.user_id}`;
        this.service.put(url, {},true).then((data) => {
            if (data?.status) {
                
              const updatedUsersList = this.state.savedUsersList.filter((user) => user.user_id !== this.state.deleteUserRowData?.user_id);
           

                this.setState({
                    onDeleteUserDialog: false,
                    savedUsersList: updatedUsersList,
                    deleteUserRowData:{} 
                });
                this.toast.show({ severity: 'success', summary: 'SuccessFully User Deleted', detail: '', life: 3000 });

               
               
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });
            }
        });
    }
    else{
        const updatedUsersList = this.state.savedUsersList.filter((user) => user.user_id !== this.state.deleteUserRowData?.user_id);
        this.setState({ savedUsersList: updatedUsersList });
        }
}




    render() {
             const { formValidations, createBatchFields } = this.state
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title' style={{ marginLeft: "5px" }}> List Of Users</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={(e) => this.onGlobalSearch(e)} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                    </ul>
                </div>
            </div>
        );
        const header1 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title' style={{ marginLeft: "0px" }}> List Of Selected Users</h2>
                    {/* {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={(e) => this.onGlobalSearch(e)} />} */}
                </div>
                {/* <div className="p-input-icon-left ma-tbl-filter">
                <ul className="ma-tbl-li">
                    <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                    <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                </ul>
            </div> */}
            </div>
        );
        return (
            <>
                <div className='p-grid ma-toolbar'>
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>

                </div>
                <div className='ma-main'>
                    <div className='flex justify-between'>
                        
                        <div className='flex'>
                           
                            <h2 className='ma-active-item'>{this.props.editBatch?._id ? "Edit" : "Create New"} Batch For <span className='ma-active-item' style={{color:"green"}}>{this.props.course?.courseName.charAt(0).toUpperCase()+ this.props.course?.courseName.slice(1)}</span></h2> 
                            {/* <h3 style={{marginLeft:"50px",marginTop:"22px",fontSize:"22px",color:"grey",fontWeight:"600"}}>Course Name: <span style={{fontSize:"20px",color:"black",fontWeight:"600"}}>{this.props.CourseName}</span> </h3> */}
                        </div>
                        {/* <p className='back-arrow ma-mt30 ' style={{ marginBottom: "20px", paddingTop: "5px" }} onClick={this.onBackFromCreate}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                        <div style={{marginTop:"22px"}}>
                                <Button type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBackFromCreate}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
                                
                        </div>
                    </div>

                    <div className='p-card ma-p20'>
                        <div className='p-grid'>
                            <div className='p-col-3 ma-mb20'>
                                <p className='ma-label-s1'>Batch Name<span className='ma-required'>*</span></p>
                                <InputText value={createBatchFields?.batchName} placeholder='Enter Batch Name' className='ma-w80p md:w-20rem p-inputtext-style1'
                                    onChange={(e) => { onTextChange(e.target.value, 'batchName', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['batchName'].isValid && <p className="p-error">{formValidations.fields['batchName'].errorMsg}</p>}
                            </div>
                        </div>
                        <div className="p-grid ">
                            {!(this.props.course?.type === "mandatory") && 
                             <div className="p-col-3 p-md-2 ma-mr20">
                                <p className='ma-label-s1'>Priority Order<span className='ma-required'>*</span></p>
                                <MultiSelect value={this.state.selectedPriorities} onChange={(e) => this.setState({ selectedPriorities: e.value })} options={this.state.priorityDropDownOptions} optionLabel="label"
                                    placeholder="Select Order" optionValue='value' className="ma-w200 md:w-20rem" />
                                {this.state.selectedPriorities.length === 0 ? <p className='p-error'>{this.state.priorityError}</p> : <p></p>}
                            </div>
                            }
                            <div className="p-col-3 p-md-2 ma-mr20">
                                <p className='ma-label-s1'>Trainer<span className='ma-required'>*</span></p>
                                <Dropdown value={createBatchFields?.trainer} className='ma-w200 md:w-20rem' placeholder='Select Trainer' options={this.state.trainerDropDownOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => { onTextChange(e.target.value, 'trainer', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['trainer'].isValid && <p className="p-error">{formValidations.fields['trainer'].errorMsg}</p>}
                            </div>
                            <div className="p-col-3 p-md-2 ma-mr20">
                                <p className='ma-label-s1'>Venue<span className='ma-required'>*</span></p>
                                <Dropdown value={createBatchFields?.venue} className='ma-w200 md:w-20rem' placeholder='Select venue' options={this.state.venueDropDownOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => { onTextChange(e.target.value, 'venue', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['venue'].isValid && <p className="p-error">{formValidations.fields['venue'].errorMsg}</p>}
                            </div>

                            <div className="p-col-3  p-md-2 ma-mr20">
                                <p className='ma-label-s1'>Room Strength<span className='ma-required'>*</span></p>
                                <InputText value={createBatchFields?.roomStrength} className='ma-w200 md:w-20rem p-inputtext-style1'
                                    onChange={(e) => { onTextChange(e.target.value, 'roomStrength', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['roomStrength'].isValid && <p className="p-error">{formValidations.fields['roomStrength'].errorMsg}</p>}
                            </div>
                            {this.props.editBatch?._id && <div className="p-col-4 p-md-2 ma-mr20">
                                <p className='ma-label-s1'>Status<span className='ma-required'>*</span></p>
                                <Dropdown disabled value={createBatchFields?.status} style={{ height: "35px" }} className='ma-w200 md:w-14rem' placeholder='Select' options={StatusOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => { onTextChange(e.target.value, 'status', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['status'].isValid && <p className="p-error">{formValidations.fields['status'].errorMsg}</p>}
                            </div>}
                            <div style={(this.props.course?.type !== "mandatory" && this.props.editBatch?._id)?{marginTop: "22px" }:{}} className={this.props.course?.type === "mandatory"? (this.props.editBatch?._id?"p-col-3 ma-mr20 p-md-2":"p-col-3 ma-mr20 p-md-4"):"p-col-3 ma-mr20 p-md-2"}>
                                <p className='ma-label-s1'>Start Date<span className='ma-required'>*</span></p>
                                <Calendar  className='ma-w200 md:w-14rem' dateFormat="dd-mm-yy" style={{ height: "35px" }} placeholder='Select Date' value={createBatchFields?.startDate ? new Date(createBatchFields.startDate) : null} showIcon
                                    onChange={(e) => { onTextChange(e.target.value, 'startDate', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['startDate'].isValid && <p className="p-error">{formValidations.fields['startDate'].errorMsg}</p>}
                                {/* {createBatchFields?.endDate >= createBatchFields?.startDate ? "":this.toast.show({ severity: 'error', summary: 'Invalid Date', detail:"Batch StartDate should Be Lower or Equal To EndDate", life: 3000 })} */}
               
                           
                            </div>
                            <div style={{marginTop:"22px"}} className='p-col-3 ma-mr20  p-md-2'>
                                <p className='ma-label-s1'>End Date<span className='ma-required'>*</span></p>

                                <Calendar className='ma-w200 md:w-14rem' dateFormat="dd-mm-yy" style={{ height: "35px" }} placeholder='Select Date' value={createBatchFields?.endDate ? new Date(createBatchFields.endDate) : null} showIcon minDate={createBatchFields.startDate ? new Date(createBatchFields.startDate) : null}
                                    onChange={(e) => { onTextChange(e.target.value, 'endDate', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['endDate'].isValid && <p className="p-error">{formValidations.fields['endDate'].errorMsg}</p>}
                              
                            
                            </div>

                            <div style={{ marginTop: "20px" }} className='p-col-4 ma-mr20 p-md-2'>
                                <p className='ma-label-s1  ma-mt5 ma-mr20'>Add Exception<span className='ma-required'>*</span></p>
                                <div>
                                    <Dropdown value={createBatchFields?.exception} style={{ height: "35px" }} className='ma-w200 md:w-14rem' placeholder='Select' options={AddExceptionOptions} optionLabel='label' optionValue='value'
                                        onChange={(e) => { onTextChange(e.target.value, 'exception', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                    />
                                    {formValidations && !formValidations.fields['exception'].isValid && <p className="p-error">{formValidations.fields['exception'].errorMsg}</p>}
                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }} className='p-col-4 p-md-2'>

                                <p className='ma-label-s1  ma-mt5 ma-mr20'>Batch Formation Rules<span className='ma-required'>*</span></p>
                                <div>
                                    <Dropdown value={createBatchFields?.batchFormationRules} style={{ height: "35px" }} className='ma-w200 ma-h20p md:w-14rem' placeholder='Select' options={FormationRulesOptions} optionLabel='label' optionValue='value'
                                        onChange={(e) => { onTextChange(e.target.value, 'batchFormationRules', this, createBatchFieldsJson, createBatchFields, formValidations, 'createBatchFields', 'formValidations') }}
                                    />
                                    {formValidations && !formValidations.fields['batchFormationRules'].isValid && <p className="p-error">{formValidations.fields['batchFormationRules'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="p-col-6  p-md-4" style={{ marginTop: "57px" }}>
                                <div className='flex'>
                                    <p className='ma-label-s1 w-auto ma-mr20' style={{marginTop:"-2px"}}>Add Notification Type :</p>
                                    <div className="flex gap-3">
                                        {NotificationTypes.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center ma-mr10">
                                                    {/* <Checkbox inputId={category.key} name="category" value={category} onChange={this.onCategoryChange} checked={this.state.selectedNotificationsTypes.some((item) => item.key === category.key)} /> */}
                                                    <i style={{ float: 'left',marginTop:"4px",fontSize:"13px",color:"red",fontWeight:'600'}} className={`pi pi-times ma-info-i toolId`} data-pr-tooltip="" data-pr-position="bottom"  ></i>
                                                    <label htmlFor={category.key}>
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {this.state.selectedNotificationsTypes.length === 0 ? <p className='p-error' >{this.state.notifiTypesError}</p> : <p></p>}

                            </div>



                        </div>


                        <div className='p-col-12 p-md-12 flex justify-end ma-mb10'>
                            <Button label="Fetch Users"
                                disabled={ (!(this.props.course?.type === "mandatory")&&this.state.selectedPriorities.length === 0 )||
                                    !createBatchFields?.exception || !createBatchFields.startDate || !createBatchFields.endDate ||
                                    !createBatchFields.batchName || !createBatchFields.trainer || !createBatchFields.venue ||
                                    !createBatchFields.roomStrength || !createBatchFields.batchFormationRules}
                                type="button" onClick={this.fetchUsersList} />

                        </div>

                    </div>
                </div>

                <div className='flex-center' style={{ marginRight: '70px', marginLeft: "70px" }}>
                    <DataTable ref={(el) => this.dt = el}
                        lazy className='ma-mt30'
                        value={this.state.savedUsersList}

                        dataKey="_id"
                        paginator
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.savedUsersList.length}

                        // selectionMode="checkbox"
                        // selection={this.state.selectedCourses}
                        // onSelectionChange={this.onSelectCourse}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        globalFilter={this.state.globalFilter2}
                        header={header1}
                        paginatorLeft={() => {
                            return <>
                                <p className='avail-q'> selected users:  <span>{this.state.savedUsersList && this.state.savedUsersList.length}</span> </p>
                            </>
                        }}
                    >

                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="username" header="User Name" />
                        <Column field="given_name" header="Given Name" />
                        <Column field="designation" header="Designation" />
                        {/* <Column  field="school" header="School" /> */}


                        <Column  field="distname" header="District" />
                        <Column  field="blkname" header="Block Name" />
                        <Column  field="subjects" header="Subjects" style={{ width: "15rem" }} body={(rowData) => {
                            return <>{(rowData.subjects?.length > 0) && rowData.subjects ? rowData?.subjects.toString() : ""}</>
                        }} />
                       
                        <Column header='Actions' body={(rowData) => {
                            return (
                                <>

                                    <span data-pr-tooltip="Delete 33 Question" data-pr-position="top" className={`ma-ml10 ma-pointer`} onClick={() => this.onDeleteSelectedUser(rowData)}>
                                        <DeleteIcon height={20} width={20} />
                                    </span>



                                </>
                            )
                        }} />

                    </DataTable>
                </div>


                <div className=' flex justify-end ma-mb10'>
                    <Button style={{ margin: 30, marginRight: 80 }} label={`${this.props.editBatch?._id ? "Update" : "Create"} Batch`} onClick={this.onClickCreateBatch} />
                </div>

                <Dialog draggable={false} closeOnEscape={true} className='ma-alert-box' blockScroll={true} dismissableMask={false} closable={true} style={{ minHeight: '90vh', maxWidth: '80vw' }}
                    visible={this.state.fetchUserDialog} header={() => this.filterHead()}
                    onHide={() => this.onHideDialog()}
                    footer={() => {
                        return (
                            <>
                                <Button label='Add Users' onClick={this.onSaveUsers} />
                            </>
                        )
                    }}
                >
                    <div>
                        <div className="card datatable-crud-demo ma-mt30">
                            <DataTable
                                ref={(el) => this.dt = el}
                                id="contentt"
                                value={this.state.usersList}
                                dataKey="_id"
                                header={header}
                                // responsiveLayout="scroll"
                                scrollable
                                scrollDirection="both"
                                scrollWidth="flex"
                                paginator
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalUsers}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                selectionMode="checkbox"
                                selection={this.state.selectedResource}
                                onSelectionChange={(e) => this.setState({ selectedResource: e.value })}
                                globalFilter={this.state.globalFilter}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tests"
                            >
                                <Column headerStyle={{ width: '4em' }} field='user_id' header={"Select"}
                                    body={(rowData) => {
                                        return <Checkbox checked={this.state.selectedUser.includes(rowData.user_id)}
                                            onChange={(e) => this.selectOrdeselect('single', this.state.selectedUsers, rowData, e, true, 'selectedUsers', 'user_id')}
                                        ></Checkbox>
                                    }}>
                                </Column>

                                <Column field="username" header="User Name" />
                                <Column field="given_name" header="Given Name" />
                                <Column field="designation" header="Designation" />
                                {/* <Column sortable field="school" header="School" /> */}


                                <Column field="distname" header="District" />
                                <Column field="blkname" header="Block Name" />
                                <Column field="subjects" header="Subjects" style={{ width: "15rem" }} body={(rowData) => {
                                    return <>{(rowData?.subjects !== undefined && rowData.subjects.length > 0) ? rowData?.subjects.toString() : ""}</>
                                }} />
                                


                            </DataTable>
                        </div>
                        <div className='flex justify-end ma-mt30'>
                            {/* <Button label='Proceed' onClick={this.onClickProceed} className='ma-ml20'/> */}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                header={<></>}
                draggable={false}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                blockScroll={true}
                visible={this.state.onDeleteUserDialog}  style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ onDeleteUserDialog:false,deleteUserRowData:{} }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.handleDeleteLocation} />
                    </div>)
                }}
                onHide={() => this.onHideDialogforDeleteUser()}>
                <div>
                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>Are You Sure</p>
                {this.state.savedUsersList?.length === 1 ?
                <>
                <div >
                 <p style={{textAlign:"center"}}>Batch: <span style={{color:"red"}}> {this.props.editBatch?.batchName}</span></p>
                 <p style={{textAlign:"center"}}>User : <span style={{color:"red"}}> {this.state.deleteUserRowData?.username}</span></p>
                </div>
                 <h5 className='ma-alert-msg'>You are deleting the Above Batch and User since it is the last user in the batch </h5>
                 </>
                :
                <h5 className='ma-alert-msg'>You are deleting the <span style={{color:"red"}}>{this.state.deleteUserRowData?.username}</span></h5>
                }
                
            </Dialog>

                
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(CreateBatch)))