import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { baseUrlAdmin } from '../../store/apiConstants';
import format1 from '../../images/course _certificate.png'
import format2 from "../../images/certifcate2.png"
import Service from '../../services';
import "./styles.scss"

class Certificatedownload extends Component {
  constructor(props) {
    super(props);
    const { courseId, userId } = this.props.match.params;
    this.state = {
      userId,
      courseId,
      obj: {}
    }
    this.service = new Service()
  }

  componentDidMount() {
    if (this.state.userId && this.state.courseId) {
      let url = `${baseUrlAdmin}/course/certificate/${this.state.userId}/${this.state.courseId}`;
      this.service.get(url, true).then((res) => {
        if (res?.status && res?.res?.status) {
          this.setState({
            obj: res.res.data
          });
        }
      }).catch(e => {
      })
    }
  }
  

  render() {
    const { obj } = this.state
    debugger
    return (
      <>
        {obj.format === "theme1" && obj.given_name && obj.courseName && obj.courseCompletedAt &&
          <div>
            <img src={`${format1}`} width={"100%"} style={{ marginBottom: "-500px" }} />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <img src={`/images/TPDA-removebg-preview.png`} width={'300'} className='ma-mt30' /> */}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "185px" }}>
              <h1 >{obj?.given_name}</h1>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "" }}>
              <h2  > {obj?.courseName}</h2>
            </div>
            <div style={{ display: "flex", marginTop: (obj?.given_name && obj?.courseName) ? "70px" : "110px" }}>
              <div style={{ marginLeft: "190px", marginTop: "", textAlign:'center' }}>
                <h2>{obj?.courseCompletedAt}</h2>
              </div>
              <div style={{ marginLeft: "25px" }}>
                {/* <img src={`/images/tpdalogo-removebg-preview.png`} className='ma-ml20' width={"100"} /> */}
              </div>
              <div style={{ marginLeft: "100px", marginTop: "-30px" }}>
                {obj?.signature?.storageLocation && <img src={`${obj?.signature?.storageLocation}`} width={'100'} />}
              </div>
            </div>
          </div>
        }



        {obj.format === "theme2" && obj.given_name && obj.courseName && obj.courseCompletedAt &&
          <div>
            <img src={`${format2}`} width={"100%"} style={{ marginBottom: "-500px" }} />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <img src={`/images/TPDA-removebg-preview.png`} width={'300'} style={{ marginTop: "50px" }} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "30px" }}>
              <h1 style={{ fontFamily: "cursive", fontSize: "35px", color: "#ddac00" }}>{obj?.given_name}</h1>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "-30px" }}>
              <h2>Course : {obj?.courseName}</h2>
            </div>
            <div style={{ display: "flex", marginTop: (obj?.given_name && obj?.courseName) ? "70px" : "110px" }}>
              <div style={{ marginLeft: "180px", marginTop: "15px" }}>
                <h2>{obj?.courseCompletedAt}</h2>
              </div>
              <div style={{ marginLeft: "25px" }}>
                <img src={`/images/tpdalogo-removebg-preview.png`} className='ma-ml20' width={"100"} />
              </div>
              <div style={{ marginLeft: "30px" }}>
                {obj?.signature?.storageLocation && <img src={`${obj?.signature?.storageLocation}`} width={'100'} />}
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default Certificatedownload