import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';



export default class UsageReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,


        }



        this.service = new Service();


        // const userAnalyticsBySubjectT = userAnalyticsBySubject.map(item => {
        //     if (item.mp4 > 0 || item.pdf > 0 || item.zip > 0 || item.quiz > 0) {
        //         isGraphDataAvailable = true;
        //     }
        //     return { Videos: item.mp4, Pdf: item.pdf, Ppt: item.zip, Quiz: item.quiz, quizCount: item.quizCount, subjectId: item.subjectId, subjectName: item.subjectName };
        // });
    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/analytics/resourceUsageReport`;
        this.service.get(url, true).then(res => {


            if (res && res.status) {
                this.setState({
                    ...res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        this.getReport();
    }


    render() {
        const { graphData, stats, inlineLoading } = this.state;

     //   console.log(graphData, 'analyticsData')

        return (
            <div className='ma-relative'>
                <div className='resource-usage-report'>
                    <h3 className="resource-title"> Usage Usage Report</h3>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-11'>

                            <div className='resource-usage-graph'>
                                {
                                    graphData && graphData.length ? <ResponsiveBar
                                        data={graphData}
                                        keys={['Ofline', 'Selfpaced']}
                                        indexBy="key"
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        padding={0.5}
                                        innerPadding={4}
                                        groupMode="grouped"
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        colors={['#3632CE', '#FF761A']}
                                        borderRadius={3}
                                        borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            //   legend: 'subjectName',
                                            legendPosition: 'left',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Time in Hours(HH)',
                                            // legend: 'food',
                                            legendPosition: 'middle',
                                            legendOffset: -40
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        enableGridY={false}

                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                        legends={[
                                            {
                                                dataFrom: 'keys',
                                                anchor: 'bottom',
                                                direction: 'row',
                                                justify: false,
                                                translateX: 0,
                                                translateY: 50,
                                                itemsSpacing: 2,
                                                itemWidth: 100,
                                                itemHeight: 20,
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 0.85,
                                                symbolSize: 18,
                                                symbolShape: 'circle',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                        enableLabel={false}
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                    /> : <></>
                                }

                            </div>
                        </div>
                        <div className='p-col-12 p-md-1'>
                            {
                                graphData && graphData.length ? <div className='usage-trend'>
                                    <p className='trend-title'>Usage Trend</p>
                                    <img src='./new-assets/Path_3270.png' style={{ height: '20px', marginLeft:'20px' }} />
                                    <p className='trend-mark'>+ {stats}%</p>
                                </div> : <></>
                            }

                        </div>

                    </div>

                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}
