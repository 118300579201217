import * as ActionTypes from '../actionTypes';
import { userPool } from './awsProfile';

const dispatchSignOut = () => ({
  type: ActionTypes.USER_SIGN_OUT
});

const dispatchClearAllData = () => ({
  type: ActionTypes.CLEAR_ALL_DATA
});

const dispatchUnAuthUser = () => ({
  type: ActionTypes.UN_AUTH_USER
});

export const userSignOut = () => (dispatch) => {
  dispatch(dispatchSignOut());
  signOutUser(dispatch)
    .then((res) => {
      dispatch(dispatchClearAllData());
    })
    .catch((err) => { });
};

function signOutUser(dispatch) {
  const p = new Promise((res, rej) => {
    if (process.env.REACT_APP_LOGIN_FROM_COGNITO === 'true') {
      const cognitoUser = userPool.getCurrentUser();
      cognitoUser.signOut();
    }
    localStorage.clear();
    dispatch(dispatchUnAuthUser());
    window.location.reload();
  });
  return p;
}
