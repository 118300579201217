import * as ActionTypes from '../actionTypes';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoIdentityCredentials,
  WebIdentityCredentials
} from 'amazon-cognito-identity-js';
import { userPool, USERPOOL_ID } from './awsProfile';
import { getAdminUserPermission } from './loggedInUserPermissionActions';
import { getOrgData } from './sp_orgActions';
import AWS from 'aws-sdk';
import Service from '../../services';
import { baseUrlAdmin } from '../apiConstants';
import { cloneDeep } from 'lodash';
import { getUserData } from './userDataActions';
import CryptoJS from 'crypto-js';
import { EncryptionKey, ORGCODE } from '../../constants';

const dispatchRequestSignIn = (data) => ({
  type: ActionTypes.REQUEST_SIGN_IN,
  data
});

const dispatchReceivedSignIn = (data) => ({
  type: ActionTypes.RECEIVED_SIGN_IN,
  data
});

const dispatchErrorSignIn = (err) => ({
  type: ActionTypes.ERROR_SIGN_IN,
  err
});

const dispatchClearSignInData = () => ({
  type: ActionTypes.CLEAR_SIGN_IN_DATA
});

const dispatchAuthenticateUser = (userData) => ({
  type: ActionTypes.AUTH_USER,
  userData
});

export const authUser = (userData) => (dispatch) => dispatch(dispatchAuthenticateUser(userData));

export const clearSignInData = () => dispatchClearSignInData();

export const userSignIn = (mobileNo, password) => (dispatch) => {
  if (process.env.REACT_APP_LOGIN_FROM_COGNITO === 'true') {
    console.log(mobileNo, password, "mobileNo, password")
    //userSignFromCognito(mobileNo, password)
    dispatch(dispatchRequestSignIn({ mobileNo, password }));
    const p = new Promise((res, rej) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: mobileNo,
        Password: password
      });
      const userData = {
        Username: mobileNo,
        Pool: userPool
      };
      const cognitoUser = new CognitoUser(userData);
      authenticateUser(cognitoUser, authenticationDetails)
        .then(() => {

          dispatch(getOrgData())
          dispatch(getAdminUserPermission(cognitoUser.getUsername()));
          return buildUserObject(cognitoUser);
        })
        .then((userProfileObject) => {
          res(userProfileObject);
          dispatch(dispatchAuthenticateUser(userProfileObject));
          dispatch(dispatchReceivedSignIn(userProfileObject));
        })
        .catch((err) => {
          dispatch(dispatchErrorSignIn(err));
          rej(err);
        });
    });
    return p;

    function authenticateUser(cognitoUser, authenticationDetails) {
      const p = new Promise((res, rej) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {

            localStorage.setItem('user_token', result.accessToken.jwtToken);
            localStorage.setItem('Bearer', result.idToken.jwtToken);
            const loginsObj = {
              [USERPOOL_ID]: result.getIdToken().getJwtToken()
            };

            res();
          },
          onFailure: function (err) {
            rej(err);
          }
        });
      });
      return p;
    }
  }
  else {
    const url = `${baseUrlAdmin}/teacherauth/login`;

    const encryptedUsername = CryptoJS.AES.encrypt(mobileNo, EncryptionKey).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, EncryptionKey).toString();

    let payload = {
      username: encryptedUsername,
      password: encryptedPassword,
    };

    dispatch(dispatchRequestSignIn({ mobileNo, password }));
    const service = new Service();
    service.post(url, payload, false).then(res => {
      if (res && res.status) {
        localStorage.setItem('user_token', res?.res?.data?.token);
        localStorage.setItem('Bearer', res?.res?.data?.token);
        let userObj = cloneDeep(res?.res?.data?.user?.user_info)
        userObj.role = userObj?.roles[0]
        dispatch(getAdminUserPermission(userObj?.username));
        dispatch(dispatchAuthenticateUser(userObj));
        dispatch(dispatchReceivedSignIn(userObj));
        if (userObj && userObj.name) {
          localStorage.setItem('name', userObj.name);
        }
        if (userObj && userObj.phone_number) {
          localStorage.setItem('phone_number', userObj.phone_number);
        }
        if (userObj && userObj.boardId) {
          localStorage.setItem('boardId', userObj.boardId);
        }
        if (userObj && userObj.isAdmin) {
          localStorage.setItem('isAdmin', userObj.isAdmin);
        }
        if (userObj && userObj.picture) {
          localStorage.setItem('picture', userObj.picture);
        }
        if (userObj && userObj.role) {
          localStorage.setItem('currentUserRole', userObj.role);
        }
        if (userObj && userObj.email) {
          localStorage.setItem('emailId', userObj.email);
        }
        if (res?.res?.data?.user && res?.res?.data?.user?.orgId) {
          localStorage.setItem('orgId', res?.res?.data?.user?.orgId);
        }
        if (res?.res?.data?.user && res?.res?.data?.user?.user_id) {
          localStorage.setItem('userId', res?.res?.data?.user?.user_id);
        }
        if (userObj && userObj?.username) {
          localStorage.setItem('username', userObj?.username);
        }
        if (userObj && userObj?.userType) {
          localStorage.setItem('userType', userObj?.userType);
        }
        if (userObj && userObj?.type) {
          localStorage.setItem('type', userObj?.type);
        }
      } else {
        debugger
        console.log(res);
        dispatch(dispatchErrorSignIn({ message: res.messsage }));
      }
    }).catch(e => {
      console.log(e);
      dispatch(dispatchErrorSignIn({ message: "Login error message" }));
    })
  }


}


// export const userSignIn1 = (mobileNo, password) =>  (dispatch) => {
//   dispatch(dispatchRequestSignIn({ mobileNo, password }));
//   const p =  new Promise((res, rej) => {
//     const authenticationDetails = new AuthenticationDetails({
//       Username: mobileNo,
//       Password: password
//     });
//     const userData = {
//       Username: mobileNo,
//       Pool: userPool
//     };
//     const cognitoUser = new CognitoUser(userData);
//     authenticateUser(cognitoUser, authenticationDetails)
//       .then(() => {

//         dispatch(getOrgData())
//         dispatch(getAdminUserPermission(cognitoUser.getUsername()));
//         return buildUserObject(cognitoUser);
//       })
//       .then((userProfileObject) => {
//         res(userProfileObject);
//         dispatch(dispatchAuthenticateUser(userProfileObject));
//         dispatch(dispatchReceivedSignIn(userProfileObject));
//       })
//       .catch((err) => {
//         dispatch(dispatchErrorSignIn(err));
//         rej(err);
//       });
//   });
//   return p;

//   function authenticateUser(cognitoUser, authenticationDetails) {
//     const p = new Promise((res, rej) => {
//       cognitoUser.authenticateUser(authenticationDetails, {
//         onSuccess: function (result) {

//           localStorage.setItem('user_token', result.accessToken.jwtToken);
//           localStorage.setItem('Bearer', result.idToken.jwtToken);
//           const loginsObj = {
//             [USERPOOL_ID]: result.getIdToken().getJwtToken()
//           };

//           res();
//         },
//         onFailure: function (err) {
//           rej(err);
//         }
//       });
//     });
//     return p;
//   }
// };

function buildUserObject(cognitoUser) {
  const p = new Promise((res, rej) => {
    cognitoUser.getUserAttributes(function (err, result) {
      if (err) {
        rej(err);
      }
      let userProfileObject = {};
      if (!result) return;
      for (let i = 0; i < result.length; i++) {
        if (result[i].getName().indexOf('custom:') >= 0) {
          let name = result[i].getName().slice(7, result[i].getName().length);
          userProfileObject[name] = result[i].getValue();
        } else {
          userProfileObject[result[i].getName()] = result[i].getValue();
        }
      }
      if (userProfileObject && userProfileObject.name) {
        localStorage.setItem('name', userProfileObject.name);
      }
      if (userProfileObject && userProfileObject.phone_number) {
        localStorage.setItem('phone_number', userProfileObject.phone_number);
      }
      if (userProfileObject && userProfileObject.boardId) {
        localStorage.setItem('boardId', userProfileObject.boardId);
      }
      if (userProfileObject && userProfileObject.boardId) {
        localStorage.setItem('isAdmin', userProfileObject.isAdmin);
      }
      if (userProfileObject && userProfileObject.picture) {
        localStorage.setItem('picture', userProfileObject.picture);
      }
      if (userProfileObject && userProfileObject.role) {
        localStorage.setItem('currentUserRole', userProfileObject.role);
      }
      if (userProfileObject && userProfileObject.email) {
        localStorage.setItem('emailId', userProfileObject.email);
      }
      if (userProfileObject && userProfileObject.orgId) {
        localStorage.setItem('orgId', userProfileObject.orgId);
      }
      if (userProfileObject && userProfileObject.sub) {
        localStorage.setItem('userId', userProfileObject.sub);
      }
      res(userProfileObject);
    });
    if (cognitoUser && cognitoUser.username) {
      localStorage.setItem('username', cognitoUser.username);
    }
  });
  return p;
}

export const retrieveUserFromLocalStorage = async (dispatch) => {
  const p = await new Promise((res, rej) => {
    if (process.env.REACT_APP_LOGIN_FROM_COGNITO === 'true') {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            rej(err);
          }

          if (session && session.isValid()) {
            localStorage.setItem('user_token', session.getAccessToken().getJwtToken());
            localStorage.setItem('Bearer', session.getIdToken().getJwtToken());
            const loginsObj = {
              [USERPOOL_ID]: session.getIdToken().getJwtToken()
            };
            dispatch(getOrgData())
            dispatch(getAdminUserPermission(cognitoUser.getUsername()));
            res(buildUserObject(cognitoUser));
            return;
          }
          rej('failed to retrive user from localStorage');
        });
      } else {
        rej('Failed to retrieve user from localStorage');
      }
    }
    else {
      let token = localStorage.getItem('user_token');
      let Bearer = localStorage.getItem('Bearer');
      let uName = localStorage.getItem('username');
      if (token.length) {
        localStorage.setItem('user_token', token);
        localStorage.setItem('Bearer', Bearer);
        dispatch(getUserData())
        dispatch(getOrgData())
        dispatch(getAdminUserPermission(uName));
        return;
      }
      rej('Failed to retrieve user from localStorage');
    }
  });
  return p;
}
