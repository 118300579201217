import React, { Component } from 'react';
import './App.scss'
import './app.css'
import './fonts/fonts.css';
import "react-datepicker/dist/react-datepicker.css";
import 'primeflex/primeflex.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import AdminManagement from './components/AdminManagement/index';
// import BulkUpload from './components/bulkUpload';
// import ContactUsInfo from './components/ConatctUsInfo';

// import CurriculumMaster from './components/curriculumMaster';
// import ChapterTable from './components/curriculumMaster/chapterTable/index.js';
// import TopicTable from './components/curriculumMaster/topicTable/index.js';

// import ExamResult from './components/examResult';
// import OrganizationMaster from './components/organizationMaster';
// import OrganizationClassTable from './components/organizationMaster/classListTable';
// import OrganizationGroupTable from './components/organizationMaster/groupListTable';
// import PackageMaster from './components/packageMaster';
// import AddQuestions from './components/QuestioBank/index';
// import ReviewQuestions from './components/QuestioBank/reviewQuestions';
// import videoLink from './components/QuestioBank/videoLink/index';
// import TestCreator from './components/testCreator';
// import TestListComponent from './components/testList/testList';
// import TestResultComponent from './components/testResults/index.js';
// import Transactions from './components/transactionsScreen';
import UserManagement from './components/userManagement';
import AskADoubt from './components/askADoubt';
import BranchMaster from './components/branchMaster';
import ContentMaster from './components/contentMaster';
// import BranchCourseTable from './components/branchMaster/courseListTable';
// import BranchSectionTable from './components/branchMaster/sectionListTable';
// import UserAnalytics from './components/userAnalytics';
// import PerformanceAnalytics from './components/userAnalytics/performanceAnalytics';
// import AllProcesses from './components/userAnalytics/AllProcesses';
// import './App.css';
// import './fonts/fonts.css';
// import SnackBarComponent from './components/snackBarComponent';
// import { removeSnackBar } from './store/actions';
import { connect } from 'react-redux';
import OrganizationMaster from './components/orgnizationMaster';
import Navbar from './components/navbar';
import Dashboard from './components/dashboard';
import CurriculumMaster from './components/curriculumMaster';
import AssignContent from './components/curriculumMaster/assignContent';
import AdminManagement from './components/adminManagement';
import BulkUploadA from './components/adminManagement/bulkUpload';
import CreateClass from './components/virtualClass/createClass';
import VirtualClass from './components/virtualClass';
import DiscussionBoard from './components/discussionBoard';
import LoginPage from './components/login';
import Practise from './components/practise';
import PDF from './PDF';
import BoardsMaster from './components/boardsMaster';
import CalendarMaster from './components/calendarMaster'
import BulkUpload from './components/userManagement/bulkUpload';
import PaperDownload from './components/paperDownload';
import BulkUploadc from './components/contentMaster/bulkUpload';
import QuestionBank from './components/questionBank';
import Examinations from './components/examination';
import UsageAnalytics from './components/analytics/usageAnalytics/index';
import AssignQuestions from './components/assignQuestions';
import ExamAnalytics from './components/analytics/examAnalytics';
import { getOrgData, getOrgDataByCode } from './store/actions';
import BranchWiseAnalytics from './components/analytics/usageAnalytics/branchWiseAnalytics';
import MockTestResult from './components/mockTestResult';
import LoadingComponent from './components/loadingComponent';
import BulkUploadYearlyPlanner from './components/calendarMaster/bulkUpload';
import TimeTable from './components/timeTable';
import Attendance from './components/attendance';
import BulkAttendance from './components/attendance/bulkAttendance';
import AttendaceReport from './components/attendance/attendaceReport';
import Notification from './components/notification';
import Service from './services';
import { baseUrlAdmin } from './store/apiConstants';
import BehaviorAnalytics from './components/analytics/examAnalytics/behaviorAnalytics';
import WidgetManger from './components/widgetManager';
import TeacherAnalytics from './components/analytics/teacherAnalytics';
import ClassRoom from './components/classroom';
import RegisterOrganization from './components/registerOrganization';
import EnquiryFrom from './components/enqiryForm';
import FeeTypes from './components/organizationManage/feeTypes';
import FeePlan from './components/organizationManage/feePlan';
import AdmissionType from './components/admissionsOrganization/admissionType';
import AcadamicYear from './components/admissionsOrganization/academicYears';
import ApplicationFee from './components/applicationFee';
import Course from './components/course';
import Finance from './components/finance';
import VehicleManagement from './components/transport/vehicleManagement';
import TransportStaffManagement from './components/transport/transportStaffManagement';
import RouteManagement from './components/transport/routeManagement';
import TransportAttendance from './components/transport/attendance';
import TransportAttendanceReport from './components/transport/attendanceReport';
import BatchManagement from './components/batchManagement/batchManagement';
import BusFeePlans from './components/finance/busFeePlans';
import QrCodeManager from './components/QRCodeManager';
import FeeDaySheet from './components/organizationManage/feeDaySheet';
import TicketManagement from './components/ticketManagement';
import ApprovalManagement from './components/approvalManagement';
import CourseManagement from './components/teachProffTraining/courseManagement/courseManagement';
import Certificatedownload from './components/userManagement/Certificatedownload';
import { TrainerManagement } from './components/trainerManagement/trainerManagement';
import VenueManagement from './components/venueManagement'
import CourseSettings from './components/courseSettings';
// function getRandomColor(char) {
//   let colors = { a: '#aaaaaa', b: "#aa4433" }
//   return colors[char] ? colors[char] : '#ffffff';
// }
// <p style={{ backgroundColor: `${getRandomColor('b')}` }}>wer</p>

let timer1;
class App extends Component {



  componentDidMount() {
    //document.documentElement.setAttribute('data-theme', 'pink');
    //  document.documentElement.setAttribute('data-theme', 'green');
    //document.documentElement.setAttribute('data-theme', 'yellow');
    // localStorage.setItem('super_admin', 'eyJraWQiOiJrbmpETGpcL04yT3k5M0w3Z3hVUVlJaEVjNk9UYXhrMlpFMU5LTmF6SGxoMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0Q1YVRyaVl1UCIsImNvZ25pdG86dXNlcm5hbWUiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJvcmlnaW5fanRpIjoiZTAyNmFhZTUtOGNjNi00Y2NmLWJhM2UtMmI5OGZlZGY4Nzc2IiwiYXVkIjoiM3NtMWVsdXRiOW9vaWhiYmZyNXZ1ZGIzMm4iLCJldmVudF9pZCI6IjljNGFmNTYyLWFmZDItNGJlYi04Mjc3LTg4MmE4NzhkMDEwYiIsImN1c3RvbTpib2FyZElkIjoiICIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQyMTcyMjY5LCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIrOTE5OTk5OTk5OTk5IiwiY3VzdG9tOm9yZ0lkIjoiNjFhMGQ4ODcxZWMxOGFhNWYxOGFkODA2IiwiZXhwIjoxNjQyMTc1ODY5LCJjdXN0b206cm9sZSI6IjYxY2FlNWY2MWNmOWRiNzcyY2FhODZhZiIsImlhdCI6MTY0MjE3MjI2OSwianRpIjoiMDc0MjE3NTktMGIwOC00MmY1LWJmMmEtYTc3ZTQwMzdiODIyIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImN1c3RvbTppc0FkbWluIjoidHJ1ZSJ9.CfqhAyPE0V1wcaPwo4RT1aWikDrABBetbsGGWYAizWAUeYVZnlGL7NtQt4mfd5QpisZUPDFSFAQSljqzyhc_3VmygHOmf1jYkfQ79yRkCBBsVOxCMz6Ff-vZgsUxoulBUUFEWweiAIjTxghLA81WGFMCf8sBuM-HtH2LHqkgnSFHK_7UIy0c26IXf0IvxegrBdWJOhhKeuQyXZ2Ah2d2WuKNrne2Pb3wwnt1eJX1v3XYE3kC20hqRNgxVoZ63gsKNPCeW73l1Odnibj4NeQrBtLAxxpikAnVpJaLZrNslvOgtOEtMJiXe1wNXXa6kxpE7DXb-Z9gWYT-v-sHVTBClA')
    // localStorage.setItem('Bearer', 'eyJraWQiOiJrbmpETGpcL04yT3k5M0w3Z3hVUVlJaEVjNk9UYXhrMlpFMU5LTmF6SGxoMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0Q1YVRyaVl1UCIsImNvZ25pdG86dXNlcm5hbWUiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJvcmlnaW5fanRpIjoiZTAyNmFhZTUtOGNjNi00Y2NmLWJhM2UtMmI5OGZlZGY4Nzc2IiwiYXVkIjoiM3NtMWVsdXRiOW9vaWhiYmZyNXZ1ZGIzMm4iLCJldmVudF9pZCI6IjljNGFmNTYyLWFmZDItNGJlYi04Mjc3LTg4MmE4NzhkMDEwYiIsImN1c3RvbTpib2FyZElkIjoiICIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQyMTcyMjY5LCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIrOTE5OTk5OTk5OTk5IiwiY3VzdG9tOm9yZ0lkIjoiNjFhMGQ4ODcxZWMxOGFhNWYxOGFkODA2IiwiZXhwIjoxNjQyMTc1ODY5LCJjdXN0b206cm9sZSI6IjYxY2FlNWY2MWNmOWRiNzcyY2FhODZhZiIsImlhdCI6MTY0MjE3MjI2OSwianRpIjoiMDc0MjE3NTktMGIwOC00MmY1LWJmMmEtYTc3ZTQwMzdiODIyIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImN1c3RvbTppc0FkbWluIjoidHJ1ZSJ9.CfqhAyPE0V1wcaPwo4RT1aWikDrABBetbsGGWYAizWAUeYVZnlGL7NtQt4mfd5QpisZUPDFSFAQSljqzyhc_3VmygHOmf1jYkfQ79yRkCBBsVOxCMz6Ff-vZgsUxoulBUUFEWweiAIjTxghLA81WGFMCf8sBuM-HtH2LHqkgnSFHK_7UIy0c26IXf0IvxegrBdWJOhhKeuQyXZ2Ah2d2WuKNrne2Pb3wwnt1eJX1v3XYE3kC20hqRNgxVoZ63gsKNPCeW73l1Odnibj4NeQrBtLAxxpikAnVpJaLZrNslvOgtOEtMJiXe1wNXXa6kxpE7DXb-Z9gWYT-v-sHVTBClA')
    if (window !== window.top) {
      window.top.location = window.location;
    }

    if (!this.props.assignedModules) {
      let orgCode = new URLSearchParams(window.location.search).get("org");

      if (localStorage.getItem('orgCode')) {
        orgCode = localStorage.getItem('orgCode')
      }

      if (orgCode) {
        localStorage.setItem('orgCode', orgCode);
        this.props.getOrgDataByCode(orgCode);
      } else {
        this.props.getOrgData();
      }
    }
    document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));

    let service = new Service();
    let url = `${baseUrlAdmin}/user/server/time/forexam`;
    service.get(url, true).then((data) => {



      if (data && data.status) {
        localStorage.setItem('serverTime1', data.res.serverTime);
        var serverDate = new Date();
        serverDate.setTime(data.res.serverTime);

        timer1 = setInterval(() => {
          let time = Number(localStorage.getItem('serverTime1'));
          time += 1000;
          localStorage.setItem('serverTime1', time);
          // //   console.log(new Date(time).toString(), 'a')
          //    console.log(new Date().toString(), 'b')
        }, 1000);
      }
    });



  }

  componentWillUnmount() {
    clearInterval(timer1);
  }


  render() {
    return (<>

      <Router>
        {/* <Navbar /> */}
        {/* <MuiThemeProvider theme={theme}> */}
        <Switch>
          {/* <Route exact path="/" component={Dashboard} /> */}
          {/* <Route exact path="/" component={AdminManagement} /> */}
          {/* <Route exact path="/" component={PDF} /> */}

          <Route exact path="/content-bulkupload" component={BulkUploadc} />
          <Route exact path="/curriculum" component={CurriculumMaster} />
          <Route exact path="/" component={this.props.userType == undefined ? LoginPage : this.props.userType !== "Trainer" ? Dashboard : TrainerManagement} />
          <Route exact path="/admin-manage" component={AdminManagement} />
          <Route exact path="/user-management" component={UserManagement} />
          <Route exact path="/user-bulkupload" component={BulkUpload} />
          <Route exact path="/admins-bulkupload" component={BulkUploadA} />
          <Route exact path="/askadoubt" component={AskADoubt} />
          <Route exact path="/discussion-board" component={DiscussionBoard} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path='/organization' component={OrganizationMaster} />
          <Route exact path='/virtualclass' component={VirtualClass} />
          <Route exact path="/branch" component={BranchMaster} />
          <Route exact path="/yearlyplanner" component={CalendarMaster} />
          <Route exact path="/yearlyplanner-bulk" component={BulkUploadYearlyPlanner} />
          <Route exact path="/timetable" component={TimeTable} />

          <Route exact path="/boards" component={BoardsMaster} />
          <Route exact path="/content" component={ContentMaster} />
          <Route exact path="/paper-download/:examId" component={PaperDownload} />
          <Route exact path="/assign-content" component={AssignContent} />
          <Route exact path="/question-bank" component={QuestionBank} />
          <Route exact path="/assign-question" component={AssignQuestions} />
          <Route exact path="/examination" component={Examinations} />
          <Route exact path="/usage-analytics" component={UsageAnalytics} />
          <Route exact path="/exam-analytics" component={ExamAnalytics} />
          <Route exact path="/branch-analytics" component={BranchWiseAnalytics} />
          <Route exact path="/give-attendance" component={Attendance} />
          <Route exact path="/attendance-report" component={AttendaceReport} />
          <Route exact path="/upload-attendance" component={BulkAttendance} />
          <Route exact path="/exam-report/:examId/userId/:userId" component={MockTestResult} />
          <Route exact path="/notifications" component={Notification} />
          <Route exact path="/classroom" component={ClassRoom} />
          <Route exact path="/practise" component={Practise} />
          <Route exact path="/teacher-analytics" component={TeacherAnalytics} />
          <Route exact path="/register" component={RegisterOrganization} />
          <Route exact path="/admission-create" component={EnquiryFrom} />

          <Route exact path="/fee-types" component={FeeTypes} />
          <Route exact path="/fee-plans" component={FeePlan} />


          <Route exact path="/admission-types" component={AdmissionType} />
          <Route exact path="/academic-years" component={AcadamicYear} />

          <Route exact path="/application-fees" component={ApplicationFee} />
          <Route exact path="/admission-courses" component={Course} />
          <Route exact path='/fee-collect' component={Finance} />
          <Route exact path="/bus-fee-plans" component={BusFeePlans} />
          <Route exact path="/fee-day-sheet" component={FeeDaySheet} />



          <Route exact path="/vehicle-management" component={VehicleManagement} />
          <Route exact path="/transport-staff-management" component={TransportStaffManagement} />
          <Route exact path="/route-management" component={RouteManagement} />
          <Route exact path="/transport-attendance" component={TransportAttendance} />
          <Route exact path="/transport-attendance-report" component={TransportAttendanceReport} />
          <Route exact path="/qr-code-manager" component={QrCodeManager} />
          <Route exact path="/ticket-management" component={TicketManagement} />
          <Route exact path="/approval-management" component={ApprovalManagement} />
          <Route exact path="/course-management" component={CourseManagement} />
          <Route exact path="/certificate-download/:userId/:courseId" component={Certificatedownload} />
          <Route exact path="/trainer-management" component={TrainerManagement} />
          {/* <Route exact path={"/trainer-management"} component={TrainerManagement} /> */}
          <Route exact path="/batch-management" component={BatchManagement} />
          <Route exact path="/venue-management" component={VenueManagement} />
          {/* <Route exact path="/settings"  component={CourseSettings} /> */}
          <Route exact path="/*" component={this.props.userType == undefined ? LoginPage : this.props.userType !== "Trainer" ? Dashboard : TrainerManagement} />
          {/* <Route exact path="/paper-download/examId/:examId/examType/:examType" component={PaperDownload} /> */}
          {/*  
            <Route exact path="/curriculum" component={CurriculumMaster} />
            <Route exact path="/curriculum-chapters/:subjectId" component={ChapterTable} />
            <Route exact path="/curriculum-topics/:subjectId/:chapterId" component={TopicTable} />
            <Route exact path="/package-master" component={PackageMaster} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/classTable/:boardId" component={OrganizationClassTable} />
            <Route exact path="/groupTable/:boardId/:classId" component={OrganizationGroupTable} />
            
            <Route exact path="/analytics" component={UserAnalytics} />
            <Route exact path="/exam-analytics" component={ExamAnalytics} />
            <Route exact path="/all-processes" component={AllProcesses} />
            <Route exact path="/bulk-upload" component={BulkUpload} />
            <Route exact path="/transactions" component={Transactions} />
            <Route exact path="/questionbank" component={AddQuestions} />
            <Route exact path="/videolink" component={videoLink} />
            <Route exact path="/review-question" component={ReviewQuestions} />
            <Route exact path="/test-creator" component={TestCreator} />
            <Route exact path="/test-list" component={TestListComponent} />
            <Route exact path="/test-result" component={TestResultComponent} />
            <Route exact path="/conatctus-info" component={ContactUsInfo} />
            <Route path="/admin-management" component={AdminManagement} />
            
            <Route exact path="/courseTable/:branchId" component={BranchCourseTable} />
            <Route exact path="/sectionTable/:branchId/:courseId" component={BranchSectionTable} />
            <Route exact path="/results/:examresultId" component={ExamResult} />
            
            <Route exact path="/discussion" component={DiscussionBoard} /> */}
        </Switch>
        {/* </MuiThemeProvider> */}
      </Router>
      {this.props.showloading ?
        <div className="ma-loading">
          <div className="ma-loading-c">
            {/* <CircularProgress /> */}
          </div>
        </div> : null}
      {
        this.props.isLoading &&
        <LoadingComponent />
      }
      {/* {this.props.snackBars && this.props.snackBars.isShow && <SnackBarComponent open={this.props.snackBars.isShow} onClose={() => this.handleCloseSnackBar()} variant={this.props.snackBars.variant || 'info'} message={this.props.snackBars.message} />} */}
    </>
    );
  }
}


const mapStateToProps = state => ({
  userType: localStorage.getItem('type'),
  showloading: Object.keys(state.common.loadings).length,
  snackBars: state.common.snackBars,
  assignedModules: state.orgData.assignedModules,
  isLoading: state.currentUserPerms.isLoading
});

export default connect(mapStateToProps, { getOrgData, getOrgDataByCode })(App);

