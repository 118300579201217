import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { TabPanel, TabView } from 'primereact/tabview';
// import CreateCourse from './createCourse';
import { Toast } from 'primereact/toast';
import moment from 'moment/moment';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
// import SearchBar from './SearchBar';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
// import {getBoardsData} from './../../../store/actions';
import { getBoardsData } from './../../store/actions';
import Authentication from './../session';
//  import ShowAssessmentsData from './showAssessmentsData';
import LoadingComponent from '../loadingComponent';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Navbar from '../navbar';
import ShowAssessmentsData from '../teachProffTraining/courseManagement/showAssessmentsData';
import CreateCourse from '../teachProffTraining/courseManagement/createCourse';
import SvgViewer from '../customComponents/svgViewer';
import { DeleteIcon, WarningIcon } from '../svgIcons';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import SearchBar from '../teachProffTraining/courseManagement/SearchBar';
import './styles.scss'
import TeachersAttendance from './teachersAttendance';
import { InputSwitch } from 'primereact/inputswitch';
import _ from 'lodash';
import ViewTeachers from './viewTeachers';

const items = [
    { label: 'Analytics' },
    { label: 'Teachers Proffesional Training' },
    { label: 'Trainer View', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


const StatusOptions = [
    { label: 'Scheduled', value: "Scheduled" },
    { label: 'Ongoing', value: "Ongoing" },
    { label: 'Cancelled', value: "Cancelled" },
    { label: 'ReScheduled', value: "ReScheduled" },
    { label: 'Completed', value: "Completed" },
]


export class ViewBatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseType: "",
            fromDate: "",
            toDate: "",
            courseTypeErr: false,
            fromDateErr: false,
            toDateErr: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedTab: 1,
            courseManagement: true,
            createCourse: false,
            mandatoryList: [],
            choiceBasedList: [],
            type: "mandatory",
            totalRecords: null,
            editCourse: {},
            warningDialog: warningDailogInit,
            deleteCourseId: null,
            selectedCourses: [],
            selectedCoursesObjects: [],
            showBatchStatusDropDown:false,
            showAssignUsers: false,
            filterData: null,
            batchRowData: [],
            viewTeachers:false,
            rowDataBatchStatus:{},
            users: [],
            userstotalRecords: '',
            showSearch: "",
            globalSearchUsers: "",
            selectedUser: [],
            assessmentData: {},
            batchStatus:"",
            batchesList: [],
            showAssessmentsData: false,
            assessmentType: "",
            batchesView: true,
            teacherAttendance: false,
            warningDialog2: warningDailogInit,
            statusId:null
        }

        this.service = new Service()
    }

    componentDidMount() {
        this.onClickFetchBatches()
        // this.onConfirmChange()
        // if (!this.props?.boards || !this.props?.boards?.length) {
        //     this.props.getBoardsData();
        // }
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Trainer Manegement</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    onClickReset = () => {
        this.setState({
            "fromDate": "",
            "toDate": ""
        },this.onClickFetchBatches)
        
    }

    onClickFetchBatches = () => {
        
        
        let lazyParams = this.state.lazyParams
        let body = { courseId: this.props.batchId }
        const Url = `${baseUrlAdmin}/trainer/batches?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        this.service.post(Url, body, true).then((data) => {
            if (data && data.res.status) {
                this.setState({
                    batchesList: data?.res?.data,
                    totalRecords: data?.res?.totalRecords
                });

            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    changeTab = (num, string) => {
        this.setState({
            selectedTab: num,
            lazyParams: BasicLazyParams,
            type: string
        }, () => this.onClickFetchBatches())
    }

    onClickCreateNewCourse = () => {
        this.setState({
            courseManagement: false,
            createCourse: true
        })
    }

    onBackFromCreate = (a) => {
        // this.setState({
        //     courseManagement : true,
        //     createCourse : false,
        //     editCourse : {}
        // })
        if (a) {
            this.setState({
                courseManagement: true,
                createCourse: false,
                editCourse: {},
                selectedCourses: [],
            }, () => this.onClickFetchBatches())
            this.toast.show({ severity: 'success', summary: `Successfully course ${a}`, detail: '', life: 3000 });
        } else {
            this.setState({
                courseManagement: true,
                createCourse: false,
                editCourse: {},
                selectedCourses: [],
                // fromDate : "",
                // fromDateErr : false,
                // toDate : "",
                // toDateErr : false,
                // mandatoryList : [],
                // choiceBasedList : []
            })
        }
    }
    onBackViewBatch = () => {
        this.props.onBackViewBatch()
    }

    // onClickEditCourse = (rowData) => {
    //     this.setState({
    //         courseManagement : false,
    //         createCourse : true,
    //         editCourse : rowData
    //     })
    // }
    // onClickTeacherAttendance = (e, data) => {

    //     this.setState({ batchesView: false, teacherAttendance: true, batchRowData: data })


    // }
    onBackTeacherAttendance = () => {
        this.setState({ batchesView: true, teacherAttendance: false,viewTeachers:false })
        // this.toast.show({ severity: 'success', summary: 'successfully attendence saved', detail: "successfully attendence saved", life: 3000 });
    }

    onBackViewTeachers = () => {
        this.setState({ batchesView: true, viewTeachers: false,teacherAttendance: false })
      
    }

    onDeleteCourse = (rowData) => {
        if (rowData?.courseAssignedCount >= 0) {
            this.setState({
                warningDialog: {
                    visible: true,
                    headerMsg: 'Are you Sure?',
                    message: `"${rowData?.courseName}" has been assigned to ${rowData?.courseAssignedCount} users.Do you want to delete the ${rowData?.courseName} from the course list and unassign it from the users?`

                },
                deleteCourseId: rowData
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Unable to Delete Assigned Courses', life: 3000 });
        }
    }

    onClickDelete = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `${baseUrlAdmin}/course/${this.state.deleteCourseId?._id}`;
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res?.res?.status) {
                this.setState({ isLoading: false }, () => {
                    this.onClickFetchCourses();
                })
                this.toast.show({ severity: 'success', summary: 'Course deleted successfully', detail: res.res.message, life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, () => this.onClickFetchBatches());
    }

    onSelectCourse = e => this.setState({ selectedCourses: e.value })

    onChangeSelection = (rowData, e) => {
        if (e.checked) {
            let cId = rowData._id
            rowData.prerequisite.courseId = cId
            rowData.preAssessment.courseId = cId
            rowData.postAssessment.courseId = cId
            this.setState((prevState) => {
                return {
                    selectedCourses: [...prevState?.selectedCourses, rowData._id],
                    selectedCoursesObjects: [...prevState?.selectedCoursesObjects, rowData]
                }
            })
        } else {
            this.setState((prevState) => {
                let filteredArr = prevState?.selectedCourses.filter(a => a !== rowData._id)
                let filterArrObj = prevState?.selectedCoursesObjects.filter(b => b._id !== rowData._id)
                return {
                    selectedCourses: filteredArr,
                    selectedCoursesObjects: filterArrObj
                }
            })
        }
    }

    onChangeSelectUser = (rowData, e) => {
        if (e.checked) {
            this.setState((prevState) => {
                return {
                    selectedUser: [...prevState?.selectedUser, rowData.user_id]
                }
            })
        } else {
            this.setState((prevState) => {
                let filterUserArr = prevState?.selectedUser.filter(c => c !== rowData.user_id)
                return {
                    selectedUser: filterUserArr
                }
            })
        }
    }

    onChangeFromDateFetchBatch = (e)=>{
        let startDate = new Date(e.target.value);
        let timezoneOffset = startDate.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
      

       this.setState({fromDate:startDate.toISOString(),fromDateErr:false})
    }


    onChangeToDateFetchBatch = (e)=>{
        let endDate = new Date(e.target.value);
        let timezoneOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - timezoneOffset);
      

       this.setState({toDate:endDate.toISOString(),toDateErr:false})
    }

    onClikAssginUsers = () => {
        if (this.state.selectedCourses.length > 0) {
            this.setState({ showAssignUsers: true })
        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the courses', life: 3000 });
        }
    }

    onSearchClick = (filterData) => {
        this.setState({
            filterData
        }, this.filterUsersData)
    }
    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })
        let { lazyParams, globalSearchUsers, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearchUsers}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    userstotalRecords: res.res.totalRecords
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
        })
    }

    onPageChangeUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onSortUsers = (event) => {
        this.setState((prevState) => {
            return { lazyParams: { ...prevState?.lazyParams, ...event } };
        }, this.filterUsersData);
    }

    onClickMarkAttendence = (data)=>{
        let today= new Date();
        today.setHours(0, 0, 0, 0);
        today.toLocaleString('en-US', { timeZoneName: 'short', timeZone: 'Asia/Kolkata', hour12: false });

       
        let startDate= new Date(data?.fromDate);
        startDate.setHours(0, 0, 0, 0);
        startDate.toLocaleString('en-US', { timeZoneName: 'short', timeZone: 'Asia/Kolkata', hour12: false });

        let endDate= new Date(data?.toDate);
        endDate.setHours(0, 0, 0, 0);
        endDate.toLocaleString('en-US', { timeZoneName: 'short', timeZone: 'Asia/Kolkata', hour12: false });

       

        if (today > endDate){
            this.toast.show({ severity: 'error', summary: 'Invalid Date.', detail: `We Can't Mark the Attendance For Completed Batch.`, life: 3000 });
    
        } 
       else if(startDate <= today && today <= endDate){
        this.setState({teacherAttendance:true,viewTeachers:false,batchesView:false,batchRowData: data})
      }
      else{
        this.toast.show({ severity: 'error', summary: 'Invalid Date.', detail: `Please wait until the batch start date to mark attendance.`, life: 3000 });

      }
          
    }
    onClickViewTeachers = (data)=>{
        this.setState({teacherAttendance:false,viewTeachers:true,batchesView:false,batchRowData: data})
  }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return { globalSearchUsers: e.target.value, }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }
        });
    }

    startGlobalFilter = () => {
        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;
            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }
            }
            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }

    onClickAssignExamsToUsers = () => {
        if (this.state.selectedUser.length) {
            let obj = {}
            obj.curriculum = {}
            obj.assignIds = this.state.selectedUser

            let examsArr = []
            this.state.selectedCoursesObjects.map(e => {
                examsArr.push(e.prerequisite, e.preAssessment, e.postAssessment)
            })
            obj.curriculum.boardId = "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7"
            obj.curriculum.classId = "ccb7388c-dfc8-477d-8919-b202db3f422b"
            obj.curriculum.groupId = "1aeababe-3477-427c-8a74-0819caf05d36"
            obj.curriculum.exams = examsArr

            let courseArr = []
            this.state.selectedCoursesObjects.map(e => {
                courseArr.push({ id: e._id, name: e.courseName, assigned: e.type === "mandatory" ? true : false })
            })
            obj.courses = courseArr

            obj.assignTo = "user"
            obj.examType = "Assessment"

            let url = `${baseUrlAdmin}/examination/assigntosection`
            this.setState({ isLoading: true })
            this.service.post(url, obj, true).then(res => {
                if (res && res?.status && res?.res?.success) {
                    this.setState({
                        isLoading: false, showAssignUsers: false, users: [], filterData: null,
                        selectedCourses: [], selectedCoursesObjects: [], selectedUser: [],
                    })
                    this.toast.show({ severity: 'success', summary: '', detail: 'Successfully assigned to users', life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }
            }).catch(e => {
             
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
            })

        } else {
            this.toast.show({ severity: 'error', summary: '', detail: 'Please select the studnets', life: 3000 });
        }
    }
    onClickAssessments = (rowData, assessmentType) => {
        this.setState({
            courseManagement: false,
            showAssessmentsData: true,
            assessmentData: rowData,
            assessmentType: assessmentType
        })
    }

    onBackFromAssessments = () => {
        this.setState({
            courseManagement: true,
            showAssessmentsData: false,
            assessmentData: {},
            assessmentType: '',
            selectedCourses: []
        })
    }

    usersClear = () => {
        this.setState({ users: [], filterData: {}, showSearch: false, globalSearchUsers: "" })
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isActive = "Active"
        } else {
            data.isActive = "InActive"
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Batch status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            statusId: data,
            //editUserData: this.transFormEditUserData(data)
        });

       

    }

    statusTemplate = (rowData) => {
        

        return (
            <div className="ma-status">
                {/* <span className="ma-status-text">{rowData.isActive ? true : false} </span> */}
                

                    <InputSwitch onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.isActive== true} />
               
            </div>

        );


    }

    onUpdateBatchStatus = ()=>{
      
        let url = `${baseUrlAdmin}/batch/status`;

        const statusData={
               courseId:this.state.rowDataBatchStatus?.courseId,
                batchId:this.state.rowDataBatchStatus?._id,
               status:this.state.batchStatus
        }
        const ActiveOrInactiveData={
            courseId:this.state.statusId?.courseId,
             batchId:this.state.statusId?._id,
            isActive:this.state.statusId?.isActive
     }
       const payload = this.state.showBatchStatusDropDown ?statusData:ActiveOrInactiveData
        this.service.post(url,payload , true).then((res) => {
            if (res?.status) {
                this.setState({ 
                    showBatchStatusDropDown:false,
                    isLoading: false,
                    rowDataBatchStatus:{},
                    warningDialog: {
                        visible: false,
                    }
                   
                },this.onClickFetchBatches())
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
        
    }

    
    // onConfirmChange = () => {
        
   
    //     this.setState({
    //         warningDialog: warningDailogInit
    //     });
    //     let payload = {
    //         isActive : this.state.statusId?.isActive,
    //         _id : this.state.statusId?._id 
    //     }

    //     let url = `${baseUrlAdmin}/batch/update`;
    //     // const url = "http://192.168.29.154:3000/v1/data/batch/update";
    //     this.service.post(url,payload , true).then((res) => {
    //         if (res?.status && res?.res?.status) {
    //             this.setState({
    //                 isLoading: false,
    //             },this.onClickFetchBatches())
    //         } else {
    //             this.setState({ isLoading: false })
    //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
    //         }
    //     }).catch(e => {
    //         this.setState({ isLoading: false });
    //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
    //     })


    // }


    render() {
        const header1 = (
            <div className="table-header ma-tbl-header  ">
                <div className="ma-tbl-left">
                    <h2 >Batches List For <span style={{color:"green",fontWeight:"bold"}}>{(this.props.courseName.charAt(0).toUpperCase()+ this.props.courseName.slice(1))}</span></h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearchUsers}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    {/* <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearchUsers: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul> */}
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                {this.state.columns.map((column) => {
                                    return <li>{column.columnName}</li>
                                })}
                            </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        return (
            <>
                {this.state.batchesView &&
                    <div>

                        <div className='p-grid ma-toolbar'>
                            <div className="p-col-12 p-md-1 ma-no-p">
                            </div>
                            <div className="p-col-12 p-md-11 ma-no-pm">
                                <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                            </div>
                            {/* <p className='back-arrow' onClick={this.onBackViewBatch}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                        </div>
                        <div className='ma-main'>
                            <div style={{marginBottom:"-5px",display:"flex",justifyContent:"space-between"}}>
                                {/* <div>
                            <div>
                            <Dropdown value={this.state.courseType} className='ma-w200' placeholder="Select Subject"
                                onChange={(e) => this.setState({courseType : e.target.value,courseTypeErr : false})}/>
                            <span className='ma-required-mark '>*</span>
                            </div>
                            {this.state.courseTypeErr && <span className='p-error'>Subject required</span>}
                        </div> */}
                            <div className='flex'>
                                <div className='ma-mlr5'>
                                    <div>
                                        <Calendar placeholder='From Date' dateFormat='dd-mm-yy' showIcon value={this.state.fromDate}
                                            onChange={(e) => this.onChangeFromDateFetchBatch(e)} />

                                    </div>
                                    {/* {this.state.fromDateErr && <span className='p-error'>From Date required</span>} */}
                                </div>
                                <div className='ma-mlr5'>
                                    <div>
                                        <Calendar placeholder='To Date' dateFormat='dd-mm-yy' showIcon value={this.state.toDate}
                                            onChange={(e) => this.onChangeToDateFetchBatch(e)} />

                                    </div>
                                    {/* {this.state.toDateErr && <span className='p-error'>To Date required</span>} */}
                                </div>
                                <div>
                                    <Button style={{marginTop:"5px"}} label='Fetch Batches' className="p-button-success" onClick={() => this.onClickFetchBatches()} />
                                    {/* <Button label='Reset' className="p-button-success ma-mlr5" onClick={() => this.onClickReset()} /> */}
                                </div>
                            </div>    
                            <div style={{marginTop:"6px"}}>
                            <Button type='button' className="back-arrow p-button-success ma-mlr5" onClick={this.onBackViewBatch}><i style={{fontSize:"13px"}} className="pi pi-arrow-left ma-mr5"></i>Back</Button>
                                
                            </div>
                            </div>
                            <div className='ma-mt30 '>
                                <DataTable ref={(el) => this.dt = el}
                                    lazy className='ma-mt30'
                                    value={this.state.batchesList}
                                    dataKey="_id"
                                    paginator
                                    onPage={this.onPage}
                                    first={this.state.lazyParams.first}
                                    last={this.state.totalRecords}
                                    rows={this.state.lazyParams.rows}
                                    totalRecords={this.state.totalRecords}

                                    // selectionMode="checkbox"
                                    // selection={this.state.selectedCourses}
                                    // onSelectionChange={this.onSelectCourse}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} batches"
                                    globalFilter={this.state.globalFilter2}
                                    header={header1}
                                    paginatorLeft={() => {
                                        return <>
                                            <p className='avail-q'>Available  Batches:  <span>{this.state.batchesList && this.state.batchesList.length}</span> </p>
                                        </>
                                    }}
                                >
                                    {/* <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column> */}
                                    {/* <Column headerStyle={{ width: '70px' }} body={(rowData) => {
                            return (<><Checkbox onChange={e => this.onChangeSelection(rowData,e)}
                                checked={this.state.selectedCourses?.includes(rowData._id)}
                            ></Checkbox>
                            </>)
                            }} header="Select"
                        /> */}
                                    <Column headerStyle={{width:"120px"}} field="slNo" header="Sr. No" body={(rowData, rowIndex) => {
                                        return <span style={{textAlign:"center"}}>
                                            {(this.state.lazyParams.page * this.state.lazyParams.rows) + rowIndex.rowIndex + 1}
                                        </span>
                                    }} />
                                    <Column field="batchName"  header="Batch Name" />
                                    {/* <Column  headerStyle={{width:"160px"}} field="courseName" header="Course " body={(rowData) => {
                                        return (<><span style={{width:"100px"}}>{rowData?.courseName}</span></>)
                                    }} /> */}
                                    <Column  headerStyle={{width:"160px"}} header="Start Date" body={(rowData) => {
                                        return (<><span style={{width:"100px"}}>{moment(rowData.fromDate).format('DD-MM-YYYY')}</span></>)
                                    }} />
                                    <Column headerStyle={{width:"160px"}} header="End Date" body={(rowData) => {
                                        return (<><span style={{width:"100px"}}>{moment(rowData.toDate).format('DD-MM-YYYY')}</span></>)
                                    }} />
                                    {/* <Column field="isActive" header="Active/InActive"  headerStyle={{ width: "180px",textAlign:"center" }} body={(rowData) => {
                                    return (
                                       <div style={{display:"flex",justifyContent:"center"}}>
                                    
                                  
                                    <Authorizer permId={PERMISSIONS.TRAINER_BATCH_ACTIVE} >
                                        <InputSwitch tooltip={rowData?.isActive === "Active" ?"Active":"InActive"} style={{ width: "50px",textAlign:"center" }} onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.isActive == 'Active'} />
                                    </Authorizer>
                                  
                                </div>
                            )
                                   }}/> */}
                                    {/* <Column field="isActive" header="Status" body={this.statusTemplate} /> */}
                                    <Column field="status" headerStyle={{ width: "150px",textAlign:"center" }} header="Status" body={(rowData, rowIndex) => {
                                            if(rowData.status === "Scheduled"){
                                               
                                                return (
                                                <Authorizer permId={PERMISSIONS.TRAINER_BATCH_ACTIVE} >
                                                    <div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData,batchStatus:rowData?.status})} style={{backgroundColor:"#B2EBF2",color:"black",width: "120px",paddingTop:"5px",display:"flex",justifyContent:"space-around" ,paddingBottom:"3px",textAlign:"center",color:"black"}}>{rowData?.status} <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" />
                                                    </div>
                                                </Authorizer>
                                                )
                                            }
                                            else if(rowData.status === "Ongoing"){
                                                return (
                                            <Authorizer permId={PERMISSIONS.TRAINER_BATCH_ACTIVE} >
                                                <div  onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData,batchStatus:rowData?.status})} style={{textAlign:"center",color:"black" ,width: "120px",paddingTop:"5px",display:"flex", justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#C8E6C9 "}}> {rowData?.status} <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit"  />
                                                </div>
                                            </Authorizer>
                                            )    
                                            }
                                            else if(rowData.status === "Cancelled"){
                                                return (
                                            <Authorizer permId={PERMISSIONS.TRAINER_BATCH_ACTIVE} >
                                                <div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData,batchStatus:rowData?.status})} style={{textAlign:"center",color:"black" ,width: "120px",paddingTop:"5px",display:"flex", justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#FFCDD2"}}>{rowData?.status} <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit"  />
                                                </div>
                                            </Authorizer>    
                                                )
                                            }
                                            else if(rowData.status === "ReScheduled"){
                                                return (
                                            <Authorizer permId={PERMISSIONS.TRAINER_BATCH_ACTIVE} >
                                                <div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData,batchStatus:rowData?.status})} style={{textAlign:"center",color:"black" ,width: "120px",paddingTop:"5px",display:"flex", justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#FFF9C4 "}}>{rowData?.status} <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" />
                                                </div>
                                            </Authorizer>    
                                                )
                                            }
                                            else if(rowData.status === "Completed"){
                                                return (
                                            <Authorizer permId={PERMISSIONS.TRAINER_BATCH_ACTIVE} >
                                                <div onClick={()=>this.setState({showBatchStatusDropDown:true,rowDataBatchStatus:rowData,batchStatus:rowData?.status})} style={{textAlign:"center",color:"black" ,width: "120px",paddingTop:"5px",display:"flex",display:"flex", justifyContent:"space-around" ,paddingBottom:"3px",backgroundColor:"#98FB98"}}>{rowData?.status} <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="13" height="13" tooltip="Edit" />
                                                </div>
                                            </Authorizer>    
                                                )
                                            }
                                        }} />

                                    <Column field="roomstrength" headerStyle={{display:"flex",justifyContent:"center"}} header="Strength" body={(rowData, rowIndex) => {
                                        return <p style={{textAlign:"center"}}>
                                            {rowData.totalAssigned}/{rowData.roomStrength}
                                        </p>
                                    }} />
                                    <Column header="Actions" body={(rowData) => {
                                      const menuItems = [
                                        {
                                            label: 'View Teachers',
                                            command: () => { this.onClickViewTeachers(rowData) }
                                        },
                                        {
                                            label: 'Mark Attendence', 
                                            command: () => { this.onClickMarkAttendence(rowData) }
                                        },
                                        
                                    ]
                                    return (
                                        <>
                                            {/* <Button onClick={(e) => { this.onClickTeacherAttendance(e, rowData) }}>Mark Attendence</Button> */}
                                            <Button id={rowData._id} onClick={(event) => this[rowData._id].toggle(event)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary ma-ml10' style={{height:"28px",width : "28px",borderColor : "black"}}/>
                                        <Menu model={menuItems.map(item => {

                                            if(item.label === "View Teachers"){
                                            return   {
                                                        ...item,
                                                        command: () => (
                                                            <Authorizer permId={PERMISSIONS.TRAINER_VIEW_TEACHERS}>
                                                                {item.command()}
                                                            </Authorizer>
                                                        )
                                                  }
                                            }
                                            if(item.label === "Mark Attendence"){
                                                return   {
                                                            ...item,
                                                            command: () => (
                                                                <Authorizer permId={PERMISSIONS.TRAINER_USERS_MARK_ATTENDENCE}>
                                                                    {item.command()}
                                                                </Authorizer>
                                                            )
                                                      }
                                                }
                                    })}

                                        className="content-more" popup={true} ref={el => this[rowData._id] = el}/>
                                        </>
                                        )
                                    }} />
                                </DataTable>
                            </div>
                        </div>




                    </div>
                }
                {this.state.teacherAttendance && <><TeachersAttendance batchData={this.state.batchRowData} onBackTeacherAttendance={this.onBackTeacherAttendance} /></>}
                {this.state.viewTeachers && <><ViewTeachers batchData={this.state.batchRowData} onBackViewTeachers={this.onBackViewTeachers} /></>}
                {this.state.isLoading && <><LoadingComponent /></>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onUpdateBatchStatus} />
                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog header="Update Status" visible={this.state.showBatchStatusDropDown} style={{ width: '30vw' }} onHide={() => this.setState({showBatchStatusDropDown:false})} 
                    footer={()=>{
                       return (<div> 
                        <Button label="update" icon="pi pi-check" onClick={this.onUpdateBatchStatus}  autoFocus />
                        <Button label="Cancel" icon="pi pi-times" onClick={() => this.setState({showBatchStatusDropDown:false})}   className="p-button-text" />
                       
                    </div>)   }}>
                        
                        <div className="p-col-4 p-md-3 ma-mr20">
                                <p className='ma-label-s1'>Status<span className='ma-required'>*</span></p>
                                <Dropdown value={this.state.batchStatus||this.state.rowDataBatchStatus?.status} style={{ height: "35px" }} className='ma-w200 md:w-14rem' placeholder='Select' options={StatusOptions} optionLabel='label' optionValue='value'
                                    onChange={(e) => this.setState({batchStatus:e.value})}
                                />
                              
                        </div>
                       
                   
               
                    </Dialog>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});
export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(ViewBatches))) 