import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { Component } from 'react'
import { getFormFields, isFormValid, onTextChange } from '../../utile/formHelper'
import { baseUrlAdmin } from '../../store/apiConstants'
var createCourseSettingFieldsJson = require('./createCourseSettingFields.json')
export default class settings extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(createCourseSettingFieldsJson);
        this.state = {
            createCourseSettingFields: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
    }

    onCreateCourseSettings = () => {
        let body = this.state.createCourseSettingFields

        const formStatus = isFormValid(createCourseSettingFieldsJson, this.formFields.formValidations, body);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
    }

    render() {
        const { createCourseSettingFields, formValidations } = this.state
        return (
            <>
                <div className='p-grid tab-active-body' style={{ paddingLeft: '20px' }}>
                    <div className='p-col-12' style={{ marginTop: "15px" }}>
                        <h2 className='ma-active-item'> Course Settings</h2>

                        <div className='p-pl-2'>
                            <div>{/* MinCourses */}
                                <h4>Minimum No of Courses a teacher can Register</h4>
                                <div className="p-grid">

                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Minimum Courses<span className='ma-required'>*</span></p>
                                        <InputText value={createCourseSettingFields.minimumCourses} className='p-inputtext-style1 ma-w100p md:w-14rem' placeholder='Total Courses'
                                            onChange={(e) => onTextChange(e.target.value, 'minimumCourses', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                        />
                                        {formValidations && !formValidations.fields['minimumCourses'].isValid && <p className="p-error">{formValidations.fields['minimumCourses'].errorMsg}</p>}
                                    </div>
                                    <div className="p-col-12 p-md-2 p-ml-2">
                                        <p className='ma-label-s1'>Choice Based Courses<span className='ma-required'>*</span></p>
                                        <div className='p-grid '>
                                            <div className="p-col-12 p-md-6">
                                                <InputText value={createCourseSettingFields.minCoursesMinChoice} className='p-inputtext-style1 ma-w100p md:w-14rem' placeholder='Min'
                                                    onChange={(e) => onTextChange(e.target.value, 'minCoursesMinChoice', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                                />
                                                {formValidations && !formValidations.fields['minCoursesMinChoice'].isValid && <p className="p-error">{formValidations.fields['minCoursesMinChoice'].errorMsg}</p>}
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <InputText value={createCourseSettingFields.minCoursesMaxChoice} className='p-inputtext-style1 ma-w100p md:w-14rem' placeholder='Max'
                                                    onChange={(e) => onTextChange(e.target.value, 'minCoursesMaxChoice', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                                />
                                                {formValidations && !formValidations.fields['minCoursesMaxChoice'].isValid && <p className="p-error">{formValidations.fields['minCoursesMaxChoice'].errorMsg}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>  {/* Max Courses */}
                                <h4>Maximum No of Courses a teacher can Register</h4>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Maximum Courses<span className='ma-required'>*</span></p>
                                        <InputText value={createCourseSettingFields.maximumCourses} className='p-inputtext-style1 ma-w100p md:w-14rem' placeholder='Total Courses'
                                            onChange={(e) => onTextChange(e.target.value, 'maximumCourses', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                        />
                                        {formValidations && !formValidations.fields['maximumCourses'].isValid && <p className="p-error">{formValidations.fields['maximumCourses'].errorMsg}</p>}
                                    </div>

                                    <div className="p-col-12 p-md-2 p-ml-2">
                                        <p className='ma-label-s1'>Choice Based Courses<span className='ma-required'>*</span></p>
                                        <div className='p-grid '>
                                            <div className="p-col-12 p-md-6">
                                                <InputText value={createCourseSettingFields.maxCoursesMinChoice} className='p-inputtext-style1 ma-w100p md:w-14rem' placeholder='Min'
                                                    onChange={(e) => onTextChange(e.target.value, 'maxCoursesMinChoice', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                                />
                                                {formValidations && !formValidations.fields['maxCoursesMinChoice'].isValid && <p className="p-error">{formValidations.fields['maxCoursesMinChoice'].errorMsg}</p>}
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <InputText value={createCourseSettingFields.maxCoursesMaxChoice} className='p-inputtext-style1 ma-w100p md:w-14rem' placeholder='Max'
                                                    onChange={(e) => onTextChange(e.target.value, 'maxCoursesMaxChoice', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                                />
                                                {formValidations && !formValidations.fields['maxCoursesMaxChoice'].isValid && <p className="p-error">{formValidations.fields['maxCoursesMaxChoice'].errorMsg}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-col-12' >
                        <h2 className='ma-active-item'> Batch Settings</h2>
                        <div className='p-pl-2'>
                            <div>
                                <h3>For Mandatory Courses</h3>
                                <div>{/* Min Mandatory Courses */}
                                    <h4>Minimum Batch Size</h4>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Offline<span className='ma-required'>*</span></p>
                                            <InputText value={createCourseSettingFields.batchMandatoryMinOffline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMandatoryMinOffline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMandatoryMinOffline'].isValid && <p className="p-error">{formValidations.fields['batchMandatoryMinOffline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Online</p>
                                            <InputText value={createCourseSettingFields.batchMandatoryMinOnline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMandatoryMinOnline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMandatoryMinOnline'].isValid && <p className="p-error">{formValidations.fields['batchMandatoryMinOnline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Blended</p>
                                            <InputText value={createCourseSettingFields.batchMandatoryMinBlended} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMandatoryMinBlended', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMandatoryMinBlended'].isValid && <p className="p-error">{formValidations.fields['batchMandatoryMinBlended'].errorMsg}</p>}
                                        </div>

                                    </div>
                                </div>

                                <div>{/* Max Mandatory Courses*/}
                                    <h4>Maximum Batch Size</h4>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Offline<span className='ma-required'>*</span></p>
                                            <InputText value={createCourseSettingFields.batchMandatoryMaxOffline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMandatoryMaxOffline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMandatoryMaxOffline'].isValid && <p className="p-error">{formValidations.fields['batchMandatoryMaxOffline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Online</p>
                                            <InputText value={createCourseSettingFields.batchMandatoryMaxOnline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMandatoryMaxOnline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMandatoryMaxOnline'].isValid && <p className="p-error">{formValidations.fields['batchMandatoryMaxOnline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Blended</p>
                                            <InputText value={createCourseSettingFields.batchMandatoryMaxBlended} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMandatoryMaxBlended', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMandatoryMaxBlended'].isValid && <p className="p-error">{formValidations.fields['batchMandatoryMaxBlended'].errorMsg}</p>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='p-mt-3'>
                                <h3>For Choice Based Courses</h3>
                                <div>{/* Min Choice Courses */}
                                    <h4>Minimum Batch Size</h4>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Offline<span className='ma-required'>*</span></p>
                                            <InputText value={createCourseSettingFields.batchChoiceMinOffline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchChoiceMinOffline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchChoiceMinOffline'].isValid && <p className="p-error">{formValidations.fields['batchChoiceMinOffline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Online</p>
                                            <InputText value={createCourseSettingFields.batchChoiceMinOnline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchChoiceMinOnline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchChoiceMinOnline'].isValid && <p className="p-error">{formValidations.fields['batchChoiceMinOnline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Blended</p>
                                            <InputText value={createCourseSettingFields.batchChoiceMinBlended} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchChoiceMinBlended', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchChoiceMinBlended'].isValid && <p className="p-error">{formValidations.fields['batchChoiceMinBlended'].errorMsg}</p>}
                                        </div>

                                    </div>
                                </div>

                                <div>{/* Max Choice Courses*/}
                                    <h4>Maximum Batch Size</h4>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Offline<span className='ma-required'>*</span></p>
                                            <InputText value={createCourseSettingFields.batchChoiceMaxOffline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchChoiceMaxOffline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchChoiceMaxOffline'].isValid && <p className="p-error">{formValidations.fields['batchChoiceMaxOffline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Online</p>
                                            <InputText value={createCourseSettingFields.batchChoiceMaxOnline} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchChoiceMaxOnline', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchChoiceMaxOnline'].isValid && <p className="p-error">{formValidations.fields['batchChoiceMaxOnline'].errorMsg}</p>}
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <p className='ma-label-s1'>Blended</p>
                                            <InputText value={createCourseSettingFields.batchMChoiceMaxBlended} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                                onChange={(e) => onTextChange(e.target.value, 'batchMChoiceMaxBlended', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['batchMChoiceMaxBlended'].isValid && <p className="p-error">{formValidations.fields['batchMChoiceMaxBlended'].errorMsg}</p>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='p-col-12' >
                        <h2 className='ma-active-item'> Trainer Settings</h2>
                        <div className='p-pl-2'>
                            <h4>Total No of Courses a trainer can take during a year</h4>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <InputText value={createCourseSettingFields.trainerCoursesPerYear} className='p-inputtext-style1 ma-w100p md:w-14rem'
                                        onChange={(e) => onTextChange(e.target.value, 'trainerCoursesPerYear', this, createCourseSettingFieldsJson, createCourseSettingFields, formValidations, 'createCourseSettingFields', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['trainerCoursesPerYear'].isValid && <p className="p-error">{formValidations.fields['trainerCoursesPerYear'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className='p-col-12 p-md-12 flex justify-end p-mt-5'>
                    <Button label='Save Course Settings' onClick={this.onCreateCourseSettings} />
                </div>
            </>
        )
    }
}
