import React, { Component } from 'react'
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid, onTextChange, isFieldValid, onNumberChange, onEmailChange, onDropDownChange } from '../../utile/formHelper';
import _, { cloneDeep } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin } from '../../store/apiConstants';
import InputText2 from './../customComponents/inputText2';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import Service from './../../services';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import InputTextB from './../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import {

    getBoardsData,
    getBranchesLatest
} from './../../store/actions';
import AdditionalGrades from './additionalGrades';
import { FIELDS_INFO } from '../../constants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FileUpload from '../fileUpload';
const createUserFields = require('./createUser.json');

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Add New User', className: 'ma-active-item' }
];


const home = { icon: 'pi pi-home' }


let formFields = createUserFields;


const processEditUser = (editUser) => {

    editUser = _.cloneDeep(editUser);
    editUser.gradeId = editUser.classId;
    // editUser.phone_number = editUser.phone_number.substring(3);
    editUser.phone_number = editUser.phone_number

    editUser.grade = editUser['custom:grade'];
    editUser.group = editUser['custom:group'];
    editUser.board = editUser['custom:board'];
    editUser.board = editUser['custom:board'];
    return editUser;
}

const getFields = (isEdit) => {
    if (isEdit) {
        formFields = formFields.filter((field) => field.FieldName != 'password' && field.FieldName != 'confirmPassword'
        )
    } else {
        formFields = createUserFields
    }
    return formFields;
}

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(getFields(this.props.editUser), this.props.editUser ? processEditUser(this.props.editUser) : {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            boards: [],
            classes: [],
            academicYears: [],
            sections: [{ value: '21759c65-7c60-4745-bdbc-bcb47a65cf9c', label: 'Testing Section' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf11', label: 'Testing Section 1' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf22', label: 'Testing Section 2' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf33', label: 'Testing Section 3' }],
            isShowCreateSuccess: false,
            hideUpdateButton: false,
            sectionsData: {},
            religionData:[
                {label: "Hindu",value : "hindhu"},
                {label: "Muslim",value : "muslim"},
                {label: "Christian",value : "christian"},
                {label: "Others",value : "othera"}
            ],
            casteData:[
                {label: "General",value : "general"},
                {label: "BC-A",value : "bc-a"},
                {label: "BC-B",value : "bc-b"},
                {label: "BC-C",value : "bc-c"},
                {label: "BC-D",value : "bc-d"},
                {label: "BC-E",value : "bc-e"},
                {label: "SC",value : "sc"},
                {label: "ST",value : "st"}
            ],
            genderData:[
                {label: "Male",value : "male"},
                {label: "Female",value : "female"},
            ],
            qualificationData:[
                {label: "Matriculate",value : "matriculate"},
                {label: "Graduate",value : "graduate"},
                {label: "Post Graduate",value : "postGraduate"},
                {label: "Doctorate",value : "doctorate"},
                {label: "Others",value : "others"},
            ],
            classStudyingDrp : [
                {label : "I",value:"i"},{label : "II",value:"ii"},{label : "III",value:"iii"},{label : "IV",value:"iv"},
                {label : "V",value:"v"},{label : "VI",value:"vi"},{label : "VII",value:"vii"},{label : "VIII",value:"viii"},
                {label : "IX",value:"ix"},{label : "X",value:"x"},{label : "XI",value:"xi"},{label : "XII",value:"xii"},
            ],
            siblings : [
                {name: "",gender: "",classStudying : "",schoolStudying : ""}
            ],
            prevAcadamicDetails : [
                {name: "",classStudied: "",schoolStudied : "",percentageOrGpa : ""}
            ],
        }

        this.service = new Service();
    }

    componentDidMount() {
        this.getAdmissionMetaData()
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }
        }

        if (this.props.editUser) {
            if(this.props?.editUser?.siblings){
                this.setState({
                    siblings:this.props?.editUser?.siblings,
                    prevAcadamicDetails : this.props?.editUser?.prevAcadamicDetails
                })
            }
            const { boardId, classId, groupId } = this.props.editUser;
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === this.props.editUser.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                let _classes = [];
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));

                this.setState((prevState) => {
                    return {
                        classes: _classes,
                        boardId, classId, groupId
                    };
                });
            }

        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            if (this.props.editUser && this.props.branchData) {
                let finaldata = this.getSectionsData(this.props.branchData, this.state.user.sectionId, this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata
                });

            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }
                this.setState({
                    sectionsData
                });
            }
        }
    }

    getAdmissionMetaData = () => {
        let url = `${baseUrlAdmin}/admissions/meta-data`;
        this.setState({
            isLoading: true
        })
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        academicYears: res?.res?.data?.academicYears || [],
                        isLoading: false
                    };
                });
            }
            else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.errMessage, life: 3000 });
            console.log(e);
        })
    }



    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        ///   console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }



    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }
        }

        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }



        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.editUser && this.props.branchData) {
                let finaldata = this.getSectionsData(this.props.branchData, this.state.user.sectionId, this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata
                });

            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }
                this.setState({
                    sectionsData
                });
            }
        }



    }

    onChangeBoard = (boardId) => {
        this.setState({ classes: [], groups: [], classId: null, groupId: null });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }

        let user = {
            ...this.state.user,
            boardId,
            board: selectedBoard.boardName
        };

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'board'), this.state.formValidations, user);
        this.setState((prevState) => {
            return {
                classes: _classes,
                user,
                boardId,
                formValidations
            };
        });

    };

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let selectedGroup = _groups[0];

            let user = {
                ...this.state.user,
                gradeId: classId,
                grade: selectedClass.className,
                groupId: selectedGroup.value,
                group: selectedGroup.label
            }

            let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'grade'), this.state.formValidations, user);
            this.setState((prevState) => {
                return {
                    classId,
                    groupId: selectedGroup.value,
                    user,
                    formValidations
                };
            });

        }
    };


    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }


        if (!data.selectedNode.children) {
            let user = {
                ...this.state.user,
                sectionId: data.selectedNode.key,
                section: data.selectedNode.name
            };
            let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'sectionId'), this.state.formValidations, user);

            this.setState((prevState) => {
                return {
                    user,
                    formValidations,
                    sectionsData: newSectionData
                };
            });

        } else {
            let user = {
                ...this.state.user,
                sectionId: '',
                section: ''
            };
            let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'sectionId'), this.state.formValidations, user);


            this.setState({
                formValidations,
                sectionsData: newSectionData
            });
        }




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onSubmitClick = () => {

        const { user, formValidations } = this.state;
        user.siblings =  this.state.siblings;
        user.prevAcadamicDetails = this.state.prevAcadamicDetails
        user.sectionId = "4a8800de-f4ea-4be1-93f0-1053f19cf8c0"
        const formStatus = isFormValid(formFields, this.formFields.formValidations, user);

        // // password and confirm password
        let isValid = true;
        if (user.confirmPassword !== '' && user.password != user.confirmPassword) {
            isValid = false;
            formValidations.fields['confirmPassword'] = { ...formValidations.fields['confirmPassword'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }
        if (user.username !== '' && !(/^[\w\-]+$/).test(user.username)) {
            isValid = false;
            formValidations.fields['username'] = { ...formValidations.fields['username'], isValid: false, errorMsg: `User name allows only letters, numbers, -,_` }
        }




        if (!formStatus.formValidations.isFormValid || !isValid) {
            
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            
            if (this.props.editUser) {

                const url = `${baseUrlAdmin}/user/update/${user.user_id}/details`;
                let payload = {
                    user_info: {
                        ...user,
                        "phone_number": `+91${user.phone_number}`,
                        "given_name": user.given_name,
                        "grade": user.grade,
                        "custom:grade": user.grade,
                        "group": user.group,
                        "custom:group": user.group,
                        "board": user.board,
                        "custom:board": user.board,
                        "classId": user.gradeId,
                        "custom:gradeId": user.gradeId,
                        "groupId": user.groupId,
                        "custom:groupId": user.groupId,
                        "boardId": user.boardId,
                        "custom:boardId": user.boardId,
                        academicYear: user.academicYear,
                        sectionId: user.sectionId,
                        email: user.email
                    },
                    isBaseAccount: user.isBaseAccount,
                    user_id: user.user_id,
                    account_id: user.account_id,
                    username: user.username
                }
                delete payload.user_info.user_info
                //  return;
                this.setState({
                    isLoading: true
                });
                this.service.put(url, payload, true).then((res) => {


                    if (res && res.status && res.res.success) {
                        this.setState({
                            isLoading: false,
                            isShowCreateSuccess: true
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                    this.setState({
                        isLoading: false
                    });
                });


            } else {

                const url = `${baseUrlAdmin}/user/addUser`;

                let userToSave = {
                    ...user,
                    phone_number: `+91${user.phone_number}`
                }

                this.setState({
                    isLoading: true
                });

                this.service.post(url, userToSave, true).then((res) => {


                    if (res && res.status && res.res.success) {
                        this.setState({
                            isLoading: false,
                            isShowCreateSuccess: true
                        });

                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }


                }).catch(e => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                    this.setState({
                        isLoading: false
                    });
                });

            }




        }


    }
    _onProgress = (filesInfo) => {
        // let files = this.state.uploadedFilesInfo;
        // files = files.map((file) => {
        //     if (file.name == filesInfo.name) {
        //         file = filesInfo;
        //     }
        //     return file;
        // })

        // 
        // this.setState((prevState) => {
        //     return {
        //         org: {
        //             ...prevState.org,
        //             logoRectangle: filesInfo
        //         }
        //     }
        // });
    }
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    addAttachment = (fieldName, fileName, data) => {
        let user = {
            ...this.state.user,
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,
            }
        }
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, user);
        this.setState((prevState) => {
            return {user,formValidations}
        });
    }

    _onStudenPhotoSuccess = (fileName, data) => {
        this.addAttachment('studnetPhoto', fileName, data);
    }
    _onFatherPhotoSuccess = (fileName, data) => {
        this.addAttachment('fatherPhoto', fileName, data);
    }
    _onMotherPhotoSuccess = (fileName, data) => {
        this.addAttachment('motherPhoto', fileName, data);
    }
    _onGuardianPhotoSuccess = (fileName, data) => {
        this.addAttachment('guardianPhoto', fileName, data);
    }
    _afterFilesUploaded = (files) => {

    }
    onCliclAddSibling = (bool,index) => {
        if(bool){
            let arrLength = this.state.siblings.length
            let lastObj = this.state.siblings[arrLength-1]            
            if(lastObj.name !== ""){
                this.setState((prevState) => {
                    return {siblings : [...prevState.siblings,{name: "",gender: "",classStudying : "",schoolStudying : ""}]}
                })
            }else{
                this.toast.show({ severity: 'error', summary: 'Please enter the sibling details', detail: '', life: 3000 });
            }
        }
        else if(bool === false){
            const{ siblings } = this.state
            const newArr = [...siblings]
            newArr.splice(index,1)
            this.setState({siblings : newArr})
        }
    }

    onCliclAddPrevAcadamicDet = (bool, index) => {
        if(bool){
            let arrLength = this.state.prevAcadamicDetails.length
            let lastObj = this.state.prevAcadamicDetails[arrLength-1]            
            if(lastObj.name !== ""){
                this.setState((prevState) => {
                    return {prevAcadamicDetails : [...prevState.prevAcadamicDetails, {name: "",classStudied: "",schoolStudied : "",percentageOrGpa : ""}]}
                })
            }else{
                this.toast.show({ severity: 'error', summary: 'Please enter the Previous acadamic details', detail: '', life: 3000 });
            }
        }
        else if(bool === false){
            const{ prevAcadamicDetails } = this.state
            const newArr = [...prevAcadamicDetails]
            newArr.splice(index,1)
            this.setState({prevAcadamicDetails : newArr})
        }
    }
    onChangeInput = (value ,index,field,arr)=> {
        if(arr === "siblings"){
            let data = cloneDeep(this.state.siblings)
            data[index][field] = value;
            this.setState({
                siblings:data
            })
        }else if(arr === "prevAcadamicDetails"){
            let data = cloneDeep(this.state.prevAcadamicDetails)
            data[index][field] = value;
            this.setState({
                prevAcadamicDetails:data
            })
        }
        
    }
    render() {
        const { user, formValidations, sectionsData } = this.state;
        return (<>
            <div className="p-grid ma-toolbar">
                <div className="p-col-12 p-md-1 ma-no-p">
                </div>
                <div className="p-col-12 p-md-11 ma-no-pm">
                    <Toolbar className="ma-toolbard"
                        left={() => { return <BreadCrumb model={items} home={home} /> }}
                        right={() => {
                            return (
                                <h2 className='ma-tab-title'>User Management</h2>
                            )
                        }}></Toolbar>
                </div>
            </div>
            <div className='create-user'>


                <p className='reg-title'>{this.props.editUser ? 'Update' : 'Create'} User</p>
                <div className="p-grid ma-w100p ma-p20" >
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>User Name (for login)<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.USER_USER_NAME} id="cu1">
                            {this.props.editUser ? <InputText value={user.username.substring(3)} onChange={(e) => { onTextChange(e.target.value, 'username', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                disabled={true}
                                className='p-inputtext-style1 ma-w100p' /> : <InputText value={user.username} onChange={(e) => { onTextChange(e.target.value, 'username', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                    disabled={this.props.editUser}
                                    className='p-inputtext-style1 ma-w100p' />}

                        </InputTextB>
                        {formValidations && !formValidations.fields['username'].isValid && <p className="p-error">{formValidations.fields['username'].errorMsg}</p>}
                    </div>

                    {!this.props.editUser && <>
                        <div className="p-col-12 p-md-3">
                            <p className='ma-label-s2'>Password<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.USER_PASSWORD} id="cu6">
                                <Password
                                    className='ma-w100p'
                                    toggleMask
                                    value={user.password} onChange={(e) => onTextChange(e.target.value, 'password', this, formFields, user, formValidations, 'user', 'formValidations')}
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                        </div>
                        <div className="p-col-12 p-md-3">
                            <p className='ma-label-s2'>Confirm Password<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.USER_CONFIRM} id="cu6">
                                <Password
                                    className='ma-w100p'
                                    toggleMask
                                    value={user.confirmPassword}
                                    onChange={(e) => onTextChange(e.target.value, 'confirmPassword', this, formFields, user, formValidations, 'user', 'formValidations')}
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                        </div>
                    </>}
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Given Name<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.USER_GIVEN_NAME} id="cu1">
                            <InputText value={user.given_name} onChange={(e) => { onTextChange(e.target.value, 'given_name', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                className='p-inputtext-style1 ma-w100p' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['given_name'].isValid && <p className="p-error">{formValidations.fields['given_name'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Mobile Number<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.USER_MOBILE_NUMBER} id="co12">
                            <InputText2 left={'+91'}
                                value={user.phone_number}
                                onChange={(e) => {
                                    onNumberChange(e.target.value, 'phone_number', this, formFields, user, formValidations, 'user', 'formValidations')
                                }}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['phone_number'].isValid && <p className="p-error">{formValidations.fields['phone_number'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Email ID<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.USER_EMAIL_ID} id="co12">
                            <InputText
                                className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                value={user.email}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                    </div>
                    {/* Added by madhu */}
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Academic Year<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.ACADEMIC_YEAR} id="co12">
                            <Dropdown value={user.academicYear}
                                className='ma-w100p'
                                disabled={this.state.hideUpdateButton}
                                options={this.state.academicYears}
                                optionLabel='academicCode'
                                optionValue='academicCode'
                                onChange={(e) => { onDropDownChange(e.target.value, 'academicYear', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder="--Select Academic Year--" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['academicYear'].isValid && <p className="p-error">{formValidations.fields['academicYear'].errorMsg}</p>}
                    </div>

                    {/*  */}
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Board<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.USER_BOARD} id="co12">
                            <Dropdown value={user.boardId}
                                className='ma-w100p'
                                disabled={this.state.hideUpdateButton}
                                options={this.state.boards}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="--Select Board--" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['board'].isValid && <p className="p-error">{formValidations.fields['board'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Class<span className='ma-required'>*</span></p>
                        <InputTextB info={FIELDS_INFO.USER_CLASS} id="co12">
                            <Dropdown value={user.gradeId}
                                className='ma-w100p'
                                disabled={this.state.hideUpdateButton}
                                options={this.state.classes}
                                onChange={(e) => this.onChangeClass(e.value)}
                                placeholder="--Select Class--" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['grade'].isValid && <p className="p-error">{formValidations.fields['grade'].errorMsg}</p>}
                    </div>

                    {/* {
                        this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                            return <>
                                <div className="p-col-12 p-md-3">
                                    <p className='ma-label-s2'>{level.levelName}<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.PLEASE_SELECT + level.levelName} id={level.levelId.substring(0, 10)}>
                                        <Dropdown
                                            value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            className='ma-w100p'
                                            //    disabled={this.state.hideUpdateButton}
                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                            onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                            placeholder={`--Select ${level.levelName}--`} />
                                    </InputTextB>
                                    {!(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) && <p className="p-error">Required</p>}
                                </div>
                            </>
                        }) : null
                    } */}

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Religion</p>
                        <InputTextB info={FIELDS_INFO.RELIGION} id="co12">
                            <Dropdown value={user.religion}
                                className='ma-w100p'
                                options={this.state.religionData}
                                optionLabel='label'
                                optionValue='value'
                                onChange={(e) => { onDropDownChange(e.target.value, 'religion', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder="Select Religion" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['religion'].isValid && <p className="p-error">{formValidations.fields['religion'].errorMsg}</p>}
                    </div>


                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Caste</p>
                        <InputTextB info={FIELDS_INFO.CASTE} id="co12">
                            <Dropdown value={user.caste}
                                className='ma-w100p'
                                disabled={this.state.hideUpdateButton}
                                options={this.state.casteData}
                                optionLabel='label'
                                optionValue='value'
                                onChange={(e) => { onDropDownChange(e.target.value, 'caste', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder="Select Caste" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['caste'].isValid && <p className="p-error">{formValidations.fields['caste'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Gender</p>
                        <InputTextB info={FIELDS_INFO.GENDER} id="co12">
                            <Dropdown value={user.gender}
                                className='ma-w100p'
                                disabled={this.state.hideUpdateButton}
                                options={this.state.genderData}
                                optionLabel='label'
                                optionValue='value'
                                onChange={(e) => { onDropDownChange(e.target.value, 'gender', this, formFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder="Select Gender" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error">{formValidations.fields['gender'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Admission Date</p>
                        <InputTextB info={FIELDS_INFO.ADMISSION_DATE} id="co12">
                            <Calendar showIcon
                                value={user.admissionDate ? new Date(user.admissionDate) : null}
                                onChange={(e) => onTextChange(e.target.value, 'admissionDate', this, formFields, user, formValidations, 'user', 'formValidations')}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['admissionDate'].isValid && <p className="p-error">{formValidations.fields['admissionDate'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Aadhar Number</p>
                        <InputTextB info={FIELDS_INFO.AADHAR_NUMBER} id="co12">
                            <InputText value={user.aadharNo} className='inputNumberStyle ma-w100p'
                                onChange={(e) => onNumberChange(e.target.value, 'aadharNo', this, formFields, user, formValidations, 'user', 'formValidations', true)}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['aadharNo'].isValid && <p className="p-error">{formValidations.fields['aadharNo'].errorMsg}</p>}
                    </div>    

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Date of Birth</p>
                        <InputTextB info={FIELDS_INFO.DATE_OF_BIRTH} id="co12">
                            <Calendar showIcon className='ma-100p' maxDate={new Date()}
                                value={user.dateOfBirth ? new Date(user.dateOfBirth) : null}
                                onChange={(e) => onTextChange(e.target.value, 'dateOfBirth', this, formFields, user, formValidations, 'user', 'formValidations', true)}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['dateOfBirth'].isValid && <p className="p-error">{formValidations.fields['dateOfBirth'].errorMsg}</p>}
                    </div>    

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Age</p>
                        <InputTextB info={FIELDS_INFO.AGE} id="co12">
                            <InputText
                                className='inputNumberStyle ma-w100p'
                                value={user.age}
                                onChange={(e) => onNumberChange(e.target.value, 'age', this, formFields, user, formValidations, 'user', 'formValidations',true)}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['age'].isValid && <p className="p-error">{formValidations.fields['age'].errorMsg}</p>}
                    </div>  
                </div>
                <div className='p-col-12 p-md-3'>
                    <h4 className='reg-title'>Father's Details</h4>
                </div>  
                <div className="p-grid ma-w100p ma-p20" >
                    <div className="p-col-12 p-md-3">
                    <p className='ma-label-s2'>Father Name</p>
                    <InputTextB info={FIELDS_INFO.FATHER_NAME} id="cu1">
                        <InputText value={user.fatherName} className='p-inputtext-style1 ma-w100p'
                            onChange={(e) => { onTextChange(e.target.value, 'fatherName', this, formFields, user, formValidations, 'user', 'formValidations', true) }}/>
                    </InputTextB>
                    {formValidations && !formValidations.fields['fatherName'].isValid && <p className="p-error">{formValidations.fields['fatherName'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Occupation</p>
                        <InputTextB info={FIELDS_INFO.OCCUPATION} id="cu1">
                            <InputText value={user.fatherOccupation} className='p-inputtext-style1 ma-w100p' 
                                onChange={(e) => { onTextChange(e.target.value, 'fatherOccupation', this, formFields, user, formValidations, 'user', 'formValidations') }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['fatherOccupation'].isValid && <p className="p-error">{formValidations.fields['fatherOccupation'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Qualification</p>
                        <InputTextB info={FIELDS_INFO.QUALIFICATION} id="co12">
                            <Dropdown value={user.fatherQualification} placeholder="Select Qualification" disabled={this.state.hideUpdateButton} className='ma-w100p'
                                options={this.state.qualificationData} optionLabel='label' optionValue='value'
                                onChange={(e) => { onDropDownChange(e.target.value, 'fatherQualification', this, formFields, user, formValidations, 'user', 'formValidations') }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['fatherQualification'].isValid && <p className="p-error">{formValidations.fields['fatherQualification'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Mobile Number</p>
                        <InputTextB info={FIELDS_INFO.USER_MOBILE_NUMBER} id="co12">
                            <InputText2 left={'+91'} value={user.fatherMobileNum}
                                onChange={(e) => {onNumberChange(e.target.value, 'fatherMobileNum', this, formFields, user, formValidations, 'user', 'formValidations', true)}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['fatherMobileNum'].isValid && <p className="p-error">{formValidations.fields['fatherMobileNum'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Email ID</p>
                        <InputTextB info={FIELDS_INFO.USER_EMAIL_ID} id="co12">
                            <InputText className='p-inputtext-style1 ma-w100p' value={user.fatherEmailId}
                                onChange={(e) => { onEmailChange(e.target.value, 'fatherEmailId', this, formFields, user, formValidations, 'user', 'formValidations', true)}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['fatherEmailId'].isValid && <p className="p-error">{formValidations.fields['fatherEmailId'].errorMsg}</p>}
                    </div>
                </div>

                <div className='p-col-12 p-md-3'>
                    <h4 className='reg-title'>Mother's Details</h4>
                </div>    
                <div className="p-grid ma-w100p ma-p20" >
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Mother Name</p>
                        <InputTextB info={FIELDS_INFO.MOTHER_NAME} id="cu1">
                            <InputText value={user.motherName} className='p-inputtext-style1 ma-w100p' 
                                onChange={(e) => { onTextChange(e.target.value, 'motherName', this, formFields, user, formValidations, 'user', 'formValidations', true) }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['motherName'].isValid && <p className="p-error">{formValidations.fields['motherName'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Occupation</p>
                        <InputTextB info={FIELDS_INFO.OCCUPATION} id="cu1">
                            <InputText value={user.motherOccupation} className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onTextChange(e.target.value, 'motherOccupation', this, formFields, user, formValidations, 'user', 'formValidations')}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['motherOccupation'].isValid && <p className="p-error">{formValidations.fields['motherOccupation'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Qualification</p>
                        <InputTextB info={FIELDS_INFO.QUALIFICATION} id="co12">
                            <Dropdown value={user.motherQualification} className='ma-w100p' disabled={this.state.hideUpdateButton} placeholder="Select Qualification"
                                options={this.state.qualificationData} optionLabel='label' optionValue='value'
                                onChange={(e) => { onTextChange(e.target.value, 'motherQualification', this, formFields, user, formValidations, 'user', 'formValidations')}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['motherQualification'].isValid && <p className="p-error">{formValidations.fields['motherQualification'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Mobile Number</p>
                        <InputTextB info={FIELDS_INFO.USER_MOBILE_NUMBER} id="co12">
                            <InputText2 left={'+91'} value={user.motherMobileNum}
                                onChange={(e) => {onNumberChange(e.target.value, 'motherMobileNum', this, formFields, user, formValidations, 'user', 'formValidations', true)}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['motherMobileNum'].isValid && <p className="p-error">{formValidations.fields['motherMobileNum'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Email ID</p>
                        <InputTextB info={FIELDS_INFO.USER_EMAIL_ID} id="co12">
                            <InputText value={user.motherEmailId} className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onEmailChange(e.target.value, 'motherEmailId', this, formFields, user, formValidations, 'user', 'formValidations', true)}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['motherEmailId'].isValid && <p className="p-error">{formValidations.fields['motherEmailId'].errorMsg}</p>}
                    </div>        
                </div> 

                <div className='p-col-12 p-md-3'>
                    <h4 className='reg-title'>Guardian's Details</h4>
                </div>    

                <div className="p-grid ma-w100p ma-p20" >
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Guardian Name</p>
                        <InputTextB info={FIELDS_INFO.GUARDIAN_NAME} id="cu1">
                            <InputText value={user.guardianName} className='p-inputtext-style1 ma-w100p' 
                                onChange={(e) => { onTextChange(e.target.value, 'guardianName', this, formFields, user, formValidations, 'user', 'formValidations', true) }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['guardianName'].isValid && <p className="p-error">{formValidations.fields['guardianName'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Occupation</p>
                        <InputTextB info={FIELDS_INFO.OCCUPATION} id="cu1">
                            <InputText value={user.guardianOccupation} className='p-inputtext-style1 ma-w100p'
                                 onChange={(e) => { onTextChange(e.target.value, 'guardianOccupation', this, formFields, user, formValidations, 'user', 'formValidations')}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['guardianOccupation'].isValid && <p className="p-error">{formValidations.fields['guardianOccupation'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Qualification</p>
                        <InputTextB info={FIELDS_INFO.QUALIFICATION} id="co12">
                            <Dropdown value={user.guardianQualification} placeholder="Select Qualification" className='ma-w100p' disabled={this.state.hideUpdateButton}
                                options={this.state.qualificationData} optionLabel='label' optionValue='value'
                                onChange={(e) => { onDropDownChange(e.target.value, 'guardianQualification', this, formFields, user, formValidations, 'user', 'formValidations')}}
                                 />
                        </InputTextB>
                        {formValidations && !formValidations.fields['guardianQualification'].isValid && <p className="p-error">{formValidations.fields['guardianQualification'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Mobile Number</p>
                        <InputTextB info={FIELDS_INFO.USER_MOBILE_NUMBER} id="co12">
                            <InputText2 left={'+91'}
                                value={user.guardianMobileNum}
                                onChange={(e) => {onNumberChange(e.target.value, 'guardianMobileNum', this, formFields, user, formValidations, 'user', 'formValidations', true)
                                }}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['guardianMobileNum'].isValid && <p className="p-error">{formValidations.fields['guardianMobileNum'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Email ID</p>
                        <InputTextB info={FIELDS_INFO.USER_EMAIL_ID} id="co12">
                            <InputText value={user.guardianEmailId} className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onEmailChange(e.target.value, 'guardianEmailId', this, formFields, user, formValidations, 'user', 'formValidations', true) }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['guardianEmailId'].isValid && <p className="p-error">{formValidations.fields['guardianEmailId'].errorMsg}</p>}
                    </div>        
                </div>           

                <div className='p-col-12 p-md-3'>
                    <h4 className='reg-title'>Address</h4>
                </div>            

                <div className="p-grid ma-w100p ma-p20" >            
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>H.No./D.No</p>
                        <InputTextB info={FIELDS_INFO.HOUSE_NUMBER} id="co12">
                            <InputText value={user.houseNum} className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onTextChange(e.target.value, 'houseNum', this, formFields, user, formValidations, 'user', 'formValidations')}}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['houseNum'].isValid && <p className="p-error">{formValidations.fields['houseNum'].errorMsg}</p>}
                    </div>   

                        <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Street/Village</p>
                        <InputTextB info={FIELDS_INFO.STREET} id="co12">
                            <InputText value={user.streetOrVillage} className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onTextChange(e.target.value, 'streetOrVillage', this, formFields, user, formValidations, 'user', 'formValidations') }}
                            />
                        </InputTextB>
                        {formValidations && !formValidations.fields['streetOrVillage'].isValid && <p className="p-error">{formValidations.fields['streetOrVillage'].errorMsg}</p>}
                    </div>   
        
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>Area/Mandal</p>
                        <InputTextB info={FIELDS_INFO.MANDAL} id="co12">
                        <InputText value={user.mandal} className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => { onTextChange(e.target.value, 'mandal', this, formFields, user, formValidations, 'user', 'formValidations') }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['mandal'].isValid && <p className="p-error">{formValidations.fields['mandal'].errorMsg}</p>}
                    </div>   
            
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>City/District</p>
                        <InputTextB info={FIELDS_INFO.CITY} id="co12">
                            <InputText value={user.cityOrDist} className='p-inputtext-style1 ma-w100p'
                                    onChange={(e) => { onTextChange(e.target.value, 'cityOrDist', this, formFields, user, formValidations, 'user', 'formValidations') }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['cityOrDist'].isValid && <p className="p-error">{formValidations.fields['cityOrDist'].errorMsg}</p>}
                    </div>   
            
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>State</p>
                        <InputTextB info={FIELDS_INFO.STATE} id="co12">
                            <InputText value={user.state} className='p-inputtext-style1 ma-w100p'
                                    onChange={(e) => { onTextChange(e.target.value, 'state', this, formFields, user, formValidations, 'user', 'formValidations') }}/>
                        </InputTextB>
                        {formValidations && !formValidations.fields['state'].isValid && <p className="p-error">{formValidations.fields['state'].errorMsg}</p>}
                    </div>   
            
                    <div className="p-col-12 p-md-3">
                        <p className='ma-label-s2'>PIN Code</p>
                        <InputTextB info={FIELDS_INFO.PINCODE} id="co12">
                            <InputText value={user.pinCode} className='inputNumberStyle ma-w100p' 
                                onChange={(e) => onNumberChange(e.target.value, 'pinCode', this, formFields, user, formValidations, 'user', 'formValidations')}/>
                        </InputTextB>
                    </div>   
                </div>

                <div className='p-col-12 p-md-3'>
                        <h4 className='reg-title'>Siblings</h4>
                </div>
                <div className='p-grid ma-w100p ma-p20'>
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.siblings}>
                        <Column header='Sl.No' className='ma-w100 ma-h100' body = {(rowData,index) => {
                            return(<span>{index.rowIndex+1}</span>)
                        }}/>
                        <Column header="Name" body={(rowData,index) => {
                            return(
                                <InputText value={this.state.siblings[index.rowIndex].name} onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"name","siblings")} className='p-inputtext-style1 ma-w100p'/>
                            )}}/>
                        <Column header="Gender" body={(rowData,index) => {
                            return(
                                <Dropdown className='ma-w100p' disabled={this.state.hideUpdateButton} 
                                options={this.state.genderData}optionLabel='label'optionValue='value'
                                value={this.state.siblings[index.rowIndex].gender}
                                onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"gender","siblings")}
                                placeholder="Select Gender" />
                            )}}/>
                        <Column header="Class Studying" body={(rowData,index) => {
                            return(
                                <Dropdown className='ma-w100p' disabled={this.state.hideUpdateButton} 
                                options={this.state.classStudyingDrp}optionLabel='label'optionValue='value'
                                value={this.state.siblings[index.rowIndex].classStudying}
                                onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"classStudying","siblings")}
                                placeholder="Select Class" />
                            )}}/>
                        <Column header="School Studying" body={(rowData,index) => {
                            return(
                                <InputText className='p-inputtext-style1 ma-w100p'  
                                    value={this.state.siblings[index.rowIndex].schoolStudying} 
                                    onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"schoolStudying","siblings")}
                                />
                            )}}/>
                        <Column header="" body={(rowData,index) => {
                            return(
                            <>
                                <Button icon="pi pi-plus" aria-label="Increase" onClick={()=> this.onCliclAddSibling(true,index.rowIndex)} />
                                {this.state.siblings.length > 1 && <Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onCliclAddSibling(false,index.rowIndex)} className='ma-ml10'/>}
                            </>
                            )}}/>
                    </DataTable>
                </div>

                <div className='p-col-12 p-md-3'>
                        <h4 className='reg-title'>Previous Academic Details</h4>
                </div>
                <div className='p-grid ma-w100p ma-p20'>
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.prevAcadamicDetails}>
                        <Column header='Sl.No' className='ma-w100 ma-h100' body = {(rowData,index) => {
                            return(<span>{index.rowIndex+1}</span>)
                        }}/>
                        <Column header="Name" body={(rowData,index) => {
                            return(
                                <InputText value={this.state.prevAcadamicDetails[index.rowIndex].name} onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"name","prevAcadamicDetails")} className='p-inputtext-style1 ma-w100p'/>
                            )}}/>
                        <Column header="Class Studied" body={(rowData,index) => {
                            return(
                                <Dropdown className='ma-w100p' disabled={this.state.hideUpdateButton} 
                                    options={this.state.classStudyingDrp}optionLabel='label'optionValue='value' placeholder="Select Class"
                                    value={this.state.prevAcadamicDetails[index.rowIndex].classStudied}
                                    onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"classStudied","prevAcadamicDetails")}/>
                            )}}/>
                        <Column header="School Studied" body={(rowData,index) => {
                            return(
                                <InputText className='p-inputtext-style1 ma-w100p'
                                    value={this.state.prevAcadamicDetails[index.rowIndex].schoolStudied}
                                    onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"schoolStudied","prevAcadamicDetails")}/>
                            )}}/>
                        <Column header="Percentage/GPA" body={(rowData,index) => {
                            return(
                                <InputText className='p-inputtext-style1 ma-w100p'
                                    value={this.state.prevAcadamicDetails[index.rowIndex].percentageOrGpa}
                                    onChange={(e) => this.onChangeInput(e.target.value,index.rowIndex,"percentageOrGpa","prevAcadamicDetails")}/>
                            )}}/>
                        <Column header="" body={(rowData,index) => {
                            return(
                            <>
                                <Button icon="pi pi-plus" aria-label="Increase" onClick={()=> this.onCliclAddPrevAcadamicDet(true,index.rowIndex)} />
                                {this.state.prevAcadamicDetails.length > 1 &&<Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onCliclAddPrevAcadamicDet(false,index.rowIndex)} className='ma-ml10'/>}
                            </>
                            )}}/>
                    </DataTable>
                </div>
                <div className='p-grid ma-mt10'>
                    <div className="p-col-12 p-md-12 p-lg-3  ma-pr0">
                    <p className='ma-label-s2'>Student</p>
                    <InputTextB info={FIELDS_INFO.UPLOAD} id="co24">
                        <div className='custom-input2'>
                            <FileUpload id={"student"}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onStudenPhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                            >
                                <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                            </FileUpload>
                            <div style={{ height: '40px' }} className='logo-rect-text'>
                                {(this.state.user?.studnetPhoto && this.state.user?.studnetPhoto?.fileName) ? this.state.user?.studnetPhoto?.fileName : 'No file choosen'}
                            </div>
                            {this.state.user?.studnetPhoto && this.state.user?.studnetPhoto?.storageLocation && <img src={this.state.user?.studnetPhoto?.storageLocation} className='uploaded-photo ma-mt10'/>}
                        </div>
                    </InputTextB>
                    <p className='field-alert'>(*Upload JPEG formats are allowed with size below 200KB)</p>
                    {formValidations && !formValidations.fields['studnetPhoto'].isValid && <p className="p-error">{formValidations.fields['studnetPhoto'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-3  ma-pr0">
                    <p className='ma-label-s2'>Father</p>
                    <InputTextB info={FIELDS_INFO.UPLOAD} id="co24">
                        <div className='custom-input2'>
                            <FileUpload id={"father"}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onFatherPhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                            >
                                <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                            </FileUpload>
                            <div style={{ height: '40px' }} className='logo-rect-text'>
                                {(this.state.user?.fatherPhoto && this.state.user?.fatherPhoto?.fileName) ? this.state.user?.fatherPhoto?.fileName : 'No file choosen'}
                            </div>
                            {this.state.user?.fatherPhoto && this.state.user?.fatherPhoto?.storageLocation && <img src={this.state?.user?.fatherPhoto?.storageLocation} className='uploaded-photo ma-mt10'/>}
                        </div>
                    </InputTextB>
                    <p className='field-alert'>(*Upload JPEG formats are allowed with size below 200KB)</p>
                    {formValidations && !formValidations.fields['fatherPhoto'].isValid && <p className="p-error">{formValidations.fields['fatherPhoto'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-3  ma-pr0">
                    <p className='ma-label-s2'>Mother</p>
                    <InputTextB info={FIELDS_INFO.UPLOAD} id="co24">
                        <div className='custom-input2'>
                            <FileUpload id={"mother"}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onMotherPhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                            >
                                <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                            </FileUpload>
                            <div style={{ height: '40px' }} className='logo-rect-text'>
                                {(this.state.user?.motherPhoto && this.state.user?.motherPhoto?.fileName) ? this.state.user?.motherPhoto?.fileName : 'No file choosen'}
                            </div>
                            {this.state?.user?.motherPhoto && this.state?.user?.motherPhoto?.storageLocation && <img src={this.state.user?.motherPhoto?.storageLocation} className='uploaded-photo ma-mt10'/>}
                        </div>
                    </InputTextB>
                    <p className='field-alert'>(*Upload JPEG formats are allowed with size below 200KB)</p>
                    {formValidations && !formValidations.fields['motherPhoto'].isValid && <p className="p-error">{formValidations.fields['motherPhoto'].errorMsg}</p>}
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-3  ma-pr0">
                    <p className='ma-label-s2'>Guardian</p>
                    <InputTextB info={FIELDS_INFO.UPLOAD} id="co24">
                        <div className='custom-input2'>
                            <FileUpload id={"guardian"}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onGuardianPhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                            >
                                <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                            </FileUpload>
                            <div style={{ height: '40px' }} className='logo-rect-text'>
                                {(this.state.user?.guardianPhoto && this.state.user?.guardianPhoto?.fileName) ? this.state.user?.guardianPhoto?.fileName : 'No file choosen'}
                            </div>
                            {this.state.user?.guardianPhoto && this.state.user?.guardianPhoto?.storageLocation && <img src={this.state.user?.guardianPhoto?.storageLocation} className='uploaded-photo ma-mt10'/>}
                        </div>
                    </InputTextB>
                    <p className='field-alert'>(*Upload JPEG formats are allowed with size below 200KB)</p>
                    {formValidations && !formValidations.fields['guardianPhoto'].isValid && <p className="p-error">{formValidations.fields['guardianPhoto'].errorMsg}</p>}
                    </div>
                </div>   
                <Toolbar className="ma-toolbard" right={() => {
                    return (
                        <React.Fragment>
                            <Button label={this.props.editUser ? 'Update' : 'Create'} className='' onClick={this.onSubmitClick} />
                            <Button label="Cancel" className='ma-m-lr10 p-button-outlined' onClick={this.props.onCancel} />
                        </React.Fragment>
                    )
                }}></Toolbar>
                <div className=' ma-w100p ma-p20'>
                    {this.props.editUser && <>
                        <p className='anot-title'>Another Grades</p>
                        <AdditionalGrades baseAccountInfo={this.props.editUser} hideUpdateButton={(val) => { this.setState({ hideUpdateButton: val }) }} />
                    </>
                    }
                </div>

            </div>


            <Dialog
                visible={this.state.isShowCreateSuccess}
                blockScroll={true}
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Done" onClick={() => {
                            this.setState({
                                isShowCreateSuccess: false
                            }, () => {
                                this.props.onCreateOrEditSuccess()
                            })

                        }} />
                    </div>)
                }}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                closable={false}
                onHide={() => {
                    this.setState({
                        isShowCreateSuccess: false
                    }, () => {
                        this.props.onCreateOrEditSuccess()
                    })
                }}>
                <div className='success-dialog'>
                    <img src="./images/success-icon.PNG" className='img-center' alt="success" />
                    <p className='success-title'>Success!</p>
                    <p className='success-msg'>
                        User {this.props.editUser ? 'Updated' : 'Created'} Successfully.
                    </p>
                </div>
            </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                (this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && <>
                    <LoadingComponent />
                </>
            }
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
});


export default connect(mapStateToProps, {
    // getCurriculum,
    // getActiveOrganizationData,
    // selectBoard,
    // dispatchClearCurriculumData,
    // selectClass,
    // selectGroup,
    // updateSubject,
    // getSubject,
    // clearSelectedOrganizationData,
    // startExportToOffline,
    // getImageUrl,
    getBoardsData,
    getBranchesLatest
})((withRouter(CreateUser)));
